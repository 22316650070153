import React, { useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { AuditScope, AuditScreen_Query } from './__generated__/AuditScreen_Query.graphql';
import { AuditScreen_OrganizationQuery } from './__generated__/AuditScreen_OrganizationQuery.graphql';
import { TenantId } from '@app/hooks/useTenant';
import { AuditScreen_Event_event$key } from './__generated__/AuditScreen_Event_event.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import CopyValueButton from '@app/components/CopyValueButton/CopyValueButton';

type Props = {
  tenantId: TenantId
  context: 'admin' | 'tenant'
}

type Event = NonNullable<NonNullable<AuditScreen_Query["response"]["admin"]["tenant"]>["audit"]["events"]>[0];
function toKey(input: Event) {
  return input.event+input.timestamp;
}

const AuditScreenQuery = graphql`
  query AuditScreen_Query($id: ID!, $from: String!, $to: String!, $scope: AuditScope) {
    admin {
      tenant(id: $id) {
        audit {
          events(from: $from, to: $to, scope: $scope) {
            event
            timestamp
            ...AuditScreen_Event_event
          }
        }
      }
    }
  }
`;
const AuditScreenOrganizationQuery = graphql`
  query AuditScreen_OrganizationQuery($id: ID!, $from: String!, $to: String!, $scope: AuditScope) {
    admin {
      organization(id: $id) {
        audit {
          events(from: $from, to: $to, scope: $scope) {
            event
            timestamp
            ...AuditScreen_Event_event
          }
        }
      }
    }
  }
`

export default function AuditScreen(props: Props) {
  const {tenantId} = props;
  const [from, setFrom] = useState(() => moment().startOf('day').subtract(7, 'days').toDate())
  const [to, setTo] = useState(() => moment().endOf('day').toDate());
  const [scope, setScope] = useState<AuditScope | null>(null);

  const [queryReference, loadQuery] = useQueryLoader<AuditScreen_Query>(
    AuditScreenQuery,
    null
  );

  useEffect(() => {
    loadQuery({
      id: tenantId.relayId,
      from: from.toJSON(),
      to: to.toJSON(),
      scope
    }, {
      fetchPolicy: 'store-and-network' // Refresh when accessing page again, even if cached
    })
  }, [tenantId, from, to, scope]);

  return (
    <React.Fragment>
      <Filters scope={scope} from={from} to={to} context={props.context === 'admin' ? 'admin_tenant' : props.context} onChange={(changes) => {
        setFrom(changes.from);
        setTo(changes.to);
        setScope(changes.scope);
      }} />
      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <AuditScreenEvents queryReference={queryReference} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}
    </React.Fragment>
  );
}

function AuditScreenEvents(props: {
  queryReference: PreloadedQuery<AuditScreen_Query>,
}) {
  const data = usePreloadedQuery<AuditScreen_Query>(AuditScreenQuery, props.queryReference);
  const events = data.admin.tenant?.audit.events;
  if (!events) return null;
  return <Events events={events} />;
}

export function OrganizationAuditScreen(props: {
  organizationId: string,
  context: 'admin' | 'organization'
}) {
  const {organizationId} = props;
  const [from, setFrom] = useState(() => moment().startOf('day').subtract(7, 'days').toDate())
  const [to, setTo] = useState(() => moment().endOf('day').toDate());
  const [scope, setScope] = useState<AuditScope | null>(null);

  const [queryReference, loadQuery] = useQueryLoader<AuditScreen_OrganizationQuery>(
    AuditScreenOrganizationQuery,
    null
  );

  useEffect(() => {
    loadQuery({
      id: organizationId,
      from: from.toJSON(),
      to: to.toJSON(),
      scope
    }, {
      fetchPolicy: 'store-and-network' // Refresh when accessing page again, even if cached
    })
  }, [organizationId, from, to, scope]);

  return (
    <React.Fragment>
      <Filters scope={scope} from={from} to={to} context={props.context === 'admin' ? 'admin_organization' : props.context} onChange={(changes) => {
        setFrom(changes.from);
        setTo(changes.to);
        setScope(changes.scope);
      }} />
      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <OrganizationAuditScreenEvents queryReference={queryReference} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}
    </React.Fragment>
  );
}

function OrganizationAuditScreenEvents(props: {
  queryReference: PreloadedQuery<AuditScreen_OrganizationQuery>,
}) {
  const data = usePreloadedQuery<AuditScreen_OrganizationQuery>(AuditScreenOrganizationQuery, props.queryReference);
  const events = data.admin.organization?.audit.events;
  if (!events) return null;
  return <Events events={events} />;
}

function Filters(props: {
  context: 'admin_tenant' | 'admin_organization' | 'tenant' | 'organization',
  scope: AuditScope | null,
  from: Date,
  to: Date,
  onChange: (changes: {from: Date, to: Date, scope: AuditScope | null}) => void
}) {
  const handleChange = (key: 'from' | 'to', event: React.ChangeEvent<HTMLInputElement>) => {
    if (key === 'from') props.onChange({from: new Date(event.target.value), to: props.to, scope: props.scope});
    if (key === 'to') props.onChange({from: props.from, to: new Date(event.target.value), scope: props.scope});
  }

  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="flex flex-row gap-2 items-center">
        Between
        <input className="form-control" type="datetime-local" value={moment(props.from).format('YYYY-MM-DDTHH:mm')} onChange={event => handleChange('from', event)} />
        and
        <input className="form-control" type="datetime-local" value={moment(props.to).format('YYYY-MM-DDTHH:mm')} onChange={event => handleChange('to', event)} />
      </div>
      {(props.context === 'admin_tenant' || props.context === 'admin_organization') ? (
        <div className="flex flex-row gap-2 items-center">
          Scope:
          <select
            className="form-control"
            value={props.scope?.toString() ?? ""}
            onChange={event => {
              const scope = event.target.value?.length ? event.target.value as AuditScope : null;
              props.onChange({from: props.from, to: props.to, scope});
            }}
          >
            <option value="">All scopes</option>
            <option value="ADMIN">Admin only</option>
            {props.context === 'admin_tenant' && <option value="TENANT">Tenant only</option>}
            {props.context === 'admin_organization' && <option value="ORGANIZATION">Orgnaization only</option>}
          </select>
        </div>
      ) : null}
    </div>
  )
}

function Events(props: {
  events: readonly Event[]
}) {
  return (
    <React.Fragment>
      <p>Hint: You can click events to expand them.</p>
      <div className="mt-4">
        {props.events.map((event) => (
          <Event key={toKey(event)} event={event} />
        ))}
      </div>
    </React.Fragment>
  );
}

function Event(props: {
  event: AuditScreen_Event_event$key
}) {
  const [expanded, toggleExpanded] = useReducer(val => !val, false);
  const event = useFragment(
    graphql`
      fragment AuditScreen_Event_event on AuditEvent {
        user {
          sub
          email
        }
        api_key
        event
        timestamp
        raw
      }
    `,
    props.event
  );

  const raw = {
    event: event.event,
    user_email: event.user ? event.user.email : undefined,
    api_key: event.api_key ?? undefined,
    ...JSON.parse(event.raw),
    user_sub: event.user ? event.user.sub : undefined
  };

  const rawInlineStr = JSON.stringify(raw);
  const rawFormattedStr = JSON.stringify(raw, null, 2);

  const chevronIcon = expanded ? 'fa-angle-down' : 'fa-angle-right'

  return (
    <div className="border-b border-gray-300 rounded-lg overflow-hidden">
      <pre onClick={() => toggleExpanded()} className="flex items-center border-none gap-4 rounded-none w-full cursor-pointer">
        <i className={`text-gray-700 fa ${chevronIcon}`} />
        <div className="truncate">{rawInlineStr}</div>
        <CopyValueButton value={rawInlineStr} tooltip="Copy audit log" variant="primary"/>
      </pre>
      {expanded && <pre className="border-none rounded-none">{rawFormattedStr}</pre>}
    </div>
  )
}
