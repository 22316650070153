import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { NLiDINEvidosProviderQuery } from './__generated__/NLiDINEvidosProviderQuery.graphql';
import { NLiDINEvidosProviderMutation } from './__generated__/NLiDINEvidosProviderMutation.graphql';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId,
  environment: Environment
}

export interface FormValues  {
  clientId : string
  clientSecret : string
}

export function NLiDINEvidosProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<NLiDINEvidosProviderQuery>(
    graphql`
      query NLiDINEvidosProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on DutchIdinEvidos {
            clientId
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|NL_IDIN`),
    tenantId: tenant.relayId,
    environment: props.environment
  });
  const [{executePromise}] = useMutation<NLiDINEvidosProviderMutation>(graphql`
    mutation NLiDINEvidosProviderMutation(
      $input: UpdateDutchIdinEvidosInput!
    ) {
      updateDutchIdinEvidos(input: $input) {
        id
        isConfigured
        clientId
      }
    }
  `);

  if (!data.identityProvider) return null;

  const initialValues : FormValues = {
    clientId: data.identityProvider?.clientId ?? '',
    clientSecret: '',
  };

  const environment = useEnvironment();

  const dialogHeading = (
    <React.Fragment>
      <h3>{translate('INFO_NL_IDIN_CALLBACK_URL_PROD')}</h3>
    </React.Fragment>
  );

  const domains = data.tenant?.domains.edges.map(edge => edge.node);
  const dialogBody = domains?.length ? (
    <React.Fragment>
      {domains.map(domain => (
        <p className="green" key={domain.name}>
          Callback URL: https://{domain.name}/NLIdin/Callback<br />
          Cancel URL: https://{domain.name}/NLIdin/Cancel
        </p>
      ))}
    </React.Fragment>
  ) : (
    <p>You must register a domain before you can start using Dutch iDIN</p>
  );

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          {!initialValues.clientId ? (
            <React.Fragment>
              <EnvironmentDialog environment="TEST" heading={dialogHeading}>
                {dialogBody}
              </EnvironmentDialog>
              <EnvironmentDialog environment="PRODUCTION" heading={dialogHeading}>
                {dialogBody}
              </EnvironmentDialog>
            </React.Fragment>
          ) : null}
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} {data.identityProvider!.longName} Authentication
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_NL_IDIN_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_NL_IDIN_CLIENTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_NL_IDIN_CLIENTSECRET')}
                name="clientSecret"
                placeholder={translate('LABEL_NL_IDIN_CLIENTSECRET')}
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your dutch iDIN settings have been updated`} />
              <div className="flex flex-row mt-6 mb-6 justify-end">
                <Button variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
              </div>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_NL_IDIN_CLIENT')}</p>
              <p>{translate(environment === 'PRODUCTION' ? 'INFO_NL_IDIN_CALLBACK_URL_PROD': 'INFO_NL_IDIN_CALLBACK_URL_TEST')}</p>
              {domains?.length ? (
                <React.Fragment>
                  {domains.map(domain => (
                    <p className="green" key={domain.name}>
                      Callback URL: https://{domain.name}/NLIdin/Callback<br />
                      Cancel URL: https://{domain.name}/NLIdin/Cancel
                    </p>
                  ))}
                </React.Fragment>
              ) : (
                <p>You must register a domain before you can start using Dutch iDIN</p>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}