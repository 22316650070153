/**
 * @generated SignedSource<<df69436fdeddd97304539622030283b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Country = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type Currency = "DKK" | "EUR" | "%future added value";
export type OnboardingOrderKey = "DKMITID_CUSTOM_DOMAIN" | "NOBANKID" | "SEBANKID" | "%future added value";
export type PayOnboardingFeeInput = {
  currency: Currency;
  information?: UpdateBillingInformationInput | null;
  order: OnboardingOrderKey;
  paymentIntentId?: string | null;
  stripePaymentMethod?: string | null;
  stripePaymentToken?: string | null;
  tenantId: string;
};
export type UpdateBillingInformationInput = {
  city: string;
  company: string;
  country: Country;
  email: string;
  street: string;
  taxId?: string | null;
  tenantId: string;
  zip: string;
};
export type CustomDomain_OnboardingFeeForm_Mutation$variables = {
  input: PayOnboardingFeeInput;
};
export type CustomDomain_OnboardingFeeForm_Mutation$data = {
  readonly payOnboardingFee: {
    readonly paymentIntent: {
      readonly id: string;
      readonly secret: string;
    } | null;
    readonly tenant: {
      readonly billing: {
        readonly information: {
          readonly city: string | null;
          readonly company: string | null;
          readonly country: Country | null;
          readonly email: string | null;
          readonly street: string | null;
          readonly taxId: string | null;
          readonly zip: string | null;
        } | null;
        readonly paymentMethod: {
          readonly brand: string;
          readonly last4: string;
          readonly name: string;
        } | null;
      } | null;
      readonly features: {
        readonly DKMITID_CUSTOM_DOMAIN_order_form: boolean;
        readonly NOBANKID_order_form: boolean;
        readonly SEBANKID_order_form: boolean;
      };
    };
  };
};
export type CustomDomain_OnboardingFeeForm_Mutation = {
  response: CustomDomain_OnboardingFeeForm_Mutation$data;
  variables: CustomDomain_OnboardingFeeForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingInformation",
  "kind": "LinkedField",
  "name": "information",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "kind": "LinkedField",
  "name": "paymentMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last4",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "NOBANKID_order_form",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "SEBANKID_order_form",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "DKMITID_CUSTOM_DOMAIN_order_form",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentIntent",
  "kind": "LinkedField",
  "name": "paymentIntent",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secret",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomDomain_OnboardingFeeForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayOnboardingFeeOutput",
        "kind": "LinkedField",
        "name": "payOnboardingFee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Billing",
                "kind": "LinkedField",
                "name": "billing",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomDomain_OnboardingFeeForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayOnboardingFeeOutput",
        "kind": "LinkedField",
        "name": "payOnboardingFee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Billing",
                "kind": "LinkedField",
                "name": "billing",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67c68d67e437de0a965f7f645c836744",
    "id": null,
    "metadata": {},
    "name": "CustomDomain_OnboardingFeeForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation CustomDomain_OnboardingFeeForm_Mutation(\n  $input: PayOnboardingFeeInput!\n) {\n  payOnboardingFee(input: $input) {\n    tenant {\n      billing {\n        information {\n          company\n          email\n          country\n          street\n          city\n          zip\n          taxId\n        }\n        paymentMethod {\n          name\n          brand\n          last4\n        }\n        id\n      }\n      features {\n        NOBANKID_order_form\n        SEBANKID_order_form\n        DKMITID_CUSTOM_DOMAIN_order_form\n      }\n      id\n    }\n    paymentIntent {\n      id\n      secret\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d37fc04a6b79b1d1dc451f5426a69e1";

export default node;
