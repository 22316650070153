import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import CopyValueButton from '@app/components/CopyValueButton';
import Modal, { ModalProps } from '@app/components/Modal/Modal';
import InputField from '@app/components/FormFields/InputField/InputField';
import { OrganizationApiKeyModal_apiKey$key } from './__generated__/OrganizationApiKeyModal_apiKey.graphql';

interface Props {
  apiKey: OrganizationApiKeyModal_apiKey$key | null,
  onHide: ModalProps["onHide"],
  open: ModalProps["open"]
}

export default function OrganizationApiKeyModalKey(props : Props) {
  const apiKey = useFragment(graphql`
    fragment OrganizationApiKeyModal_apiKey on OrganizationApiKey {
      clientId
      clientSecret
    }
  `, props.apiKey)

  if (!props.apiKey) return null;
  if (!apiKey) return null;

  return (
    <Modal open={props.open} onHide={props.onHide}>
      <Modal.Header>
        <div>
          <h2>Dashboard API ClientId and ClientSecret</h2>
          <p>
            This is the only time we will show you your client secret.
            Please store the credentials in a secure location.
            You will need them when integrating your application.
          </p>
        </div>
      </Modal.Header>
      <Modal.Content className="flex flex-col gap-[10px]">
        <InputField
          name="client_id"
          label="Client ID"
          type="text"
          value={apiKey.clientId}
          readOnly
          button={<CopyValueButton variant="primary" value={apiKey.clientId} />}
        />
        <InputField
          name="client_secret"
          label="Client Secret"
          type="text"
          value={apiKey.clientSecret!}
          readOnly
          button={<CopyValueButton variant="primary" value={apiKey.clientSecret!} />}
        />
      </Modal.Content>
    </Modal>
  );
}