import React, {useCallback} from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, FormError, FormSuccess} from '@components/Form';
import CertificateForm from '@components/CertificateForm';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { NOBankIDOldProviderQuery } from './__generated__/NOBankIDOldProviderQuery.graphql';
import { NOBankIDOldProviderMutation } from './__generated__/NOBankIDOldProviderMutation.graphql';
import { fileToBase64 } from '@app/helpers';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId,
  environment: Environment
}
export interface FormValues {
  certificate?: {
    file: File,
    password: string
  }
}

export function NOBankIDOldProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<NOBankIDOldProviderQuery>(
    graphql`
      query NOBankIDOldProviderQuery($id: ID!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on NorwegianBankIDCServer {
            hasCertificate
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|NO_BANKID_CSERVER`),
  });

  const [{executePromise}] = useMutation<NOBankIDOldProviderMutation>(graphql`
    mutation NOBankIDOldProviderMutation(
      $input: UpdateNorwegianBankIDCServerInput!
    ) {
      updateNorwegianBankIDCserver(input: $input) {
        id
        isConfigured
        ... on NorwegianBankIDCServer {
          hasCertificate
        }
      }
    }
  `);

  if (!data.identityProvider) return null;

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  return (
    <Form
      key={environment}
      initialValues={{}}
      onSubmit={async (values : FormValues) => {
        if (!values.certificate) return;
        const certificate = await fileToBase64(values.certificate.file!);

        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            certificate: certificate,
            password: values.certificate?.password
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_NOBANKIDOLD_WHAT_TO_AVOID')}</em></p>
            <p className="green">❓{translate('HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_NOBANKIDOLD')}</p>
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} {data.identityProvider!.longName}
              </h3>
              <EnvironmentTag />
              <CertificateForm
                certificate={null}
                fileFieldName="certificate.file"
                passwordFieldName="certificate.password"
                required={true}
              />
              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your norwegian bankid settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_CERTIFICATE', { provider: data.identityProvider!.longName })}</p>
              <p>{translate('INFO_NO_BANKID_OLD')}</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}