import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Modal, { ModalProps } from '@app/components/Modal/Modal';
import Alert from '@app/components/Alert/Alert';

import InputField from '@app/components/FormFields/InputField/InputField';
import Button from '@app/components/Button/Button';
import { useFragment } from 'react-relay';
import { PaymentMethodModal_paymentMethod$key } from './__generated__/PaymentMethodModal_paymentMethod.graphql';
import { PaymentMethodModal_information$key } from './__generated__/PaymentMethodModal_information.graphql';
import useMutation from '@app/hooks/useMutation';
import { PaymentMethodModal_tenant$key } from './__generated__/PaymentMethodModal_tenant.graphql';
import { PaymentMethodModal_UpdateMutation } from './__generated__/PaymentMethodModal_UpdateMutation.graphql';
import { PaymentMethodModal_ConfirmMutation } from './__generated__/PaymentMethodModal_ConfirmMutation.graphql';

interface Props {
  tenant: PaymentMethodModal_tenant$key,
  open: ModalProps["open"]
  onHide: ModalProps["onHide"]
  onSuccess: () => void
  paymentMethod: PaymentMethodModal_paymentMethod$key | null
  information: PaymentMethodModal_information$key | null
}

export default function PaymentMethodModal(props: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [working, setWorking] = useState(false);
  const [error, setError] = useState<string | null>('');

  const tenant = useFragment(
    graphql`
      fragment PaymentMethodModal_tenant on Tenant {
        id
      }
    `,
    props.tenant
  );

  const information = useFragment(
    graphql`
      fragment PaymentMethodModal_information on BillingInformation {
        street
        city
        zip
        country
      }
    `,
    props.information
  );

  const paymentMethod = useFragment(
    graphql`
      fragment PaymentMethodModal_paymentMethod on PaymentMethod {
        name
      }
    `,
    props.paymentMethod
  );

  const [updateExecutor, updateStatus] = useMutation<PaymentMethodModal_UpdateMutation>(
    graphql`
      mutation PaymentMethodModal_UpdateMutation($input: UpdatePaymentMethodInput!) {
        updatePaymentMethod(input: $input) {
          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
          }
          billing {
            paymentMethod {
              name
              brand
              last4
            }
            subscription {
              status
            }
          }
          paymentIntent {
            id
            status
            secret
            subscriptionId
          }
        }
      }
    `
  );

  const [confirmExecutor, confirmStatus] = useMutation<PaymentMethodModal_ConfirmMutation>(
    graphql`
      mutation PaymentMethodModal_ConfirmMutation($input: ConfirmPaymentIntentInput!) {
        confirmPaymentIntent(input: $input) {
          subscription {
            status
            ... ConfirmPaymentButton_subscription
          }

          tenant {
            environments
            verifyEnvironments: environments(product: Verify)
            signaturesEnvironments: environments(product: Signatures)
            ageverificationEnvironments: environments(product: [Ageverification_DK])
          }
        }
      }
    `
  );

  const [name, setName] = useState(paymentMethod?.name ?? '');
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!elements || !stripe) return;
    if (working || updateStatus.pending || confirmStatus.pending) return;

    setWorking(true);
    setError(null);

    const element = elements.getElement(CardElement)!;
    const {error, token} = await stripe.createToken(element, {
      name,
      address_line1: information?.street ?? undefined,
      address_city: information?.city ?? undefined,
      address_zip: information?.zip!.toString(),
      address_country: information?.country ?? undefined
    });

    // const result = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: {token: token!.id}
    // });

    if (error) {
      setWorking(false);
      setError(error.message || 'An error occurred');
      return;
    }

    const updateResponse = await updateExecutor.executePromise({
      input: {
        tenantId: tenant.id,
        stripePaymentToken: token!.id,
        name
      }
    }).catch((err) => {
      setWorking(false);
      throw err;
    });

    if (updateResponse.updatePaymentMethod.paymentIntent?.status.startsWith('requires_')) {
      const result = await stripe.confirmCardPayment(updateResponse.updatePaymentMethod.paymentIntent.secret);
      if (result.error) {
        setWorking(false);
        setError(result.error.message || 'An error occurred');
        return;
      };

      await confirmExecutor.executePromise({
        input: {
          paymentIntentId: updateResponse.updatePaymentMethod.paymentIntent.id,
          subscriptionId: updateResponse.updatePaymentMethod.paymentIntent.subscriptionId!
        }
      }).finally(() => setWorking(false));
    }

    props.onSuccess();
  }

  return (
    <Modal open={props.open} onHide={props.onHide}>
      <Modal.Header>
        <div>
          <h2>Update payment method</h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit} className="m-0 flex flex-col gap-[16px]">
          <div className="h-[40px] text-light-blue-800 p-2.5 leading-7 font-medium bg-primary-100 border-2 border-primary-100 h-10 mb-0 min-w-[65px] py-[10px] px-[18px]">
            <CardElement options={{hidePostalCode: true}} />
          </div>
          <InputField name="name-on-card" label="Name on card" type="text" value={name} onChange={event => setName(event.target.value)} />

          {error ? (
            <Alert variant="error" className="mt-[15px]" title="An error occurred" message={error} />
          ) : updateStatus.error ? (
            <Alert variant="error" className="mt-[15px]" title="An error occurred" message={updateStatus.error.message} />
          ) : confirmStatus.error ? (
            <Alert variant="error" className="mt-[15px]" title="An error occurred" message={confirmStatus.error.message} />
          )  : null}

          <div className="flex flex-row align-items-end">
            <Button variant="primary" type="submit" working={working || updateStatus.pending || confirmStatus.pending}>Save payment method</Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}