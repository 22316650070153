import React, { cloneElement } from 'react';
import cx from 'classnames';
import Tooltip from '@app/components/Tooltip';
import HelpText from '../HelpText';
import { BaseButtonProps, TextButtonProps, IconButtonProps } from '@app/components/Button/Button';
import styles from '../FormFields.module.css';
import useField from '../useField';

export type BaseInputFieldProps = {
  iconLeft?: React.ReactNode;
  className?: string;
  helpText?: React.ReactNode;
  error?: string | React.ReactNode;
};

export type IconRightInputFieldProps = {
  iconRight?: React.ReactNode;
};

export type TooltipInputFieldProps = {
  tooltip?: string;
};

export type ButtonInputFieldProps = {
  button?: React.ReactElement<BaseButtonProps & (TextButtonProps | IconButtonProps)>;
};

export type SuffixInputFieldProps = {
  suffix?: React.ReactNode;
};

export interface InputFieldProps<T>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'type'>,
    BaseInputFieldProps {
  name: T extends never ? string : keyof T;
  type?: 'text' | 'number' | 'search' | 'url' | 'email' | 'password' | 'secret'
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  inputRef?: React.ClassAttributes<HTMLInputElement>["ref"]
}

function className<T>(props: InputFieldProps<T>) {
  return cx(styles[`input-field-container`], {
    [styles['has-label']]: props.label ? true : false,
    [styles['input-icon-left']]: 'iconLeft' in props ? true : false,
    [styles['disabled']]: props.disabled ? true : false,
  });
}

export default function InputField<T = never>(
  props: InputFieldProps<T> & (IconRightInputFieldProps | TooltipInputFieldProps | ButtonInputFieldProps | SuffixInputFieldProps)
) {
  const fieldResult = useField(props.name as string);

  const [field] = fieldResult ?? [];

  const { helpText, iconLeft, type, ...rest } = props;

  let iconRight: React.ReactNode | undefined;
  if ('iconRight' in props && props.iconRight) {
    iconRight = props.iconRight;
  }

  const disabled = props.disabled;
  const secret = type === 'secret';
  const value = props.value ?? field?.value;
  const onChange = props.onChange ?? field?.onChange;

  const tooltipIcon = (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 9C15 6.5 13.6562 4.21875 11.5 2.96875C9.3125 1.6875 6.65625 1.6875 4.5 2.96875C2.3125 4.21875 1 6.5 1 9C1 11.5312 2.3125 13.8125 4.5 15.0625C6.65625 16.3438 9.3125 16.3438 11.5 15.0625C13.6562 13.8125 15 11.5312 15 9ZM0 9C0 6.15625 1.5 3.53125 4 2.09375C6.46875 0.65625 9.5 0.65625 12 2.09375C14.4688 3.53125 16 6.15625 16 9C16 11.875 14.4688 14.5 12 15.9375C9.5 17.375 6.46875 17.375 4 15.9375C1.5 14.5 0 11.875 0 9ZM5.25 6.3125C5.46875 5.5625 6.15625 5 6.9375 5H8.75C9.84375 5 10.75 5.90625 10.75 7.03125C10.75 7.78125 10.3125 8.46875 9.65625 8.8125L8.5 9.40625V10C8.5 10.2812 8.25 10.5 8 10.5C7.71875 10.5 7.5 10.2812 7.5 10V9.09375C7.5 8.90625 7.59375 8.71875 7.75 8.65625L9.1875 7.90625C9.53125 7.75 9.75 7.40625 9.75 7.03125C9.75 6.46875 9.28125 6 8.75 6H6.9375C6.625 6 6.3125 6.25 6.21875 6.5625V6.59375C6.15625 6.84375 5.875 7.03125 5.625 6.9375C5.34375 6.875 5.1875 6.59375 5.25 6.34375V6.3125ZM7.25 12C7.25 11.5938 7.5625 11.25 8 11.25C8.40625 11.25 8.75 11.5938 8.75 12C8.75 12.4375 8.40625 12.75 8 12.75C7.5625 12.75 7.25 12.4375 7.25 12Z"
        fill="#604FED"
      />
    </svg>
  );

  const input = (
    <input
      {...field}
      {...(rest as React.InputHTMLAttributes<HTMLInputElement>)}
      ref={props.inputRef}
      id={props.id}
      name={props.name as string}
      className={`${styles['input-field-text']} !border-none`}
      placeholder={props.placeholder}
      disabled={disabled}
      type={type === 'secret' ? 'password' : type}
      value={value}
      onChange={onChange}
    />
  );

  let error = props.error;
  if (secret && !error) {
    if (value.toString() !== value.toString().trim()) {
      // Attempt to detect leading or trailing whitespace
      error = (
        <>
          We've detected leading or trailing whitespace in your input, for secrets this is usually a mistake.&nbsp;
          {onChange ? (
            <a href="#" onClick={event => {
              event.preventDefault();
              onChange({
                /** Simulate a EventTarget to pass through Formik */
                target: {
                  name: props.name as string,
                  value: value.toString().trim()
                }
              } as any);
            }}>
              Trim input
            </a>
          ) : null}
        </>
      );
    } else if (/[\p{DI}]/u.test(value)) {
      // Attempt to detect non-printable unicode characters
      error = (
        <>
          We've detected non-printable unicode characters, for secrets this is usually a mistake.&nbsp;
          {onChange ? (
            <a href="#" onClick={event => {
              event.preventDefault();
              onChange({
                /** Simulate a EventTarget to pass through Formik */
                target: {
                  name: props.name as string,
                  value: value.toString().replace(/[\p{DI}]/u, '')
                }
              } as any);
            }}>
              Remove unicode.
            </a>
          ) : null}
        </>
      );
    }
  }

  const inputField = (
    <div className="w-full">
      <label className={cx(className(props), props.className)} htmlFor={props.id}>
        {props.label ? (
          <div className={styles['input-wrapper']}>
            <span className={styles['input-label']}>{props.label}</span>
            {input}
          </div>
        ) : (
          <>{input}</>
        )}
        {iconRight ? <div className={styles['icon']}>{iconRight}</div> : null}
        {iconLeft ? <div className={`${styles['icon']} ${styles['icon-left']}`}>{iconLeft}</div> : null}
        {'button' in props && props.button && React.isValidElement(props.button) ? (
          <div className={styles['input-field-button']}>
            {cloneElement(props.button, { size: props.label ? 'xl' : 'md' })}
          </div>
        ) : null}
        {'suffix' in props && props.suffix ? (
          <div className={styles['input-field-suffix']}>
            {props.suffix}
          </div>
        ) : null}
        {'tooltip' in props && props.tooltip ? (
          <Tooltip id={`icon-${props.id}`} tooltip={props.tooltip} placement="top">
            <div id={`icon-${props.id}`} className={styles['icon']}>
              {tooltipIcon}
            </div>
          </Tooltip>
        ) : null}
      </label>
      {error && <div className={`${styles['help-text']} ${styles['invalid']}`}>{error}</div>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </div>
  );

  return inputField;
}
