import React, {useState, useEffect} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {upperFirst} from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import Button from '@app/components/Button';
import {deserializer, LogEntry, LogEntryList, getHref, getOptionalHref} from '@app/models';
import {translate} from '@app/i18n';
import {useDispatch } from '@app/redux';

import './LogsScreen.scss';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';
import { StandaloneSwitch } from '@app/components/Form/Form';
import { useTenantId } from '@app/hooks/useTenant';
import { LogsScreenQuery } from './__generated__/LogsScreenQuery.graphql';

export default function LogsScreen(props: {domainName?: string}) {
  const dispatch = useDispatch();
  const tenantId = useTenantId();
  const history = useHistory();
  const match = useRouteMatch<{domain?: string}>();
  const params = match.params;
  const baseUrl = params.domain ? match.url.replace(`/${params.domain}`, '') : match.url;
  const [pagination, setPagination] = useState<{prev: string | null; next: string |null}>({prev: null, next: null});
  const [search, setSearch] = useState('');
  const [entries, setEntries] = useState<LogEntry[]>([]);
  const [expanded, setExpanded] = useState<{[key: string]: boolean}>({});
  const [loadMorePending, setLoadMorePending] = useState(false);
  const environment = useEnvironment();
  const tracking = useTracking();
  const [timezone, setTimezone] = useState<'local' | 'utc'>('local');

  const data = useLazyLoadQuery<LogsScreenQuery>(
    graphql`
      query LogsScreenQuery($tenantId: ID!, $environment: Environment) {
        tenant(id: $tenantId) {
          longTenantId
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId,
      environment: environment
    }
  );

  useEffect(() => {
    if (!data.tenant) return;

    tracking.viewedLogsScreen(props.domainName ? 'domain' : undefined);
  }, [data.tenant, props.domainName]);


  const domains = data.tenant?.domains;
  const domain = domains?.edges.find(edge => props.domainName ? edge.node.name === props.domainName : edge.node.name === params.domain)?.node;

  const handleDomain = (event : React.ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) return history.push(baseUrl);
    history.push(`${baseUrl}/${event.target.value}`);
  };

  const loadMore = (url: string) => {
    if (!domain) throw new Error('loadMore(): domain is required');

    setLoadMorePending(true);

    dispatchVerifyRequest(dispatch, {
      method: 'GET',
      url
    }, deserializer(LogEntryList)).then(response => {
      setPagination(pagination => ({
        prev: getOptionalHref(response, 'pagination:prev'),
        next: pagination.next || getOptionalHref(response, 'pagination:next')
      }));

      setEntries(previous => previous.concat(response.entries));
      setLoadMorePending(false);
    });
  }

  const toggleEntry = (entry: LogEntry) => {
    setExpanded(expanded => ({
      ...expanded,
      [entry.cursor]: !expanded[entry.cursor]
    }));
  }

  useEffect(() => {
    if (!data.tenant) return;
    if (!domain) return;
    setEntries([]);
    loadMore(`/domains/${btoa(data.tenant.longTenantId)}/${domain.name}/http-log/`);
  }, [data.tenant, domain]);

  const className = props.domainName ? 'logs-screen' : 'container logs-screen';


  return (
    <div className={className}>
      <div className="action-bar">
        {!props.domainName && (
          <select className="form-control" onChange={handleDomain} value={domain && domain.name}>
            <option value="">{translate('HINT_CHOOSE_DOMAIN')}</option>
            {domains?.edges.map(edge => (
              <option key={edge.node.name} value={edge.node.name}>{edge.node.name}</option>
            ))}
          </select>
        )}
        <div className="search-input">
          <div className="fa fa-search" />
          <input type="search" placeholder="Search ..." value={search} onChange={(event) => setSearch(event.target.value)}/>
        </div>

        <StandaloneSwitch label="Show in local time" off="utc" on="local" value={timezone} onChange={value => setTimezone(value as any)} />
      </div>

      {entries.length ? (
        <div className="log-entry-wrapper">
          {entries.filter(entry => !search || JSON.stringify(entry).includes(search)).map(entry => (
            <div className="entry" key={entry.cursor}>
              <div className="header" onClick={() => toggleEntry(entry)}>
                <div className="timestamp">{(timezone === 'local' ? moment(entry.timestamp) : moment.utc(entry.timestamp)).format('YYYY-MM-DD HH:mm:ssZ')}</div>
                <div>{entry.statusCode}</div>
                <div>{entry.method}</div>
                <div className="url">{entry.url}</div>
              </div>
              <div className={cx('details', {'expanded': expanded[entry.cursor]})}>
                <div>
                  <strong>Request</strong>
                  <pre>
                    <strong>{entry.method}</strong> {entry.url}<br />
                    <HeadersFormatter headers={entry.requestHeaders} /><br />
                    {entry.requestBody}
                  </pre>
                </div>
                <div>
                  <strong>Response</strong>
                  <pre>
                    <strong>{entry.statusCode}</strong><br />
                    <HeadersFormatter headers={entry.responseHeaders} /><br />
                    {entry.responseBody}
                  </pre>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null }

      {pagination.prev && (
        <Button variant="primary" working={loadMorePending} onClick={() => loadMore(pagination.prev!)}>Load more</Button>
      )}
    </div>
  );
}

interface HeadersFormatterProps {
  headers: {[key: string]: string};
}

function HeadersFormatter(props: HeadersFormatterProps) {
  if (!Object.keys(props.headers).length) return null;

  return (
    <React.Fragment>
      {Object.keys(props.headers).map(key => (
        <React.Fragment key={key}>
          <strong>{upperFirst(key)}</strong>: {props.headers[key]}<br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}