import React from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { TechnologyTag } from '@app/models';
import TechnologySelector from '../TechnologySelector/TechnologySelector';
import useMutation from '@app/hooks/useMutation';
import { ApplicationTechnologySelectorMutation } from './__generated__/ApplicationTechnologySelectorMutation.graphql';
import { ApplicationTechnologySelector_application$key } from './__generated__/ApplicationTechnologySelector_application.graphql';

interface Props {
  application: ApplicationTechnologySelector_application$key,
  technologies: TechnologyTag[],
  onChange: (t: TechnologyTag) => void
}
export default function ApplicationTechnologySelector(props: Props) {
  const application = useFragment(graphql`
    fragment ApplicationTechnologySelector_application on Application {
      id
      technology
    }
  `, props.application);

  const applicationTechnology = (application.technology ? `technology:${application.technology}` : null) as (TechnologyTag | null);

  const [mutationExecutor, mutationState] = useMutation<ApplicationTechnologySelectorMutation>(graphql`
    mutation ApplicationTechnologySelectorMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          tags
          technology
          technologyGroup
        }
      }
    }
  `);

  const handleChange = async (t: TechnologyTag) => {
    props.onChange(t);
    await mutationExecutor.executePromise({
      input: {
        applicationId: application.id,
        technology: t
      }
    });
  }

  return (
    <TechnologySelector
      technology={applicationTechnology}
      disableOther={true} // Currently no support for "other" when changing technology for an existing application, not sure how to persist this yet.
      onChange={handleChange}
      technologies={props.technologies}
    />
  )
}