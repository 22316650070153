/**
 * @generated SignedSource<<f6a2c2a5e6da9ae2bcc925292f3770cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationErrorStrategy = "LEGACY" | "PROTOCOL" | "%future added value";
export type ApplicationScopeStrategy = "DYNAMIC" | "STATIC" | "%future added value";
export type ClaimPropertyFormat = "COMPACT" | "DEFAULT" | "NAMESPACED" | "%future added value";
export type NullClaimValues = "ALLOW" | "REMOVE" | "%future added value";
export type UserInfoResponseStrategy = "FROM_TOKEN_ENDPOINT" | "PLAIN_JSON" | "SIGNED_JWT" | "%future added value";
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
export type UpdateApplicationInput = {
  acrValues?: ReadonlyArray<string> | null;
  android?: VerifyApplicationAndroidInput | null;
  applicationId: string;
  callbackUrls?: ReadonlyArray<string> | null;
  clientJwks?: ApplicationJwksInput | null;
  errorStrategy?: ApplicationErrorStrategy | null;
  frameOrigin?: string | null;
  iOS?: VerifyApplicationIOSInput | null;
  metadata?: ReadonlyArray<MetadataInput> | null;
  name?: string | null;
  oauth2?: ApplicationOAuth2SettingsInput | null;
  oauth2ClientSecretEnabled?: boolean | null;
  realm?: string | null;
  scopeStrategy?: ApplicationScopeStrategy | null;
  sessionLifetime?: string | null;
  technology?: string | null;
  technologyGroup?: string | null;
  tokenLifetime?: string | null;
  ui?: UpdateApplicationUIInput | null;
};
export type UpdateApplicationUIInput = {
  cssClass?: string | null;
  javascript?: string | null;
  stylesheet?: string | null;
  viewVersion?: ViewVersion | null;
};
export type ApplicationOAuth2SettingsInput = {
  callbackOnLocationHash?: boolean | null;
  claimPropertyFormat?: ClaimPropertyFormat | null;
  nullClaimValues?: NullClaimValues | null;
  userInfoResponseStrategy?: UserInfoResponseStrategy | null;
};
export type ApplicationJwksInput = {
  static?: string | null;
  url?: string | null;
};
export type MetadataInput = {
  key: string;
  value: string;
};
export type VerifyApplicationAndroidInput = {
  packageName: string;
  sha256Fingerprints: ReadonlyArray<string>;
};
export type VerifyApplicationIOSInput = {
  bundleId: string;
  teamId: string;
};
export type GeneralSectionMutation$variables = {
  input: UpdateApplicationInput;
};
export type GeneralSectionMutation$data = {
  readonly updateApplication: {
    readonly application: {
      readonly domain: {
        readonly id: string;
        readonly name: string;
      };
      readonly id: string;
      readonly realm: string;
      readonly " $fragmentSpreads": FragmentRefs<"GeneralSection_application">;
    };
  };
};
export type GeneralSectionMutation = {
  response: GeneralSectionMutation$data;
  variables: GeneralSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Domain",
  "kind": "LinkedField",
  "name": "domain",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "realm",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateApplicationOutput",
        "kind": "LinkedField",
        "name": "updateApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GeneralSection_application"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneralSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateApplicationOutput",
        "kind": "LinkedField",
        "name": "updateApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isApplication"
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorStrategy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scopeStrategy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callbackUrls",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2074117a0a1e72b4f08e469b2006c771",
    "id": null,
    "metadata": {},
    "name": "GeneralSectionMutation",
    "operationKind": "mutation",
    "text": "mutation GeneralSectionMutation(\n  $input: UpdateApplicationInput!\n) {\n  updateApplication(input: $input) {\n    application {\n      __typename\n      id\n      domain {\n        id\n        name\n      }\n      realm\n      ...GeneralSection_application\n    }\n  }\n}\n\nfragment ApplicationRealmValidation_application on Application {\n  __isApplication: __typename\n  id\n  realm\n}\n\nfragment GeneralSection_application on Application {\n  __isApplication: __typename\n  __typename\n  id\n  name\n  errorStrategy\n  scopeStrategy\n  callbackUrls\n  ... on VerifyApplication {\n    realm\n  }\n  ... on AgeverificationApplication {\n    realm\n  }\n  domain {\n    id\n    name\n  }\n  ...ApplicationRealmValidation_application\n}\n"
  }
};
})();

(node as any).hash = "f308d452046d0a19edfcaba62cf90d3c";

export default node;
