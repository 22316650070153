import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import useEnvironment from '@app/hooks/useEnvironment';
import { useTenantId } from '@app/hooks/useTenant';
import { TenantKYCScreen_Query } from './__generated__/TenantKYCScreen_Query.graphql';
import { Form, FormError, FormSuccess, InputField, Select } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';
import { countries } from '@app/screens/BillingScreen/countries';
import useMutation from '@app/hooks/useMutation';
import { TenantKYCScreen_Mutation } from './__generated__/TenantKYCScreen_Mutation.graphql';

type Values = {
  companyName: string
  vatCountry: string
  vatNumber: string
}

export default function TenantKYCScreen() {
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<TenantKYCScreen_Query>(
    graphql`
      query TenantKYCScreen_Query($id: ID!) {
        admin {
          tenant(id: $id) {
            id
            kyc {
              companyName
              vatCountry
              vatNumber
            }
          }
        }
      }
    `,
    {
      id: tenantId.relayId
    }
  );

  const [executor, status] = useMutation<TenantKYCScreen_Mutation>(
    graphql`
      mutation TenantKYCScreen_Mutation($input: UpdateTenantKYCInput!) {
        admin_updateTenantKYC(input: $input) {
          adminTenant {
            kyc {
              companyName
              vatCountry
              vatNumber
            }
          }
        }
      }
    `
  );

  if (!data.admin.tenant) return null;

  const initialValues : Values = {
    companyName: data.admin.tenant.kyc?.companyName ?? '',
    vatCountry: data.admin.tenant.kyc?.vatCountry ?? '',
    vatNumber: data.admin.tenant.kyc?.vatNumber ?? ''
  };
  const handleSubmit = async (values: Values) => {
    if (!data.admin.tenant) return;

    await executor.executePromise({
      input: {
        tenantId: data.admin.tenant.id,
        companyName: values.companyName,
        vatCountry: values.vatCountry,
        vatNumber: values.vatNumber,
      }
    })
  }

  return (
    <div className="max-w-[750px]">
      <Form
        key="tenant_kyc"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className="flex flex-col gap-[16px]"
      >
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <InputField  type="text" label="Company name" name="companyName" required />

            <div className="flex flex-row gap-[16px]">
              <Select
                name="vatCountry"
                label="VAT Country"
                groupClassName="flex-1 basis-1/6"
                options={[
                  {value: '', label: 'Select country'}
                ].concat(Object.entries(countries).map(([key, value]) => ({
                  value: key,
                  label: value
                })))}
                required
              />

              <InputField groupClassName="grow !mt-0" type="text" label="VAT number" name="vatNumber" required />
            </div>

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'KYC details updated'} />
            )}
            <div>
              <Button variant="primary" type="submit" working={isPending}>Update KYC details</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}