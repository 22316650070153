/**
 * @generated SignedSource<<79c9e75090852eb930dcebe40cc14307>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApplicationErrorStrategy = "LEGACY" | "PROTOCOL" | "%future added value";
export type ApplicationScopeStrategy = "DYNAMIC" | "STATIC" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GeneralSection_application$data = {
  readonly __typename: string;
  readonly callbackUrls: ReadonlyArray<string>;
  readonly domain: {
    readonly id: string;
    readonly name: string;
  };
  readonly errorStrategy: ApplicationErrorStrategy;
  readonly id: string;
  readonly name: string;
  readonly realm?: string;
  readonly scopeStrategy: ApplicationScopeStrategy;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationRealmValidation_application">;
  readonly " $fragmentType": "GeneralSection_application";
};
export type GeneralSection_application$key = {
  readonly " $data"?: GeneralSection_application$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneralSection_application">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "realm",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneralSection_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorStrategy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scopeStrategy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callbackUrls",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "VerifyApplication",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "AgeverificationApplication",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationRealmValidation_application"
    }
  ],
  "type": "Application",
  "abstractKey": "__isApplication"
};
})();

(node as any).hash = "bc06970d1d771c46b0b5813f010883a1";

export default node;
