import React, { FormEvent, useEffect, useMemo, useState } from 'react';

import { PreloadedQuery, useFragment, useLazyLoadQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import moment from 'moment';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, LabelList } from 'recharts';


import { TenantUsageScreenQuery } from './__generated__/TenantUsageScreenQuery.graphql';
import { TenantRouteParams } from '@app/models';
import { useRouteMatch } from 'react-router-dom';
import useEnvironment from '@app/hooks/useEnvironment';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';

import './TenantUsageScreen.scss';
import Button from '@app/components/Button/Button';
import useMutation from '@app/hooks/useMutation';
import Alert from '@app/components/Alert/Alert';
import { useTenantId } from '@app/hooks/useTenant';

const Query =
  graphql`
    query TenantUsageScreenQuery (
      $tenantId: ID!,
      $environment: Environment!
      $from: String!
      $to: String!
    ) {
      admin {
        tenant(id: $tenantId) {
          tenant {
            id

            usage(environment: $environment, interval: MONTH, from: $from, to: $to) {
              bucket
              authentications
              signatures

              invoices {
                currency
                environment
                subtotal

                items {
                  unitAmount
                  quantity
                  description
                  subtotal
                }
              }
            }
          }
        }
      }
    }
  `

export default function TenantUsageScreen() {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();
  const [from, setFrom] = useState(() => moment().subtract(12, 'month').toDate());
  const [to, setTo] = useState(() => new Date());

  const [queryReference, loadQuery] = useQueryLoader<TenantUsageScreenQuery>(
    Query,
    null
  );

  useEffect(() => {
    loadQuery({
      tenantId: btoa(`tenant:${match.params.tenantId}`),
      environment: environment,
      from: from.toJSON(),
      to: to.toJSON()
    })
  }, [environment, match.params]);

  return (
    <div className="tenant-usage-screen">
      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <Results queryReference={queryReference} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}

      <TriggerConsumptionUpdate />

      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <Invoices queryReference={queryReference} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}
    </div>
  );
}

function Results(props: {
  queryReference: PreloadedQuery<TenantUsageScreenQuery>,
}) {
  const data = usePreloadedQuery<TenantUsageScreenQuery>(Query, props.queryReference);
  const usage = data.admin.tenant?.tenant.usage ?? [];

  return (
    <React.Fragment>
      <h2>Authentication usage</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={usage as any}
        >
          <CartesianGrid stroke="#faf6f6" fill="#FFF"  />
          <XAxis
            dataKey="bucket"
            interval="preserveStartEnd"
            label="Month"
          />
          <YAxis
            domain={[0, 'auto']}
            tickCount={7}
            padding={{top: 25}}
          />
          <Tooltip cursor={{fill: 'transparent'}} />
          <Bar dataKey="authentications" name="Authentications" fill="#E28A85">
            <LabelList dataKey="authentications" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <h2>Signatures usage</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={usage as any}
        >
          <CartesianGrid stroke="#faf6f6" fill="#FFF"  />
          <XAxis
            dataKey="bucket"
            label="Month"
            interval="preserveStartEnd"
          />
          <YAxis
            domain={[0, 'auto']}
            tickCount={7}
            padding={{top: 25}}
          />
          <Tooltip cursor={{fill: 'transparent'}} />
          <Bar dataKey="signatures" name="Signatures" fill="#5A4BDB" label="123" >
            <LabelList dataKey="signatures" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

function TriggerConsumptionUpdate() {
  const tenantId = useTenantId();
  const [date, setDate] = useState('');

  const [executor, state] = useMutation(graphql`
    mutation TenantUsageScreen_TriggerConsumptionUpdate_Mutation($input: TriggerTenantConsumptionInput!) {
      admin_triggerTenantConsumption(input: $input) {
        id
      }
    }
  `)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    executor.execute({
      input: {
        tenantId: tenantId.relayId,
        date: (new Date(`${date}T00:00:00Z`)).toJSON()
      }
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-row gap-[8px]">
        <input type="date" value={date} onChange={(event) => setDate(event.target.value)} disabled={state.pending} />
        <Button type="submit" variant="default" working={state.pending}>
          Trigger update
        </Button>
      </div>

      {state.error ? (
        <Alert variant="error" title="An error occurred" className="mt-[15px]" message={state.error.message} />
      ) : null}

      {state.success ? (
        <Alert variant="success" title="Update triggered" className="mt-[15px]" />
      ) : null}
    </form>
  )
}

function Invoices(props: {
  queryReference: PreloadedQuery<TenantUsageScreenQuery>,
}) {
  const data = usePreloadedQuery<TenantUsageScreenQuery>(Query, props.queryReference);
  const usage = data.admin.tenant?.tenant.usage ?? [];
  const [date, setDate] = useState(() => new Date());
  const bucket = useMemo(() => moment(date).format('YYYY-MM'), [date]);
  const invoices = usage.find(s => s.bucket === bucket)?.invoices ?? [];

  return (
    <div className="mt-[25px]">
      <h3 className="font-bold text-black" style={{fontSize: '20px'}}>eID consumption invoice data for {bucket}</h3>
      <div className="flex flex-row gap-[8px] mb-[16px]">
        <Button variant="default" onClick={() => setDate(moment(date).subtract(1, 'months').toDate())}>
          Previous month
        </Button>
        <Button variant="default" onClick={() => setDate(moment(date).add(1, 'months').toDate())}>
          Next month
        </Button>
      </div>
      <div className="flex flex-col gap-[16px]">
        {invoices.map(invoice => (
          <div key={invoice.currency}>
            <details>
              <summary>
                <strong>{invoice.subtotal / 100} {invoice.currency}</strong> (based on {invoice.environment} data)<br />
              </summary>
              <ul className="list-none">
                {invoice.items.map(item => (
                  <li key={item.description}>
                    {item.unitAmount / 100} {invoice.currency} * {item.quantity} = {item.subtotal / 100} {invoice.currency}
                    <p>
                      {item.description}
                    </p>
                  </li>
                ))}
              </ul>
            </details>
          </div>
        ))}
      </div>
    </div>
  );
}