import { StandaloneSwitch } from '@app/components/Form/Form';
import { toLongTenantId, useTenantId } from '@app/hooks/useTenant';
import cx from 'classnames';
import { deserializer, DiagnosticsEntry, DiagnosticsEntryList, getHref, getOptionalHref } from '@app/models';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import React, { useEffect, useState } from 'react';
import {useDispatch } from '@app/redux';

import './DiagnosticsScreen.scss';
import Button from '@app/components/Button/Button';
import moment from 'moment';

export default function DiagnosticsScreen() {
  const tenantId = useTenantId();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState<{prev: string | null; next: string |null}>({prev: null, next: null});
  const [search, setSearch] = useState('');
  const [entries, setEntries] = useState<DiagnosticsEntry[]>([]);
  const [timezone, setTimezone] = useState<'local' | 'utc'>('local');
  const [loadMorePending, setLoadMorePending] = useState(false);
  const [expanded, setExpanded] = useState<{[key: string]: boolean}>({});

  const loadMore = (url: string) => {
    setLoadMorePending(true);

    dispatchVerifyRequest(dispatch, {
      method: 'GET',
      url
    }, deserializer(DiagnosticsEntryList)).then(response => {
      setPagination(pagination => ({
        prev: getOptionalHref(response, 'pagination:prev'),
        next: pagination.next || getOptionalHref(response, 'pagination:next')
      }));

      setEntries(previous => previous.concat(response.entries));
      setLoadMorePending(false);
    });
  }

  const toggleEntry = (entry: DiagnosticsEntry) => {
    setExpanded(expanded => ({
      ...expanded,
      [entry.cursor]: !expanded[entry.cursor]
    }));
  }

  useEffect(() => {
    if (!tenantId) return;
    setEntries([]);
    const url = `/backoffice/${btoa(toLongTenantId(tenantId.tenantId))}/diagnostics-log/`
    loadMore(url);
  }, [tenantId]);

  return (
    <div className="diagnostics-screen">
      <div className="action-bar">
        <div className="search-input">
          <div className="fa fa-search" />
          <input type="search" placeholder="Search ..." value={search} onChange={(event) => setSearch(event.target.value)}/>
        </div>

        <StandaloneSwitch label="Show in local time" off="utc" on="local" value={timezone} onChange={value => setTimezone(value as any)} />
      </div>

      {entries.length ? (
        <div className="log-entry-wrapper">
          {entries.filter(entry => !search || JSON.stringify(entry).toLowerCase().includes(search.toLowerCase())).map(entry => (
            <div className="entry" key={entry.cursor}>
              <div className="header" onClick={() => toggleEntry(entry)}>
                <div className="timestamp">{(timezone === 'local' ? moment(entry.timestamp) : moment.utc(entry.timestamp)).format('YYYY-MM-DD HH:mm:ssZ')}</div>
                <div className="nowrap">{entry.requestDomain}</div>
                <div className="nowrap">{entry.errorEvent}</div>
                <div className="message">{entry.message}</div>
              </div>
              <div className={cx('details', {'expanded': expanded[entry.cursor]})}>
                <pre>
                  {entry.message}
                </pre>
              </div>
            </div>
          ))}
        </div>
      ) : null }

      {pagination.prev && (
        <Button variant="primary" working={loadMorePending} onClick={() => loadMore(pagination.prev!)}>Load more</Button>
      )}
    </div>
  );
}