/**
 * @generated SignedSource<<5cdee9934dfa64dfe0aa365078ba5d53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type ChangePlanInput = {
  planId: string;
  product: Product;
  subscriptionId: string;
};
export type SubscriptionPlanModal_ChangeMutation$variables = {
  input: ChangePlanInput;
};
export type SubscriptionPlanModal_ChangeMutation$data = {
  readonly changePlan: {
    readonly product: {
      readonly " $fragmentSpreads": FragmentRefs<"SubscriptionScreen_ProductPanel_product">;
    };
    readonly subscription: {
      readonly isCancelling: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"SubscriptionScreen_ProductPanel_subscription">;
    };
    readonly tenant: {
      readonly ageverificationEnvironments: ReadonlyArray<Environment>;
      readonly environments: ReadonlyArray<Environment>;
      readonly signaturesEnvironments: ReadonlyArray<Environment>;
      readonly verifyEnvironments: ReadonlyArray<Environment>;
    };
  };
};
export type SubscriptionPlanModal_ChangeMutation = {
  response: SubscriptionPlanModal_ChangeMutation$data;
  variables: SubscriptionPlanModal_ChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelling",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v4 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v5 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v6 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionPlanModal_ChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangePlanOutput",
        "kind": "LinkedField",
        "name": "changePlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionScreen_ProductPanel_subscription"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubscriptionProduct",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionScreen_ProductPanel_product"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriptionPlanModal_ChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangePlanOutput",
        "kind": "LinkedField",
        "name": "changePlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionProduct",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plan",
                    "kind": "LinkedField",
                    "name": "plan",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubscriptionProduct",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Plan",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "volume",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "legacyId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowRemove",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "231f2e0b54419bc86ab0fec313875fe9",
    "id": null,
    "metadata": {},
    "name": "SubscriptionPlanModal_ChangeMutation",
    "operationKind": "mutation",
    "text": "mutation SubscriptionPlanModal_ChangeMutation(\n  $input: ChangePlanInput!\n) {\n  changePlan(input: $input) {\n    subscription {\n      isCancelling\n      ...SubscriptionScreen_ProductPanel_subscription\n      id\n    }\n    product {\n      ...SubscriptionScreen_ProductPanel_product\n      id\n    }\n    tenant {\n      environments\n      verifyEnvironments: environments(product: Verify)\n      signaturesEnvironments: environments(product: Signatures)\n      ageverificationEnvironments: environments(product: [Ageverification_DK])\n      id\n    }\n  }\n}\n\nfragment RemoveProductPlanModal_subscription on BillingSubscription {\n  id\n}\n\nfragment SubscriptionPlanModal_plan on Plan {\n  id\n  name\n  legacyId\n  currency\n  interval\n  product\n  subscriptionPrice\n}\n\nfragment SubscriptionPlanModal_subscription on BillingSubscription {\n  id\n  isCancelling\n  currency\n  interval\n  products {\n    plan {\n      id\n    }\n    id\n  }\n}\n\nfragment SubscriptionScreen_ProductPanel_product on SubscriptionProduct {\n  product\n  plan {\n    id\n    name\n    currency\n    interval\n    volume\n    product\n    subscriptionPrice\n    unitPrice\n    ...SubscriptionPlanModal_plan\n  }\n  allowRemove\n}\n\nfragment SubscriptionScreen_ProductPanel_subscription on BillingSubscription {\n  status\n  ...SubscriptionPlanModal_subscription\n  ...RemoveProductPlanModal_subscription\n}\n"
  }
};
})();

(node as any).hash = "2488af582bea3be0f2cd357033413eb2";

export default node;
