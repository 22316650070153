import React, { useReducer, useState } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { useTenantId } from '@app/hooks/useTenant';
import { getErrorString, RtkError } from '@app/api/helper';
import { Tag } from '@app/components/Tag';
import { upperFirst } from 'lodash';
import Panel from '@app/components/Panel';
import moment from 'moment';
import Button from '@app/components/Button/Button';
import CancelSubscriptionModal from './components/CancelSubscriptionModal/CancelSubscriptionModal';
import SubscriptionPlanModal from './components/SubscriptionPlanModal/SubscriptionPlanModal';
import { StripeElementsProvider } from '@app/stripe';
import ConfirmPaymentButton from './components/ConfirmPaymentButton/ConfirmPaymentButton';
import { StripeError } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import { SubscriptionScreen_Query } from './__generated__/SubscriptionScreen_Query.graphql';
import { SubscriptionScreen_ProductPanel_subscription$key } from './__generated__/SubscriptionScreen_ProductPanel_subscription.graphql';
import { Product, SubscriptionScreen_ProductPanel_product$key } from './__generated__/SubscriptionScreen_ProductPanel_product.graphql';
import { SubscriptionScreen_ProductPanels_subscription$key } from './__generated__/SubscriptionScreen_ProductPanels_subscription.graphql';
import RemoveProductPlanModal from './components/RemoveProductPlanModal';
import useMutation from '@app/hooks/useMutation';
import Alert from '@app/components/Alert/Alert';
import { productName, transactionName } from '../../utils';

export default function SubscriptionScreen() {
  const tenantId = useTenantId();
  const [showPlanModal, togglePlanModal] = useReducer(value => !value, false);
  const [planChanged, setPlanChanged] = useState(false);
  const [showCancelModal, toggleCancelModal] = useReducer(value => !value, false);

  const [paymentConfirmError, setPaymentConfirmError] = useState<StripeError | RtkError | null>(null);

  const data = useLazyLoadQuery<SubscriptionScreen_Query>(
    graphql`
      query SubscriptionScreen_Query($tenantId: ID!) {
        tenant(id: $tenantId) {
          shortTenantId
          viewerPermissions {
            billing
          }

          billing {
            subscription {
              id
              status
              isCancelling
              periodStartAt
              periodEndAt

              paymentIntent {
                id
              }
              plan {
                ... SubscriptionPlanModal_plan
              }
              ...SubscriptionPlanModal_subscription
              ...ConfirmPaymentButton_subscription
              ...SubscriptionScreen_ProductPanels_subscription
            }
          }

          ...CancelSubscriptionModal_tenant
        }
      }
    `,
    {
      tenantId: tenantId.relayId
    }
  );

  const [reactivateExecutor, reactivateStatus] = useMutation(
    graphql`
      mutation SubscriptionScreen_ReactivateMutation($input: ReactivateSubscriptionInput!) {
        reactivateSubscription(input: $input) {
          subscription {
            status
            isCancelling
          }
        }
      }
    `
  );

  const hasAccess = data.tenant?.viewerPermissions.billing === "READ" || data.tenant?.viewerPermissions.billing === "WRITE";
  const subscription = data?.tenant?.billing?.subscription;
  if (!data.tenant || !hasAccess) return null;

  const handleCancelSubscription = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleCancelModal();
  }

  const handleReactivateSubscription = (event: React.MouseEvent) => {
    event.preventDefault();
    reactivateExecutor.execute({input: {subscriptionId: subscription!.id}});
  }

  const isCancelling = subscription?.isCancelling;
  const isIncomplete = subscription?.status === "incomplete";
  const isPastDue = subscription?.status === "past_due";
  const requiresPaymentConfirmation = (isIncomplete || isPastDue) && subscription.paymentIntent?.id

  return (
    <React.Fragment>
      {subscription ? (
        <div className="flex gap-[16px]">
          <Panel title="Subscription status">
            <div className="flex flex-col items-start gap-[16px]">
              <Tag>{isCancelling ? 'Canceling' : upperFirst(subscription.status)}</Tag>
              {isIncomplete ? (
                'Confirm payment with 3D secure to activate subscription'
              ) : (isPastDue && requiresPaymentConfirmation) ? (
                'Confirm payment for latest invoice with 3D secure to avoid cancellation'
              ) : isCancelling ? (
                'Cancels on ' + moment(subscription.periodEndAt).format('MMMM Do, YYYY')
              ) : subscription.status === 'canceled' ? (
                '-'
              ) : (
                'Renews on ' + moment(subscription.periodEndAt).format('MMMM Do, YYYY')
              )}
            </div>

            {paymentConfirmError && (
              <Alert variant="error" className="mt-[15px]" title="An error occurred" message={getErrorString(paymentConfirmError) || undefined} />
            )}
            {reactivateStatus.error && (
              <Alert variant="error" className="mt-[15px]" title="An error occurred" message={reactivateStatus.error.message} />
            )}

            <div className="flex flex-row justify-between items-center">
              <StripeElementsProvider>
                <ConfirmPaymentButton subscription={data.tenant?.billing?.subscription} onSucces={() => {}} onError={setPaymentConfirmError} />
              </StripeElementsProvider>

              {!isCancelling && subscription.status !== 'canceled' ? (
                <a href="#" onClick={handleCancelSubscription} className="!underline">Cancel subscription</a>
              ) : (
                <a href="#" onClick={handleReactivateSubscription} className="!underline">
                  {reactivateStatus.pending ? (
                    <i className="fa fa-spinner fa-pulse" />
                  ) : 'Reactivate subscription'}
                </a>
              )}
            </div>
          </Panel>

          <ProductPanels subscription={subscription} />
        </div>
      ) : (
        <div>
          Currently on a free/developer plan.
          Please <Link to={`/tenant/${data.tenant.shortTenantId}/billing/signup`} className="!underline">sign up for a subscription.</Link>
        </div>
      )}

      <CancelSubscriptionModal open={showCancelModal} onCancel={toggleCancelModal} tenant={data.tenant} />
      <React.Suspense fallback={null}>
        <SubscriptionPlanModal
          open={showPlanModal}
          onHide={togglePlanModal}
          onChange={() => {togglePlanModal(); setPlanChanged(true);}}
          subscription={data.tenant.billing?.subscription ?? null}
          plan={data.tenant.billing?.subscription?.plan ?? null}
        />
      </React.Suspense>
    </React.Fragment>
  );
}

function ProductPanels(props: {
  subscription: SubscriptionScreen_ProductPanels_subscription$key
}) {
  const subscription = useFragment(
    graphql`
      fragment SubscriptionScreen_ProductPanels_subscription on BillingSubscription {
        ... SubscriptionScreen_ProductPanel_subscription
        products {
          id
          ... SubscriptionScreen_ProductPanel_product
        }
      }
    `,
    props.subscription
  );
  const products = subscription.products;

  return (
    <React.Fragment>
      {products.map(product => (
        <ProductPanel key={product.id} subscription={subscription} product={product} />
      ))}
    </React.Fragment>
  );
}

function ProductPanel(props: {
  product: SubscriptionScreen_ProductPanel_product$key,
  subscription: SubscriptionScreen_ProductPanel_subscription$key
}) {
  const product = useFragment(
    graphql`
      fragment SubscriptionScreen_ProductPanel_product on SubscriptionProduct {
        product
        plan {
          id
          name
          currency
          interval
          volume
          product
          subscriptionPrice
          unitPrice

          ... SubscriptionPlanModal_plan
        }
        allowRemove
      }
    `,
    props.product
  );
  const subscription = useFragment(
    graphql`
      fragment SubscriptionScreen_ProductPanel_subscription on BillingSubscription {
        status
        ... SubscriptionPlanModal_subscription
        ... RemoveProductPlanModal_subscription
      }
    `,
    props.subscription
  );
  const [showPlanModal, togglePlanModal] = useReducer(value => !value, false);
  const [showRemoveModal, toggleRemoveModal] = useReducer(value => !value, false);
  const [planChanged, setPlanChanged] = useState(false);

  return (
    <React.Fragment>
      <Panel title={`Your ${productName(product.product).toLowerCase()} plan`} data-test-id={`product_panel_${product.product}`}>
        {product.plan ? (
          <React.Fragment>
            <div className="mb-16">
              <p className="!m-0 text-3xl">
                <span data-test-id={`product_panel_${product.product}_name`}>{product.plan.name}</span>&nbsp;-&nbsp;
                <span data-test-id={`product_panel_${product.product}_price`}>{product.plan.subscriptionPrice.toLocaleString()}</span>&nbsp;
                {product.plan.currency?.toUpperCase()} per {product.plan.interval.toLowerCase()}
              </p>
              <p className="!m-0 !mt-8 text-2xl">
                {product.plan.volume.toLocaleString()} {transactionName(product.plan.product, true)}
              </p>
              <p className="!m-0 !mt-4 text-xl">
                {product.plan.currency} {product.plan.unitPrice} per {transactionName(product.plan.product, false)} if you use more than {product.plan.volume.toLocaleString()} {transactionName(product.plan.product, true)}.
              </p>
              {product.plan.product.startsWith('Ageverification') ? (
                <p className="!m-0 !mt-4 text-xl">
                  Fees for underlying national/bank e-ID included
                </p>
              ): (
                <p className="!m-0 !mt-4 text-xl">
                  <a href="https://www.criipto.com/pricing#fees-block" target="_blank">Fees for underlying national/bank e-ID billed separately</a>
                </p>
              )}
            </div>
            <div className="flex flex-row gap-4">
              <Button variant="default" className="flex-grow" onClick={() => togglePlanModal()} data-test-id={`product_panel_${product.product}_change_plan`}>Change plan</Button>
              {product.allowRemove ? (
                <Button
                  variant="danger"
                  autoWidth
                  onClick={() => toggleRemoveModal()}
                  data-test-id={`product_panel_${product.product}_remove_plan`}
                >
                  <i className="fa fa-trash-alt" />
                </Button>
              ) : null}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="mb-16">
              <p className="!m-0 text-3xl">No plan activated</p>
            </div>
            <Button variant="default" onClick={() => togglePlanModal()} data-test-id={`product_panel_${product.product}_add_plan`}>Add plan</Button>
          </React.Fragment>
        )}
        {planChanged && (
          <Alert variant="success" className="mt-[15px]" title={`${productName(product.product)} plan updated!`}/>
        )}
      </Panel>
      <React.Suspense fallback={null}>
        <SubscriptionPlanModal
          open={showPlanModal}
          onHide={togglePlanModal}
          onChange={() => {togglePlanModal(); setPlanChanged(true);}}
          product={product.product}
          subscription={subscription}
          plan={product.plan}
        />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <RemoveProductPlanModal
          open={showRemoveModal}
          onCancel={toggleRemoveModal}
          onRemoved={toggleRemoveModal}
          product={product.product}
          subscription={subscription}
        />
      </React.Suspense>
    </React.Fragment>
  )
}