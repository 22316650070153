import React, {useEffect, useState} from 'react';
import { PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import useQuery from '@app/hooks/useQuery';
import { UsersScreen_Admin_Query } from './__generated__/UsersScreen_Admin_Query.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import InputField from '@app/components/FormFields/InputField/InputField';
import { UsersScreen_AdminUserItem_user$key } from './__generated__/UsersScreen_AdminUserItem_user.graphql';

type Props = {
  breadcrumb: React.ReactNode
}

const SearchQuery = graphql`
  query UsersScreen_Admin_Query($query: String!, $skip: Boolean!) {
    admin @skip(if: $skip) {
      users(query: $query) {
        id
        ... UsersScreen_AdminUserItem_user
      }
    }
  }
`;

export default function CriiptoAdminUsersScreen(props: Props) {
  const {breadcrumb} = props;
  const match = useRouteMatch();
  const history = useHistory();
  const routeQuery = useQuery();
  const [search, setSearch] = useState(routeQuery.get('query') || '');
  const debouncedSearch = useDebounce(search, 200);

  const [queryReference, loadQuery] = useQueryLoader<UsersScreen_Admin_Query>(
    SearchQuery,
    null
  );

  useEffect(() => {
    history.push(`${removeTrailingSlash(match.url)}?query=${debouncedSearch}`);
    loadQuery({
      query: debouncedSearch,
      skip: !debouncedSearch?.length
    });
  }, [debouncedSearch]);

  return (
    <>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          {breadcrumb}
          <i className="fa fa-angle-right" />
          <div>Search</div>
        </div>
        <div className="flex flex-row justify-between">
          <h1>Criipto Backoffice</h1>
        </div>
        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={`/admin/tenants`} activeClassName="active">Tenants</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/organizations`} activeClassName="active">Organizations</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/users`} activeClassName="active">Users</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-content-main max-w-[1280px] !pr-[50px]">
        <div className="grid grid-cols-[90%_5%] gap-[10px] items-start w-full">
          <InputField
            type="search"
            name="user-search"
            label="Search for a user"
            value={search}
            onChange={event => setSearch(event.target.value)}
            helpText="Hint: Search for user email"
          />
        </div>

        <div className="mt-[10px]">
          {queryReference ? (
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <Results queryReference={queryReference} />
              </React.Suspense>
            </GraphQLErrorBoundary>
          ) : null}
        </div>
      </div>
    </>
  )
}

function Results(props: {queryReference: PreloadedQuery<UsersScreen_Admin_Query>}) {
  const data = usePreloadedQuery<UsersScreen_Admin_Query>(SearchQuery, props.queryReference);
  return (
    <div>
      {data.admin?.users && (
        <>
          {data.admin?.users.map(user => (
            <UserItem user={user} key={user.id} />
          ))}
        </>
      )}
    </div>
  );
}

function UserItem(props: {user: UsersScreen_AdminUserItem_user$key}) {
  const user = useFragment(
    graphql`
      fragment UsersScreen_AdminUserItem_user on AdminUser {
        id
        sub
        name
        email
      }
    `,
    props.user
  );

  return (
    <Link className="block p-4 border-b border-primary-700 flex flex-row justify-between" to={`/admin/users/${user.id}`}>
      <div>
        <strong>{user.email}</strong><br />
        {user.sub} - {user.name}
      </div>
    </Link>
  );
}

function removeTrailingSlash(input: string) {
  if (input.endsWith('/')) {
    return input.substring(0, input.length - 1);
  }
  return input;
}
function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}