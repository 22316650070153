import React, {useState} from 'react';
import cx from 'classnames';
import { Formik, Field, Form as FormikForm, FormikHelpers, FormikValues, useField, FieldAttributes, FormikErrors, FormikSharedConfig } from 'formik';
import {omit} from 'lodash';

import Button from '@components/Button';
import './Form.scss';
import { getErrorString, RtkError } from '@app/api/helper';
import useMounted from '@app/hooks/useMounted';
import Alert from '@app/components/Alert/Alert';

export interface RenderProps<T = FormikValues> {
  isPending: boolean,
  isSuccess: boolean,
  error: string | null,
  values: T,
  errors: FormikErrors<T>
  setValues: (values: React.SetStateAction<T>, shouldValidate?: boolean | undefined) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  resetForm: () => void
}

interface FormProps<T = FormikValues> {
  initialValues: T,
  enableReinitialize?: FormikSharedConfig["enableReinitialize"]
  onSubmit: (values : T, actions : FormikHelpers<T>) => Promise<any>,
  children: React.ReactNode | ((props : RenderProps<T>) => React.ReactNode),
  key: string,
  className?: string,
  style?: React.CSSProperties
}

const isFunction = (obj: any): obj is Function =>
  typeof obj === 'function';

export function Form<T extends FormikValues>(props : FormProps<T>) {
  const isMounted = useMounted();
  const [error, setError] = useState<string | null>(null);
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const render =
    isFunction(props.children) ?
    props.children :
    (_ : RenderProps<T>) => props.children

  return (
    <Formik<T>
      initialValues={props.initialValues}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, actions) => {
        if (pending) return Promise.resolve();

        setPending(true);
        setSuccess(false);
        setError(null);

        return props.onSubmit(values, actions).then(result => {
          if (isMounted.current) setSuccess(true);
          return result;
        }).catch((error) => {
          if (isMounted.current) setError(getErrorString(error));
        }).finally(() => {
          if (isMounted.current) setPending(false);
        })
      }}
    >
      {({values, setValues, setFieldValue, errors, resetForm}) => (
        <FormikForm className={props.className} style={props.style}>
          {render({isPending: pending, error, errors, isSuccess: success, values, setValues, setFieldValue, resetForm})}
        </FormikForm>
      )}
    </Formik>
  )
}

export interface InputFieldProps<T> {
  name: T extends never ? string : keyof T
  type?: string
  label?: string,
  disabled?: boolean,
  placeholder?: string,
  help?: React.ReactNode
  required?: boolean
  pattern?: string,
  minLength?: number,
  maxLength?: number,
  validate?: FieldAttributes<any>["validate"],
  'data-testid'?: string,
  groupClassName?: string,
  trim?: boolean,
  style?: React.CSSProperties,
  as?: string,
  autocomplete?: boolean
}

export function InputField<T = never>(props : InputFieldProps<T>) {
  const [field, _, {setValue}] = useField(props.name as string);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = props.trim === true ? value.trim() : value;
    setValue(value);
  }

  if (!props.label) return <Field className="form-control" {...omit(props, 'label', 'help', 'trim')} onChange={handleChange} />;

  return (
    <div className={cx('form-group', props.groupClassName)}>
      <label className="control-label">{props.label}</label>
      <Field
        className="form-control"
        {...omit(props, 'label', 'help', 'groupClassName', 'trim')}
        onChange={handleChange}
        style={props.style}
        as={props.as}
        autoComplete={props.autocomplete}
      />
      {props.help}
    </div>
  );
}

export interface SelectProps<T> {
  name: T extends never ? string : keyof T
  label?: string
  options: {label: string, value: string}[],
  className?: string,
  groupClassName?: string,
  required?: boolean,
  help?: React.ReactNode,
  disabled?: boolean,
}

export function Select<T = never>(props : SelectProps<T>) {
  return (
    <div className={cx('form-group', props.groupClassName)}>
      {props.label && (<label className="control-label">{props.label}</label>)}
      <Field as="select" name={props.name} className={props.className} required={props.required} disabled={props.disabled}>
        {props.options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Field>
      {props.help}
    </div>
  );
}

interface FileUploadProps {
  name: string
  label: string,
  placeholder?: string
}

export function FileUpload(props : FileUploadProps) {
  const [field, meta, {setValue}] = useField(props.name);
  const handleFile = (event : any) => {
    setValue(event.target.files[0]);
  }

  return (
    <div className="form-group">
      <label className="control-label">{props.label}</label>
      <div className="form-control-file">
        <input type="text" className="form-control" placeholder={props.placeholder} value={field.value?.name || ''} readOnly />
        <Button variant="primary" size="md"><i className="fa fa-upload" /></Button>
        <Field className="form-control" type="file" value="" onChange={handleFile}/>
      </div>
    </div>
  );
}


interface StandaloneSwitchStringProps {
  off: string
  on: string
  value: string
  onChange: (value: string) => void
}
interface StandaloneSwitchBooleanProps {
  value: boolean
  onChange: (value: boolean) => void
}
type StandaloneSwitchProps = (StandaloneSwitchStringProps | StandaloneSwitchBooleanProps) & {
  label?: string,
  help?: React.ReactNode
  className?: string
  flip?: boolean
}

export function StandaloneSwitch(props: StandaloneSwitchProps) {
  const handleChange = (checked: boolean) => {
    if ("on" in props) {
      props.onChange(checked ? props.on : props.off);
      return;
    }
    props.onChange(checked);
  }

  const handleChangeEvent = (event : React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked);
  }

  const handleClick = (event : React.MouseEvent) => {
    handleChange("on" in props ? props.value !== props.on : !props.value);
  };

  if (!props.label) {
    return (
      <label className={cx('switch', props.className)}>
        <input onChange={handleChangeEvent} type="checkbox" checked={"on" in props ? props.on === props.value : props.value} /><i />
      </label>
    );
  }

  return (
    <div className={cx('form-group', props.className)}>
      <div className="flex flex-row items-center">
        {props.flip ? (
          <>
            <label className="control-label" onClick={handleClick}>{props.label}</label>
            <label className="switch ml-4">
              <input onChange={handleChangeEvent} type="checkbox" checked={"on" in props ? props.on === props.value : props.value} /><i />
            </label>
          </>
        ) : (
          <>
            <label className="switch mr-4">
              <input onChange={handleChangeEvent} type="checkbox" checked={"on" in props ? props.on === props.value : props.value} /><i />
            </label>
            <label className="control-label" onClick={handleClick}>{props.label}</label>
          </>
        )}
      </div>
      {props.help}
    </div>
  )
}

interface SwitchStringProps {
  off: string
  on: string
  value?: string
  onChange?: (value: string) => void
}
interface SwitchBooleanProps {
  value?: boolean
  onChange?: (value: boolean) => void
}
type SwitchProps<T> = (SwitchStringProps | SwitchBooleanProps) & {
  name: T extends never ? string : keyof T
  label?: string,
  help?: React.ReactNode
}

export function Switch<T = never>(props : SwitchProps<T>) {
  const [field, meta, {setValue}] = useField(props.name.toString());

  const handleChange = (value: string | boolean) => {
    if (props.onChange) {
      if ("on" in props) {
        props.onChange(value as string);
      } else {
        props.onChange(value as boolean);
      }
    } else {
      setValue(value);
    }
  }

  return (
    <StandaloneSwitch
      {...props}
      onChange={handleChange}
      value={props.value ?? field.value}
    />
  );
}

interface FormErrorProps {
  error: string | undefined | null | false,
  className?: string
}
export function FormError(props : FormErrorProps) {
  if (!props.error) return null;

  return (
    <div className={`form-group ${props.className || ''}`}>
      <Alert variant="error" className="mt-[15px]" title="An error occurred" message={getErrorString(props.error) || undefined} />
    </div>
  );
}

interface FormSuccessProps {
  message: string | undefined | null | false
  className?: string
}
export function FormSuccess(props : FormSuccessProps) {
  if (!props.message) return null;

  return (
    <div className={`form-group ${props.className || ''}`}>
      <Alert variant="success" className="mt-[15px]" title={props.message}/>
    </div>
  );
}