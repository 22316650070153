import React, { useEffect, useState } from 'react';
import { PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { ENTITY_ID_PREFIX } from '@app/constants';
import useQuery from '@app/hooks/useQuery';
import { TenantsScreen_AdminTenantItem_tenant$key } from './__generated__/TenantsScreen_AdminTenantItem_tenant.graphql';
import { TenantsScreen_Admin_Query } from './__generated__/TenantsScreen_Admin_Query.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import InputField from '@app/components/FormFields/InputField/InputField';

function removeTrailingSlash(input: string) {
  if (input.endsWith('/')) {
    return input.substring(0, input.length - 1);
  }
  return input;
}
function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const SearchQuery = graphql`
  query TenantsScreen_Admin_Query($query: String!, $limit: Int! $skip: Boolean!) {
    admin @skip(if: $skip) {
      tenants(query: $query, limit: $limit) {
        id
        ...TenantsScreen_AdminTenantItem_tenant
      }
    }
  }
`;

type Props = {
  breadcrumb: React.ReactNode
}
export default function CriiptoAdminTenantsScreen(props: Props) {
  const {breadcrumb} = props;
  const match = useRouteMatch();
  const history = useHistory();
  const routeQuery = useQuery();
  const [search, setSearch] = useState(routeQuery.get('query') || '');
  const [limit, setLimit] = useState(routeQuery.get('limit') ? parseInt(routeQuery.get('limit')!, 10) : 10);
  const debouncedSearch = useDebounce(search, 200);

  const [queryReference, loadQuery] = useQueryLoader<TenantsScreen_Admin_Query>(
    SearchQuery,
    null
  );

  useEffect(() => {
    history.push(`${removeTrailingSlash(match.url)}?query=${debouncedSearch}&limit=${limit}`);
    loadQuery({
      query: debouncedSearch,
      skip: !debouncedSearch?.length,
      limit
    });
  }, [debouncedSearch, limit]);

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          {breadcrumb}
          <i className="fa fa-angle-right" />
          <div>Search</div>
        </div>
        <h1>Criipto Backoffice</h1>
        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={`/admin/tenants`} activeClassName="active">Tenants</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/organizations`} activeClassName="active">Organizations</NavLink>
            </li>
            <li>
              <NavLink to={`/admin/users`} activeClassName="active">Users</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-content-main max-w-[1280px] !pr-[50px]">
        <div className="grid grid-cols-[90%_5%] gap-[10px] items-start w-full">
          <InputField
            type="search"
            name="tenant-search"
            label="Search for a tenant"
            value={search}
            onChange={event => setSearch(event.target.value)}
            helpText="Hint: Search for tenant id, entity id, name, domain or organization name"
          />
          <InputField
            type="number"
            name="tenant-search-limit"
            value={limit}
            onChange={event => setLimit(parseInt(event.target.value, 10))}
            className="!h-[56px]"
          />
        </div>

        <div className="mt-[10px]">
          {queryReference ? (
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <Results queryReference={queryReference} />
              </React.Suspense>
            </GraphQLErrorBoundary>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

function Results(props: {queryReference: PreloadedQuery<TenantsScreen_Admin_Query>}) {
  const data = usePreloadedQuery<TenantsScreen_Admin_Query>(SearchQuery, props.queryReference);
  return (
    <div>
      {data.admin?.tenants && (
        <React.Fragment>
          {data.admin?.tenants.map(tenant => (
            <TenantItem key={tenant.id} adminTenant={tenant} />
          ))}
        </React.Fragment>
      )}
    </div>
  );
}

export function TenantItem(props: {adminTenant: TenantsScreen_AdminTenantItem_tenant$key}) {
  const adminTenant = useFragment(
    graphql`
      fragment TenantsScreen_AdminTenantItem_tenant on AdminTenant {
        tenant {
          name
          shortTenantId
          longTenantId
          entityIdentifier

          organization {
            id
            shortId
            name
          }
        }

        billable {
          Verify {
            actual
          }
          Signatures {
            actual
          }
          Ageverification {
            dk {
              actual
            }
          }
        }
      }
    `,
    props.adminTenant
  )

  const match = useRouteMatch();
  const tenant = adminTenant.tenant;


  return (
    <Link className="block p-4 border-b border-primary-700 flex flex-row justify-between" to={`/admin/tenants/${tenant.shortTenantId}`}>
      <div>
        <strong>{adminTenant.tenant.name}</strong><br />
        <em>{adminTenant.billable.Verify.actual ? 'Verify Billable' : null}</em>&nbsp;
        <em>{adminTenant.billable.Signatures.actual ? 'Signatures Billable' : null}</em>
        <em>{adminTenant.billable.Ageverification.dk.actual ? 'Ageverification (DK) Billable' : null}</em>
      </div>
      <div className="text-right">
        {tenant.longTenantId}<br />
        {tenant.organization?.name}
      </div>
    </Link>
  );
}