import React, {useState} from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { singleton as config } from '@app/config';

import InputField from '@app/components/FormFields/InputField/InputField';
import Select from '@app/components/FormFields/Select/Select';

import './PreviewsScreen.scss';
import { buildStorybookURL, StorybookIframeRenderer } from './components/StorybookRenderer';
import { PreviewsScreen_tenant$key, PreviewsScreen_tenant$data } from './__generated__/PreviewsScreen_tenant.graphql';
import { PreviewsScreenDomainQuery, ViewVersion } from './__generated__/PreviewsScreenDomainQuery.graphql';
import usePreviewStories from '../hooks/usePreviewStories';
import { getProviderLanguages } from '../constants';

interface Props {
  styling: {
    cssUrl: string
    viewVersion: ViewVersion
  },
  tenant: PreviewsScreen_tenant$key
}

type StringPOJO = {[key: string]: string};
type Domain = PreviewsScreen_tenant$data["domains"]["edges"][0]["node"];
type Application = NonNullable<PreviewsScreenDomainQuery["response"]["domain"]>["applications"]["edges"][0]["node"];

export default function PreviewsScreen(props : Props) {
  const [width, setWidth] = useState(375);
  const [height, setHeight] = useState(667);

  const [simulatedDomain, setSimulatedDomain] = useState<null | Domain>(null);
  const [simulatedApplication, setSimulatedApplication] = useState<null | Application>(null);

  const [stories] = usePreviewStories();

  const tenant = useFragment(
    graphql`
      fragment PreviewsScreen_tenant on Tenant @argumentDefinitions(
        environment: {type: "Environment"}
      ) {
        domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
          edges {
            node {
              id
              name
            }
          }
        }

        ui {
          verify {
            draft {
              html {
                etag
                contents
              }
              scriptUrl
              cssUrl
            }
          }
        }
      }
    `,
    props.tenant
  );

  const domainQuery = useLazyLoadQuery<PreviewsScreenDomainQuery>(
    graphql`
      query PreviewsScreenDomainQuery($id: ID!, $skip: Boolean!) {
        domain(id: $id) @skip(if: $skip) {
          applications {
            edges {
              node {
                id
                realm
                name

                __typename
                ... on VerifyApplication {
                  ui {
                    cssClass
                    stylesheet
                    viewVersion
                    javascript
                  }
                }
              }
            }
          }
        }
      }
    `, {
      id: simulatedDomain?.id ?? '',
      skip: !simulatedDomain?.id
    }
  );

  const domains = tenant.domains;

  const [languages, setLanguages] = useState<StringPOJO>({});

  const domain = `samples.${config.easyIdDns}`;

  let bodyCssClass = simulatedDomain ? `host-${simulatedDomain.name.replace(/(\.)/g, '-')}` : undefined;
  bodyCssClass = simulatedApplication && simulatedApplication.ui?.cssClass ? `${bodyCssClass || ''} ${simulatedApplication.ui.cssClass}` : bodyCssClass;

  const styling : Props["styling"] = {
    ...props.styling,
    cssUrl: simulatedApplication?.ui?.stylesheet ?? props.styling.cssUrl,
    viewVersion: simulatedApplication?.ui?.viewVersion ?? props.styling.viewVersion,
  };

  return (
    <div className="styling-preview-container">
      <h2 className="styling-preview">Previews</h2>
      <div className="settings">
        <div className="dimensions">
          {/* <button type="button" className="btn" ng-click="vm.preview.refresh()">Refresh preview</button> */}
          <InputField type="text" name="width" value={width} onChange={(event) => setWidth(parseInt(event.target.value, 10))}/>
          &nbsp;x&nbsp;
          <InputField type="text" name="height" value={height} onChange={(event) => setHeight(parseInt(event.target.value, 10))}/>
        </div>
          <Select
            name="simulate_domain"
            value={simulatedDomain?.name}
            onChange={(event) => setSimulatedDomain(domains.edges.find(edge => edge.node.name === event.target.value)?.node || null)}
          >
            <option>Simulate domain</option>
            {domains.edges.map(edge => (
              <option value={edge.node.name} key={edge.node.name}>{edge.node.name}</option>
            ))}
          </Select>

        {domainQuery?.domain?.applications?.edges.length ? (
          <Select
            name="simulate_application"
            value={simulatedApplication?.id}
            onChange={(event) => setSimulatedApplication(domainQuery.domain!.applications.edges.find(edge => edge.node.id === event.target.value)?.node || null)}
          >
            <option>Simulate application</option>
            {domainQuery?.domain?.applications?.edges.map(edge => (
              <option value={edge.node.id} key={edge.node.id}>{edge.node.name}</option>
            ))}
          </Select>
        ) : null}
      </div>

      {stories.length ? (
        <React.Fragment>
          {['SwedishBankID', 'DanishMitID', 'FinnishTelia', 'AuthMethodSelector'].map(provider => (
            <div className="styling-preview-collection" key={provider}>
              <div className="styling-preview-collection-header">
                <h3 className="styling-preview">{provider}</h3>
                <Select
                  name="set_language"
                  onChange={(event) => setLanguages(languages => ({...languages, [provider]: event.target.value}))}
                >
                  {getProviderLanguages(provider as any).map(language => (
                    <option value={language} key={language}>{language}</option>
                  ))}
                </Select>
                <div className="clearfix"></div>
              </div>
              <div className="application-body styling-preview-collection-items">
                {stories.filter(s => s.screen?.startsWith(provider)).map(story => (
                  <div className="styling-preview-item" key={story.id} style={{width: `${width}px`}}>
                  <div className="header">
                    <h4 className="styling-preview">{story.name}</h4>
                    <a
                      href={
                        buildStorybookURL({
                          id: story.id,
                          defaultArgs: {
                            language: languages[provider] ?? getProviderLanguages(provider as any)[0] ?? 'en',
                            ['css-class']: bodyCssClass ?? null,
                            domain,
                            stylesheet: styling.cssUrl?.length ? styling.cssUrl : null,
                            customUIScript: tenant.ui.verify.draft?.scriptUrl ?? null,
                            customUIStylesheet: tenant.ui.verify.draft?.cssUrl ?? null,
                            action: 'login'
                          },
                          storyArgs: {
                            sideBySideEnabled: false
                          }
                        }).href
                      }
                      target="_blank"
                      title="View in fullscreen"
                      className="expand"
                    >
                      <i className="fa fa-expand" />
                    </a>
                  </div>
                  <div style={{width: `${width}px`, height: `${height}px`}} className="styling-preview-iframe-wrapper">
                    <StorybookIframeRenderer
                      id={story.id}
                      style={{width: `${width}px`, height: `${height}px`}}
                      defaultArgs={{
                        language: languages[provider] ?? (provider === 'SwedishBankID' ? 'sv' : 'da'),
                        ['css-class']: bodyCssClass ?? null,
                        domain,
                        stylesheet: styling.cssUrl?.length ? styling.cssUrl : null,
                        customUIScript: tenant.ui.verify.draft?.scriptUrl ?? null,
                        customUIStylesheet: tenant.ui.verify.draft?.cssUrl ?? null,
                        action: 'login'
                      }}
                      storyArgs={{
                        sideBySideEnabled: false,
                        allowAutoLaunch: false
                      }}
                    />
                  </div>
                </div>
                ))}
                <div className="clearfix"></div>
              </div>
            </div>
          ))}
        </React.Fragment>
      ) : null}
    </div>
  );
}