/**
 * @generated SignedSource<<f6261d378f3ae92466ec90b7f44629cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "DKK" | "EUR" | "%future added value";
export type Interval = "MONTH" | "YEAR" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type PlanSelection_Query$variables = {
  currency: Currency;
  interval: Interval;
};
export type PlanSelection_Query$data = {
  readonly plans: ReadonlyArray<{
    readonly currency: Currency;
    readonly id: string;
    readonly interval: Interval;
    readonly legacyId: string;
    readonly name: string;
    readonly product: Product;
    readonly subscriptionPrice: number;
    readonly unitPrice: number;
    readonly volume: number;
  }>;
};
export type PlanSelection_Query = {
  response: PlanSelection_Query$data;
  variables: PlanSelection_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "interval",
        "variableName": "interval"
      }
    ],
    "concreteType": "Plan",
    "kind": "LinkedField",
    "name": "plans",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "legacyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subscriptionPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interval",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "product",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlanSelection_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlanSelection_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4be998878185fdae609236651afc9ce1",
    "id": null,
    "metadata": {},
    "name": "PlanSelection_Query",
    "operationKind": "query",
    "text": "query PlanSelection_Query(\n  $interval: Interval!\n  $currency: Currency!\n) {\n  plans(interval: $interval, currency: $currency) {\n    id\n    legacyId\n    name\n    volume\n    subscriptionPrice\n    unitPrice\n    currency\n    interval\n    product\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd50f2277c0e50c739b0deece38c7da9";

export default node;
