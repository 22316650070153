import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useTenantId } from '@app/hooks/useTenant';
import useEnvironment from '@app/hooks/useEnvironment';
import { TenantiDINScreen_Query } from './__generated__/TenantiDINScreen_Query.graphql';
import useMutation from '@app/hooks/useMutation';
import { TenantiDINScreen_Mutation, UpdateDutchiDINBluemInput } from './__generated__/TenantiDINScreen_Mutation.graphql';
import { Form, FormError, FormSuccess, InputField, Select } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';

type Values = Omit<UpdateDutchiDINBluemInput, 'tenantId' | 'environment'>

export default function TenantiDINScreen() {
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<TenantiDINScreen_Query>(
    graphql`
      query TenantiDINScreen_Query($id: ID!, $environment: Environment!) {
        admin {
          tenant(id: $id) @required(action: THROW) {
            id
            dutchiDIN(environment: $environment) {
              senderId
              brandId
              defaultProfile
            }

            tenant {
              domains(environment: $environment) {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: tenantId.relayId,
      environment
    }
  );

  const [executor, status] = useMutation<TenantiDINScreen_Mutation>(
    graphql`
      mutation TenantiDINScreen_Mutation($input: UpdateDutchiDINBluemInput!) {
        updateDutchiDINBluem(input: $input) {
          senderId
          brandId
          defaultProfile
        }
      }
    `
  );

  const handleSubmit = async (values: Values) => {
    await executor.executePromise({
      input: {
        tenantId: data.admin.tenant.id,
        environment,
        ...values
      }
    });
  }

  const initialValues : Values = {
    senderId: data.admin.tenant.dutchiDIN.senderId ?? '',
    brandId: data.admin.tenant.dutchiDIN.brandId ?? '',
    defaultProfile: data.admin.tenant.dutchiDIN.defaultProfile ?? '',
  }

  return (
    <div className="max-w-[750px]">
      <h2 className="mb-2 font-bold text-[18px]">iDIN (Bluem)</h2>
      <Form
        key={`tenant_idin_${environment}`}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({isPending, error, isSuccess, values, setFieldValue}) => (
          <React.Fragment>
            <InputField type="text" label="Sender ID" name="senderId" />
            <InputField type="text" label="Brand ID" name="brandId" />
            <Select
              name="defaultProfile"
              label="Default profile"
              options={[
                {label: "safe-login", value: "safe-login"},
                {label: "full-id", value: "full-id"},
                {label: "re-id", value: "re-id"},
                {label: "age-verification", value: "age-verification"}
              ]}
              required
            />

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'iDIN settings updated'} />
            )}
            <div className="mt-[15px]">
              <Button variant="primary" type="submit" working={isPending}>Update iDIN settings</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}