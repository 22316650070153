import React from 'react';

import './ChartLegend.scss';

interface ChartLegendProps {
  label: string
  color: string
  onToggle: () => void
  selected: boolean
}

export default function ChartLegend(props: ChartLegendProps) {
  return (
    <div className="chart-legend">
      <div className="chart-legend-inner" style={{borderRight: `10px solid ${props.color}`}} onClick={() => props.onToggle()}>
        <div className="chart-legend-checkbox" style={{border: `1px solid ${props.color}`, backgroundColor: props.selected ? props.color : '#FFF'}}>
          {props.selected && <span>&#10003;</span>}
        </div>
        <div className="chart-legend-label">
          {props.label}
        </div>
      </div>
    </div>
  );
}