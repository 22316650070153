import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import CopyValueButton from '@app/components/CopyValueButton';
import InputField from '@app/components/FormFields/InputField/InputField';
import Modal, { ModalProps } from '@app/components/Modal';
import { ApiKeyModal_apiKey$key } from './__generated__/ApiKeyModal_apiKey.graphql';

interface Props {
  apiKey: ApiKeyModal_apiKey$key | null;
  onHide: ModalProps['onHide'];
  open: ModalProps['open'];
  'data-test-id'?: string
}

export default function ApiKeyModal(props: Props) {
  const apiKey = useFragment(
    graphql`
      fragment ApiKeyModal_apiKey on ApplicationApiKey {
        clientId
        clientSecret
      }
    `,
    props.apiKey
  );

  if (!props.apiKey) return null;
  if (!apiKey) return null;

  return (
    <Modal open={props.open} onHide={props.onHide} data-test-id={props['data-test-id']}>
      <Modal.Header>
          <h2>Signatures API ClientId and ClientSecret</h2>
          <p className='!my-0'>
            This is the only time we will show you your client secret.
            Please store the credentials in a secure location.
            You will need them when integrating your application.
          </p>
      </Modal.Header>
      <Modal.Content className="flex flex-col gap-[10px]">
        <InputField
          name="client_id"
          label="Client ID"
          type="text"
          value={apiKey.clientId}
          readOnly
          button={<CopyValueButton variant="primary" value={apiKey.clientId} />}
        />
        <InputField
          name="client_secret"
          label="Client Secret"
          type="text"
          value={apiKey.clientSecret!}
          readOnly
          button={<CopyValueButton variant="primary" value={apiKey.clientSecret!} />}
        />
      </Modal.Content>
    </Modal>
  );
}
