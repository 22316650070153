import React, { useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro';

import useMutation from '@app/hooks/useMutation';
import useQuery from '@app/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import '../LoginScreen/LoginScreen.scss';
import { InvitationAcceptScreen_Mutation } from './__generated__/InvitationAcceptScreen_Mutation.graphql';
import Alert from '@app/components/Alert/Alert';

export default function InvitationAcceptScreen() {
  const query = useQuery();
  const href = query.get('href');
  const id = query.get('id');
  const code = query.get('code');
  const history = useHistory();

  const [executor, status] = useMutation<InvitationAcceptScreen_Mutation>(
    graphql`
      mutation InvitationAcceptScreen_Mutation($input: AcceptInvitationInput!) {
        acceptInvitation(input: $input) {
          tenant {
            id
            shortTenantId
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!id) return;
    if (!code) return;

    executor.executePromise({
      input: {
        id,
        code
      }
    }).then(result => {
      history.push(`/refreshlogin/${result.acceptInvitation.tenant.shortTenantId}`);
    });
  }, [id, code]);

  const validLink = href || (id && code);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          {!validLink ? (
            <Alert variant="error" className="mt-[15px]" title="You have opened an invalid invitation link" message="Please ask to be invited again." />
          ) : status.error ? (
            <Alert variant="error" className="mt-[15px]" title="An error occurred" message={status.error.message} />
          ) : (
            <h4>Joining organization ...</h4>
          )}
        </div>
      </div>
    </div>
  )
}