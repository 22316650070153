import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import './Panel.scss';

import {RootState} from '@app/redux/store';
import useEnvironment from '@app/hooks/useEnvironment';
import { Link } from 'react-router-dom';

interface PanelProps {
  title: React.ReactNode
  className?: string,
  children: React.ReactNode,
  center?: boolean,
  image?: string,
  to?: string,
  'data-test-id'?: string
}

export default function Panel(props : PanelProps) {
  const environment = useEnvironment();
  const hasList = React.Children.count(props.children) === 1 &&
                  typeof props.children !== "string" &&
                  (React.Children.only(props.children) as any).type === PanelList;
  const className = cx('panel', props.className, hasList && 'panel-with-list', props.center && 'center');
  const content = (
    <React.Fragment>
      {props.image && (
        <div className="image">
          <img src={props.image} />
        </div>
      )}
      <h3>{props.title}</h3>
      <div className="panel-content">{props.children}</div>
    </React.Fragment>
  );

  if (props.to) {
    return (
      <Link to={props.to} className={className} data-test-id={props['data-test-id']}>{content}</Link>
    )
  }

  return (
    <div className={className} data-test-id={props['data-test-id']}>{content}</div>
  )
}

interface PanelListProps {
  children: React.ReactNode
}
export function PanelList(props : PanelListProps) {

  return (
    <ul>
      {React.Children.map(props.children, item => item ? (
        <li>
          <div>
            {item}
          </div>
        </li>
      ) : null)}
    </ul>
  )
}