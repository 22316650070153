import React, { useEffect, useState } from 'react';
import { PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { AddOrganizationTenantModal_tenant$key } from './__generated__/AddOrganizationTenantModal_tenant.graphql';
import { AddOrganizationTenantModal_organization$key } from './__generated__/AddOrganizationTenantModal_organization.graphql';
import Modal, { ModalProps } from '@app/components/Modal/Modal';
import Button from '@app/components/Button/Button';
import InputField from '@app/components/FormFields/InputField/InputField';
import RadioButton from '@app/components/FormFields/RadioButton/RadioButton';
import useMutation from '@app/hooks/useMutation';
import { AddOrganizationTenantModalMutation } from './__generated__/AddOrganizationTenantModalMutation.graphql';
import { AddOrganizationTenantModal_OrganizationSearchQuery } from './__generated__/AddOrganizationTenantModal_OrganizationSearchQuery.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { AddOrganizationTenantModal_TenantSearchQuery } from './__generated__/AddOrganizationTenantModal_TenantSearchQuery.graphql';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';
import { AddOrganizationTenantModal_CreateOrganizationMutation } from './__generated__/AddOrganizationTenantModal_CreateOrganizationMutation.graphql';

type Props = {
  open: ModalProps["open"]
  onHide: ModalProps["onHide"]
}

export default function AddOrganizationTenantModal(props: Props & ({
  tenant: AddOrganizationTenantModal_tenant$key
  organization: AddOrganizationTenantModal_organization$key | null
} | {
  tenant: AddOrganizationTenantModal_tenant$key | null
  organization: AddOrganizationTenantModal_organization$key
})) {
  const toast = useToast();
  const tenant = useFragment(
    graphql`
      fragment AddOrganizationTenantModal_tenant on AdminTenant {
        id
        tenant {
          name
        }
      }
    `,
    props.tenant
  );

  const organization = useFragment(
    graphql`
      fragment AddOrganizationTenantModal_organization on AdminOrganization {
        id
        organization {
          name
        }
      }
    `,
    props.organization
  );

  const [executor, status] = useMutation<AddOrganizationTenantModalMutation>(
    graphql`
      mutation AddOrganizationTenantModalMutation($input: AddOrganizationTenantInput!) {
        admin_addOrganizationTenant(input: $input) {
          adminTenant {
            organization {
              id
              organization {
                name
              }
            }
          }

          adminOrganization {
            tenants {
              id

              ... TenantsScreen_AdminTenantItem_tenant
            }
          }
        }
      }
    `
  );

  const [selectedTenant, setTenant] = useState(tenant ? {id: tenant.id, name: tenant.tenant.name} : null);
  const [selectedOrganization, setOrganization] = useState(organization ? {id: organization.id, name: organization.organization.name} : null);

  const handleSubmit = async () => {
    if (!selectedTenant || !selectedOrganization) return;
    await executor.executePromise({
      input: {
        tenantId: selectedTenant?.id,
        organizationId: selectedOrganization?.id
      }
    });
    toast.success({title: 'Tenant added'});
    if(props.onHide){
      props.onHide();
    }
  };

  if (!tenant && !organization) return null;

  return (
    <Modal {...props}>
      <Modal.Header>
        <div>
          <h2>{tenant ? `Attach tenant ${tenant.tenant.name} to an organization` : `Attach a tenant to organization ${organization!.organization.name}`}</h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        {tenant ? (
          <OrganizationSelector selected={selectedOrganization?.id ?? null} onChange={setOrganization} />
        ) : organization ? (
          <TenantSelector selected={selectedTenant?.id ?? null} onChange={setTenant} />
        ) : null}
        {status.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={status.error.message} />
        )}

        {tenant ? (
          <p className="mt-[20px] text-light-blue-800">
            Selected organization: {selectedOrganization?.name}
          </p>
        ) : organization ? (
          <p className="mt-[20px] text-light-blue-800">
            Selected tenant: {selectedTenant?.name}
          </p>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
          <Button variant="default" onClick={props.onHide}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" working={status.pending} disabled={!selectedTenant || !selectedOrganization} onClick={handleSubmit}>
            Attach
          </Button>
      </Modal.Actions>
    </Modal>
  );
}


const OrganizationSearchQuery = graphql`
  query AddOrganizationTenantModal_OrganizationSearchQuery($query: String!, $skip: Boolean!) {
    admin @skip(if: $skip) {
      organizations(query: $query) {
        id
        organization {
          name
        }
      }
    }
  }
`;

function OrganizationSelector(props: {
  selected: string | null
  onChange: (selected: {id: string, name: string}) => void
}) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  const [queryReference, loadQuery] = useQueryLoader<AddOrganizationTenantModal_OrganizationSearchQuery>(
    OrganizationSearchQuery,
    null
  );

  useEffect(() => {
    loadQuery({
      query: debouncedSearch,
      skip: !debouncedSearch?.length
    });
  }, [debouncedSearch]);

  return (
    <React.Fragment>
      <div className="mb-8">
        <InputField
          name="search-organization"
          label='Search for an organization'
          type="search"
          value={search}
          onChange={event => setSearch(event.target.value)}
          helpText="Hint: Search for organization id or name"
        />
      </div>
      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <OrganizationResults queryReference={queryReference} selected={props.selected} onChange={props.onChange} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}
    </React.Fragment>
  );
}

function OrganizationResults(props: {
  queryReference: PreloadedQuery<AddOrganizationTenantModal_OrganizationSearchQuery>,
  selected: string | null
  onChange: (selected: {id: string, name: string}) => void
}) {
  const data = usePreloadedQuery<AddOrganizationTenantModal_OrganizationSearchQuery>(OrganizationSearchQuery, props.queryReference);
  const [createOrganizationExecutor, createOrganizationStatus] = useMutation<AddOrganizationTenantModal_CreateOrganizationMutation>(
    graphql`
      mutation AddOrganizationTenantModal_CreateOrganizationMutation($input: CreateOrganizationInput!) {
        admin_createOrganization(input: $input) {
          organization {
            id
            name
          }
        }
      }
    `
  );

  const handleCreate = (event: React.MouseEvent) => {
    event.preventDefault();

    createOrganizationExecutor.executePromise({
      input: {
        name: props.queryReference.variables.query.trim()
      }
    }).then(response => {
      props.onChange(response.admin_createOrganization.organization);
    });
  }

  if (!data.admin) return null;
  if (!data.admin.organizations?.length && !props.selected) {
    return (
      <div>
        No organization results. <Button variant="default" onClick={handleCreate} working={createOrganizationStatus.pending}>Create organization</Button>

        {createOrganizationStatus.error ? (
          <Alert title="An error occurred" variant="error" message={createOrganizationStatus.error.message} />
        ) : null}
      </div>
    )
  }
  return (
    <div className="flex flex-row gap-[8px] flex-wrap">
      {data.admin.organizations?.map(organization => (
        <RadioButton
          name={organization.organization.name}
          key={organization.id}
          label={organization.organization.name}
          checked={props.selected === organization.id}
          onChange={event =>props.onChange({id: organization.id, name: organization.organization.name})}
        />
      ))}
    </div>
  );
}

const TenantSearchQuery = graphql`
  query AddOrganizationTenantModal_TenantSearchQuery($query: String!, $limit: Int! $skip: Boolean!) {
    admin @skip(if: $skip) {
      tenants(query: $query, limit: $limit) {
        id
        tenant {
          name
          shortTenantId
          longTenantId
          entityIdentifier
        }

        organization {
          id
        }
      }
    }
  }
`;

function TenantSelector(props: {
  selected: string | null
  onChange: (selected: {id: string, name: string}) => void
}) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  const [queryReference, loadQuery] = useQueryLoader<AddOrganizationTenantModal_TenantSearchQuery>(
    TenantSearchQuery,
    null
  );

  useEffect(() => {
    loadQuery({
      query: debouncedSearch,
      limit: 10,
      skip: !debouncedSearch?.length
    });
  }, [debouncedSearch]);

  return (
    <React.Fragment>
      <div className="mb-8">
        <InputField
          type="search"
          name="search-tenant"
          label="Search for a tenant"
          value={search}
          onChange={event => setSearch(event.target.value)}
          helpText="Hint: Search for tenant id, domain or name"
        />
      </div>
      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <TenantResults queryReference={queryReference} selected={props.selected} onChange={props.onChange} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}
    </React.Fragment>
  );
}

function TenantResults(props: {
  queryReference: PreloadedQuery<AddOrganizationTenantModal_TenantSearchQuery>,
  selected: string | null
  onChange: (selected: {id: string, name: string}) => void
}) {
  const data = usePreloadedQuery<AddOrganizationTenantModal_TenantSearchQuery>(TenantSearchQuery, props.queryReference);
  return (
    <div className="flex flex-row gap-[8px] flex-wrap">
      {data.admin?.tenants?.filter(t => !t.organization).map(tenant => (
        <RadioButton
          name={tenant.tenant.name}
          label={`${tenant.tenant.name} (${tenant.tenant.shortTenantId})`}
          key={tenant.id}
          checked={props.selected === tenant.id}
          onChange={event => props.onChange({id: tenant.id, name: tenant.tenant.name})}
        />
      ))}
    </div>
  );
}

function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}