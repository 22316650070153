import React, {useState} from 'react';
import { useFragment } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { IS_DEVELOPER_MODE } from "@app/constants";
import { CustomDomain_DKMitID_tenant$key } from "./__generated__/CustomDomain_DKMitID_tenant.graphql";
import { Environment } from "@app/models";
import { CustomDomain_OnboardingFeeForm_tenant$key } from './__generated__/CustomDomain_OnboardingFeeForm_tenant.graphql';
import { Form, FormError, FormSuccess, InputField } from '@app/components/Form/Form';
import BillingInformationFields from '@app/screens/BillingScreen/screens/BillingInformationScreen/components/BillingInformationFields/BillingInformationFields';
import { CustomerRendition } from '@app/api/subscription';
import Button from '@app/components/Button/Button';
import useMutation from '@app/hooks/useMutation';
import { Country, CustomDomain_OnboardingFeeForm_Mutation, OnboardingOrderKey } from './__generated__/CustomDomain_OnboardingFeeForm_Mutation.graphql';
import { CustomDomain_DKMitID_identityProvider$key } from './__generated__/CustomDomain_DKMitID_identityProvider.graphql';
import '@app/screens/DomainsScreen/components/DomainCreater/DomainCreater.scss';
import { trimDomainInput, validateDomainPrefix } from '@app/helpers';
import { CustomDomain_CompleteDomain_Mutation } from './__generated__/CustomDomain_CompleteDomain_Mutation.graphql';
import { CustomDomain_OrderDomain_Mutation } from './__generated__/CustomDomain_OrderDomain_Mutation.graphql';
import { getRelayDomainConnections } from '@app/screens/DomainsScreen/utils';

type Props = {
  tenant: CustomDomain_DKMitID_tenant$key
  identityProvider: CustomDomain_DKMitID_identityProvider$key
  environment: Environment,
  className?: string
}
export default function CustomDomain(props: Props) {
  const tenant = useFragment(
    graphql`
      fragment CustomDomain_DKMitID_tenant on Tenant {
        id
        features {
          DKMITID_CUSTOM_DOMAIN_order_form
        }

        billing {
          information {
            country
          }
          paymentMethod {
            brand
          }
        }

        viewerPermissions {
          providers(environment: PRODUCTION)
          domains(environment: PRODUCTION)
          billing
        }

        ... CustomDomain_OnboardingFeeForm_tenant
      }
    `,
    props.tenant
  );

  const identityProvider = useFragment(
    graphql`
      fragment CustomDomain_DKMitID_identityProvider on DanishMitID {
        customDomain {
          __typename
          ... on PendingDanishMitIDCustomDomain {
            domainName
            cname {
              actual
              expected
              matches
            }
          }
          ... on CompletedDanishMitIDCustomDomain {
            domainName
          }
        }
      }
    `,
    props.identityProvider
  );
  const [orderExecutor, orderStatus] = useMutation<CustomDomain_OrderDomain_Mutation>(
    graphql`
      mutation CustomDomain_OrderDomain_Mutation(
        $input: OrderDanishMitIDDomainInput!
      ) {
        orderDanishMitIDDomain(input: $input) {
          ...CustomDomain_DKMitID_identityProvider
        }
      }
    `
  )
  const [completeExecutor, completeStatus] = useMutation<CustomDomain_CompleteDomain_Mutation>(
    graphql`
      mutation CustomDomain_CompleteDomain_Mutation(
        $input: CompleteDanishMitIDDomainOrderInput!,
        $connections: [ID!]!
      ) {
        completeDanishMitIDDomainOrder(input: $input) {
          identityProvider {
            ...CustomDomain_DKMitID_identityProvider
          }

          domainEdge @appendEdge(connections: $connections) {
            cursor
            node {
              id
              name
              environment
            }
          }
        }
      }
    `
  );

  const hasAccess =
    tenant.viewerPermissions.providers === 'WRITE' &&
    tenant.viewerPermissions.domains === 'WRITE';

  if (props.environment !== 'PRODUCTION') return null;
  if (!hasAccess) return null;

  if (identityProvider.customDomain?.__typename === 'CompletedDanishMitIDCustomDomain') {
    return (
      <div className={`max-w-[750px] ${props.className ?? ''}`}>
        Your MitID custom domain, {identityProvider.customDomain.domainName} is ready to use.
      </div>
    );
  }

  if (!tenant.features.DKMITID_CUSTOM_DOMAIN_order_form) {
    return (
      <div className={`max-w-[750px] ${props.className ?? ''}`}>
        <h3>
          Order custom domain for Danish MitId
        </h3>
        <p>
          By default, your production MitID logins will run on a shared <em>criipto.mitid.dk</em> domain.<br />
          However, if you wish, we can facilitate the process with Nets/MitID to set up a custom domain on your behald, such as <em>coolenergy.mitid.dk</em>
        </p>
        <p>
          We charge an onboarding fee of DKK 1200 / € 160 to order the custom MitID domain on your behalf.
        </p>
        <OnboardingFeeForm tenant={tenant} order="DKMITID_CUSTOM_DOMAIN" />
      </div>
    )
  }

  const intro = (
    <React.Fragment>
      <h3>
        Order custom domain for Danish MitId
      </h3>
      <p>
        We will facilitate the process with Nets regarding your custom MitID domain and contact you via email when it is ready.
      </p>
    </React.Fragment>
  );

  if (identityProvider.customDomain?.__typename === 'PendingDanishMitIDCustomDomain') {

    const handleClick = () => {
      const connections = getRelayDomainConnections(tenant.id, props.environment);
      completeExecutor.execute({
        input: {
          tenantId: tenant.id,
          environment: props.environment
        },
        connections
      });
    };
    return (
      <div className={`max-w-[750px] ${props.className ?? ''}`}>
        {intro}
        <p>
          <em>{identityProvider.customDomain.domainName} {identityProvider.customDomain.cname.matches ? 'is ready to use!' : 'is not yet ready'}</em><br />
          {!identityProvider.customDomain.cname.matches ? `(Expected ${identityProvider.customDomain.domainName} to map to ${identityProvider.customDomain.cname.expected}, currently maps to ${identityProvider.customDomain.cname.actual ?? 'N/A'})` : null}
        </p>
        {completeStatus.error && (<FormError error={completeStatus.error.message} />)}
        <div className="flex flex-row justify-between items-center mt-[25px]">
          <Button
            variant="primary" working={completeStatus.pending}
            disabled={!identityProvider.customDomain.cname.matches}
            type="button"
            onClick={handleClick}
          >Start using custom domain</Button>
        </div>
      </div>
    );
  }

  const mitidSuffix = props.environment === 'PRODUCTION' ? '.mitid.dk' : '.pp.mitid.dk';
  const handleSubmit = async (values: {domainPrefix: string}) => {
    const error = validateDomainPrefix(values.domainPrefix, mitidSuffix);
    if (error) throw new Error(error);

    await orderExecutor.executePromise({
      input: {
        tenantId: tenant.id,
        environment: props.environment,
        domainName: values.domainPrefix + mitidSuffix
      }
    });
  }

  return (
    <div className={`max-w-[750px] domain-creater ${props.className ?? ''}`}>
      {intro}
      <Form
        initialValues={{domainPrefix: ''}}
        key={`${tenant.id}_mitid_custom_domain`}
        onSubmit={handleSubmit}
        className="max-w-[750px]"
      >
        {({isPending, isSuccess, error, values, setFieldValue}) => (
          <React.Fragment>
            <div className="domain-input-group">
              <input
                type="text"
                className="form-control subdomain"
                placeholder={'yourcompany'}
                value={values.domainPrefix}
                onChange={(event) => setFieldValue('domainPrefix', trimDomainInput(event.target.value))}
                disabled={isPending}
                required />
              <div className="tld">
                {mitidSuffix}
              </div>
            </div>
            {values.domainPrefix && validateDomainPrefix(values.domainPrefix, mitidSuffix) ? (
              <small className="form-text">
                <span className="text-danger">{validateDomainPrefix(values.domainPrefix, mitidSuffix)}</span>
              </small>
            ) : null}
            <FormError className="mt-[25px]" error={error} />
            <FormSuccess className="mt-[25px]" message={!isPending && isSuccess && `Custom domain ordered!` || null} />
            <div className="flex flex-row justify-between items-center mt-[25px]">
              <Button variant="primary" working={isPending} type="submit">Order custom domain</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}

type OnboardingFeeFormProps = {
  tenant: CustomDomain_OnboardingFeeForm_tenant$key,
  order: OnboardingOrderKey
}
type OnboardingFeeValues = CustomerRendition & {
  currency: 'EUR' | 'DKK',
  name?: string
};
function OnboardingFeeForm(props: OnboardingFeeFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [currency, setCurrency] = useState<OnboardingFeeValues["currency"]>('DKK');
  const tenant = useFragment(
    graphql`
      fragment CustomDomain_OnboardingFeeForm_tenant on Tenant {
        id
        billing {
          information {
            country
          }
          paymentMethod {
            brand
          }
        }

        viewerPermissions {
          billing
        }
      }
    `,
    props.tenant
  );

  const [executor, status] = useMutation<CustomDomain_OnboardingFeeForm_Mutation>(
    graphql`
      mutation CustomDomain_OnboardingFeeForm_Mutation($input: PayOnboardingFeeInput!) {
        payOnboardingFee(input: $input) {
          tenant {
            billing {
              information {
                company
                email
                country
                street
                city
                zip
                taxId
              }
              paymentMethod {
                name
                brand
                last4
              }
            }
            features {
              NOBANKID_order_form
              SEBANKID_order_form
              DKMITID_CUSTOM_DOMAIN_order_form
            }
          }

          paymentIntent {
            id
            secret
          }
        }
      }
    `
  );


  const handleSubmit = async (values: OnboardingFeeValues) => {

    const element = elements?.getElement(CardElement)!;
    const {error, token} = element ? await stripe!.createToken(element, {
      name: values.name ?? undefined,
      address_line1: values.street ?? undefined,
      address_city: values.city ?? undefined,
      address_zip: values.zip!.toString(),
      address_country: values.country ?? undefined
    }) : {error: null, token: null};

    if (error) throw new Error(error.message);

    const initialResponse = await executor.executePromise({
      input: {
        tenantId: tenant.id,
        currency,
        stripePaymentToken: token?.id ?? null,
        information: values.country ? {
          tenantId: tenant.id,
          company: values.company!,
          email: values.email!,
          country: values.country! as Country,
          street: values.street!,
          city: values.city!,
          zip: values.zip!,
          taxId: values.taxId
        } : null,
        order: props.order
      }
    });

    const paymentIntent = initialResponse.payOnboardingFee.paymentIntent;
    if (paymentIntent) {
      const confirmResult = await stripe!.confirmCardPayment(paymentIntent.secret);
      if (confirmResult.error) throw new Error(confirmResult.error.message);

      const confirmResponse = await executor.executePromise({
        input: {
          tenantId: tenant.id,
          currency,
          paymentIntentId: paymentIntent.id,
          order: props.order
        }
      });
    }
  };

  const initialValues : Partial<OnboardingFeeValues> = {
    currency: 'DKK',
  };

  if (tenant.viewerPermissions.billing !== 'WRITE') return <p>You do not have billing permissions to perform a payment.</p>

  return (
    <Form
      initialValues={initialValues}
      key={`${tenant.id}_onboarding_fee`}
      onSubmit={handleSubmit}
      className="max-w-[750px]"
    >
      {({isPending, isSuccess, error, values}) => (
        <React.Fragment>
          {!tenant.billing?.paymentMethod ? (
            <React.Fragment>
              <div className="form-group">
                <label className="control-label">Credit card</label>
                <div className="stripe-card-input">
                  <CardElement options={{hidePostalCode: true}} />
                </div>
              </div>

              <InputField<CustomerRendition>
                type="text"
                name="name"
                placeholder="Name on card"
                label="Name on card"
                required
              />
            </React.Fragment>
          ) : null}
          {!tenant.billing?.information ? <BillingInformationFields values={values} /> : null}

          <FormError error={error} />
          <FormSuccess message={!isPending && isSuccess && `Onboarding fee paid` || null} />
          <div className="flex flex-row justify-between items-center mt-[25px]">
            <div>
              <select className="form-control" value={currency} onChange={event => setCurrency(event.target.value as any)}>
                <option value="DKK">DKK</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
            <Button variant="primary" working={isPending} type="submit">Pay onboarding fee ({currency === 'DKK' ? 'DKK 1200' : '€ 160'})</Button>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
