import React from 'react';
import cx from 'classnames';
import {useSelector} from '@app/redux';
import {SlackModal} from '@components/Slack';
import styles from './Footer.module.css';
import useToast from '@app/hooks/useToast';
import Alert from '../Alert/Alert';

export function Footer(props: {className?: string}) {
  return (
    <footer id="footer" className={cx(styles['footer-wrapper'], props.className)}>
      <div className={styles.content}>
        <div className={styles.copyright}>
          © 2024 Criipto ApS | Making the digital world a safe place to roam | All rights reserved
        </div>
        <a className={styles.logo} href="https://www.criipto.com/?utm_source=dashboard" target="_blank" rel="noreferrer">
          <svg width="112" height="24" viewBox="0 0 112 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8247 23.1714C9.99795 23.1714 7.78071 22.2176 6.17195 20.309C4.56318 18.4015 3.75781 15.6311 3.75781 12C3.75781 10.1845 3.96361 8.58563 4.37718 7.20344C4.78976 5.82124 5.38735 4.65573 6.17195 3.7069C6.95555 2.75806 7.90933 2.04075 9.03428 1.55594C10.1582 1.07114 11.4217 0.828735 12.8247 0.828735C14.7016 0.828735 16.2747 1.23736 17.5441 2.0546C18.8125 2.87184 19.8078 4.08287 20.5301 5.68767L17.5906 7.29644C17.2196 6.26549 16.6467 5.44527 15.873 4.8358C15.0993 4.22732 14.0832 3.92259 12.8247 3.92259C11.1536 3.92259 9.84361 4.49248 8.89477 5.63128C7.94594 6.77008 7.47103 8.34421 7.47103 10.3527V13.6454C7.47103 15.6549 7.94495 17.228 8.89477 18.3678C9.84361 19.5066 11.1536 20.0755 12.8247 20.0755C14.1247 20.0755 15.1814 19.7401 15.9967 19.0703C16.811 18.4005 17.4145 17.5387 17.8073 16.486L20.6231 18.1877C19.9009 19.752 18.8907 20.9739 17.5906 21.8525C16.2905 22.7311 14.7025 23.1704 12.8247 23.1704V23.1714Z" fill="black"/>
            <path d="M26.0905 22.7993H22.594V1.20068H31.9082C33.8474 1.20068 35.3741 1.78344 36.4881 2.94895C37.6022 4.11446 38.1592 5.69849 38.1592 7.69906C38.1592 9.24648 37.803 10.5307 37.0917 11.5518C36.3803 12.5728 35.3325 13.2902 33.9503 13.7027L38.6233 22.8003H34.724L30.3915 14.0738H26.0905V22.8003V22.7993ZM31.6609 11.1333C32.5474 11.1333 33.2389 10.9027 33.7346 10.4407C34.2293 9.97863 34.4777 9.31573 34.4777 8.45298V6.97482C34.4777 6.11206 34.2303 5.45015 33.7346 4.9881C33.2399 4.52605 32.5483 4.29453 31.6609 4.29453H26.0905V11.1333H31.6609Z" fill="black"/>
            <path d="M58.7417 22.7993V1.20068H68.0252C70.0258 1.20068 71.5732 1.78839 72.6665 2.96478C73.7598 4.14118 74.3069 5.71927 74.3069 7.69906C74.3069 9.67885 73.7598 11.2579 72.6665 12.4333C71.5732 13.6087 70.0258 14.1974 68.0252 14.1974H62.2382V22.8003H58.7417V22.7993ZM62.2382 11.1026H67.8085C68.6951 11.1026 69.3866 10.873 69.8823 10.413C70.377 9.95291 70.6254 9.29397 70.6254 8.43517V6.96294C70.6254 6.10415 70.378 5.44521 69.8823 4.98514C69.3876 4.52507 68.696 4.29552 67.8085 4.29552H62.2382V11.1036V11.1026Z" fill="black"/>
            <path d="M84.2872 4.29455V22.7993H80.7906V4.29455H74.5356V1.19971H90.5421V4.29455H84.2872Z" fill="black"/>
            <path d="M99.1221 23.1713C97.7191 23.1713 96.4457 22.9289 95.3 22.4441C94.1553 21.9593 93.1748 21.2479 92.3605 20.309C91.5452 19.371 90.916 18.2045 90.4727 16.8125C90.0285 15.4204 89.8079 13.8166 89.8079 12.001C89.8079 10.1855 90.0295 8.58164 90.4727 7.18956C90.916 5.79747 91.5452 4.63196 92.3605 3.69302C93.1748 2.75507 94.1553 2.0427 95.3 1.5579C96.4447 1.07309 97.7181 0.830688 99.1221 0.830688C100.526 0.830688 101.798 1.07309 102.944 1.5579C104.089 2.0427 105.068 2.75507 105.884 3.69302C106.698 4.63196 107.327 5.79747 107.771 7.18956C108.215 8.58164 108.436 10.1864 108.436 12.001C108.436 13.8156 108.215 15.4204 107.771 16.8125C107.327 18.2045 106.699 19.371 105.884 20.309C105.068 21.2479 104.089 21.9593 102.944 22.4441C101.799 22.9289 100.525 23.1713 99.1221 23.1713ZM99.1221 20.0765C99.9472 20.0765 100.705 19.932 101.397 19.6421C102.087 19.3522 102.676 18.9327 103.161 18.3836C103.646 17.8345 104.022 17.1667 104.291 16.3801C104.559 15.5925 104.693 14.7031 104.693 13.7087V10.2913C104.693 9.29797 104.559 8.40751 104.291 7.62094C104.023 6.83337 103.646 6.16553 103.161 5.6174C102.676 5.06829 102.088 4.64977 101.397 4.35889C100.705 4.06899 99.9472 3.92355 99.1221 3.92355C98.2969 3.92355 97.5133 4.06899 96.8326 4.35889C96.1519 4.64878 95.5691 5.06829 95.0843 5.6174C94.5995 6.16652 94.2226 6.83436 93.9544 7.62094C93.6863 8.4085 93.5518 9.29797 93.5518 10.2913V13.7087C93.5518 14.7031 93.6853 15.5925 93.9544 16.3801C94.2226 17.1676 94.5985 17.8345 95.0843 18.3836C95.5691 18.9327 96.1519 19.3513 96.8326 19.6421C97.5133 19.932 98.2761 20.0765 99.1221 20.0765Z" fill="black"/>
            <path d="M45.8558 1.20068H42.3118V22.8003H45.8558V1.20068Z" fill="black"/>
            <path d="M54.2745 1.20068H50.7305V22.8003H54.2745V1.20068Z" fill="black"/>
            <path d="M0.0258789 9V10.9825V15H111.974V10.9825V9H0.0258789Z" fill="#604FED"/>
          </svg>
        </a>
      </div>
    </footer>
  )
}

export function FooterContainer(props: {className?: string}) {
  const toast = useToast();
  const toasts = useSelector(state => state.toast.alerts);

  return (
    <React.Fragment>
      <div className={styles['toasts-wrapper']}>
        {toasts.map(item => (
          <Alert key={item.id} {...item} onDismiss={() => toast.dismiss(item)} />
        ))}
      </div>
      <SlackModal />
      <Footer className={props.className} />
    </React.Fragment>
  )
}

export default FooterContainer;