import React, { useEffect, useState } from 'react';
import { ConnectionHandler, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import { Modal, ModalProps } from '@app/components/Modal/';
import { Application, Environment, Domain, TenantRouteParams } from '@app/models';
import { Form, FormError } from '@app/components/Form';
import InputField from '@app/components/FormFields/InputField/InputField';
import Select from '@app/components/FormFields/Select/Select';
import ApplicationNameField from '../ApplicationEditor/ApplicationNameField';
import DomainField, { RelayDomain } from '../ApplicationEditor/DomainField';
import { translate } from '@app/i18n';
import ApplicationRealmValidation from '../ApplicationEditor/ApplicationRealmValidation';
import Button from '@app/components/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { CopyApplicationModal_application$key } from './__generated__/CopyApplicationModal_application.graphql';
import { CopyApplicationModalQuery } from './__generated__/CopyApplicationModalQuery.graphql';
import { CopyApplicationModalMutation } from './__generated__/CopyApplicationModalMutation.graphql';
import useMounted from '@app/hooks/useMounted';
import useToast from '@app/hooks/useToast';

type Props = {
  application: CopyApplicationModal_application$key
  show: ModalProps["open"]
  onHide: NonNullable<ModalProps["onHide"]>
}

type Values = {
  name: string
  realm: string
}

export default function CopyApplicationModal(props: Props) {
  const toast = useToast();
  const params = useParams<TenantRouteParams>();
  const isMounted = useMounted();

  const application = useFragment(graphql`
    fragment CopyApplicationModal_application on VerifyApplication {
      id
      name
      realm
      domain {
        id
        name
        environment
      }

      ... ApplicationRealmValidation_application
    }
  `, props.application);

  const [environment, setEnvironment] = useState<Environment>(application.domain.environment === 'PRODUCTION' ? 'PRODUCTION' : 'TEST');

  const query = useLazyLoadQuery<CopyApplicationModalQuery>(graphql`
    query CopyApplicationModalQuery($id: ID!, $environment: Environment!) {
      tenant(id: $id) {
        ... DomainField_tenant @arguments(environment: $environment)

        environments(product: Verify)
      }
    }
  `, {
    id: btoa(`tenant:${params.tenantId}`),
    environment: environment
  });

  const [mutationExecutor, mutationState] = useMutation<CopyApplicationModalMutation>(graphql`
    mutation CopyApplicationModalMutation(
      $connections: [ID!]!
      $input: CopyApplicationInput!
    ) {
      copyApplication(input: $input) {
        applicationEdge @appendEdge(connections: $connections) {
          cursor
          node {
            id
            __typename
            name
            realm

            domain {
              name
            }

            ... GeneralSection_application
            ... VerifyApplicationIntegrate_application
          }
        }
        application {
          id
          domain {
            name
          }
          name
          realm

          ... GeneralSection_application
          ... VerifyApplicationIntegrate_application
        }
      }
    }
  `);

  const initialValues : Values = {
    name: application.name,
    realm: application.realm
  };

  const [domain, setDomain] = useState<RelayDomain | null>(application.domain);
  const tenant = query.tenant;
  const history = useHistory();
  const [isRealmValid, setRealmValid] = useState<null | boolean>(null);
  const isFormValid = isRealmValid;

  useEffect(() => {
    setRealmValid(null);
  }, [domain]);

  const handleSubmit = async (values: Values) => {
    if (!isFormValid) return Promise.reject(new Error('Invalid form fields'));
    if (!domain) return Promise.reject(new Error('Invalid form fields'));

    const connections = [
      ConnectionHandler.getConnectionID(
        domain.id,
        'domain_applications'
      ),
      ConnectionHandler.getConnectionID(
        domain.id,
        'domain_applications',
        null
      )
    ];

    const result = await mutationExecutor.executePromise({
      input: {
        applicationId: application.id,
        targetDomainId: domain.id,
        name: values.name,
        realm: values.realm
      },
      connections
    });

    history.push(`/tenant/${params.tenantId}/applications/${result.copyApplication.application?.id}`);
    toast.success({title: 'Application copied'});
    if (isMounted.current) props.onHide();
  }

  if (!tenant) return null;

  return (
    <Modal open={props.show} onHide={props.onHide}>
      <Form
        key={`copy_application_form_${application.domain.name}_${application.realm}`}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
          {({isPending, error, isSuccess, values, setFieldValue}) => (
            <React.Fragment>
              <Modal.Header>
                <h2>Copy application "{application.name}"</h2>
                <p>
                  {application.name} is on domain {application.domain.name} in the {application.domain.environment.toLowerCase()} environment.
                </p>
              </Modal.Header>
              <Modal.Content>
                <div className="flex flex-col gap-[16px]">

                  <div className="flex flex-col gap-[8px]">
                    {tenant.environments.length > 1 ? (
                      <Select name="environment" label='Environment' value={environment} onChange={(event) => setEnvironment(event.target.value as Environment)}>
                        <option value="TEST">Test environment</option>
                        <option value="PRODUCTION">Production environment</option>
                      </Select>
                    ) : null}

                    <ApplicationNameField />

                    <DomainField
                      tenant={tenant}
                      domain={domain}
                      onChange={setDomain}
                     />

                    <InputField<Application>
                      type="text"
                      label={translate('LABEL_CLIENT_ID')}
                      name="realm"
                      required
                      placeholder={translate('LABEL_CLIENT_ID')}
                      iconRight={domain && (
                        <React.Suspense fallback={<i className="fa fa-spinner fa-pulse" />}>
                          <ApplicationRealmValidation domainId={domain.id} realm={values.realm} onValid={setRealmValid} />
                        </React.Suspense>
                      )}
                      error={!isRealmValid && domain &&
                        <React.Fragment>
                          {translate('REALM_EXISTS')}
                        </React.Fragment>
                      }
                    />
                  </div>
                  <p>Note: OAuth2 code flow secret will not be transferred and you must generate a new one on the created application.</p>
                  {error && <FormError error={error} />}
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button variant="default" onClick={props.onHide} size="md">
                  Cancel
                </Button>
                <Button type="submit" variant="primary" working={isPending} disabled={!isFormValid} size="md">
                  Copy application
                </Button>
              </Modal.Actions>
            </React.Fragment>
          )}
        </Form>
    </Modal>
  )
}