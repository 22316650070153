import React from "react";
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from "react-relay";
import { TenantItem } from "../TenantsScreen/TenantsScreen";
import { OrganizationTenantsScreenQuery } from "./__generated__/OrganizationTenantsScreenQuery.graphql";
import RemoveOrganizationTenantButton from "../../components/RemoveOrganizationTenantButton";
import AddOrganizationTenantButton from "../../components/AddOrganizationTenantButton";

export default function OrganizationTenantsScreen(props: {
  organizationId: string
}) {
  const data = useLazyLoadQuery<OrganizationTenantsScreenQuery>(
    graphql`
      query OrganizationTenantsScreenQuery($id: ID!) {
        admin {
          organization(id: $id) {
            tenants {
              id
              ... TenantsScreen_AdminTenantItem_tenant
              ... RemoveOrganizationTenantButton_tenant
            }
            ... AddOrganizationTenantButton_organization
          }
          ...AddOrganizationTenantButton_admin
        }
      }
    `,
    {
      id: props.organizationId
    }
  );

  if (!data.admin.organization) return null;

  return (
    <div className="max-w-[1250px]">
      {data.admin?.organization?.tenants.map(tenant => (
        <div key={tenant.id} className="flex flex-row items-center">
          <div className="grow">
            <TenantItem key={tenant.id} adminTenant={tenant} />
          </div>
          <RemoveOrganizationTenantButton tenant={tenant} />
        </div>
      ))}
      <div className="mt-[25px]">
        <AddOrganizationTenantButton admin={data.admin} organization={data.admin.organization} tenant={null} />
      </div>
    </div>
  )
}