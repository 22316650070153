import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import useEnvironment from '@app/hooks/useEnvironment';
import { useTenantId } from '@app/hooks/useTenant';
import { TenantMitIDScreen_Query } from './__generated__/TenantMitIDScreen_Query.graphql';
import { Form, FormError, FormSuccess, InputField, Select } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';
import { countries } from '@app/screens/BillingScreen/countries';
import useMutation from '@app/hooks/useMutation';
import { TenantMitIDScreen_Mutation } from './__generated__/TenantMitIDScreen_Mutation.graphql';
import { TenantMitIDScreen_KYC_tenant$key } from './__generated__/TenantMitIDScreen_KYC_tenant.graphql';
import { TenantMitIDScreen_ServiceProvider_tenant$key } from './__generated__/TenantMitIDScreen_ServiceProvider_tenant.graphql';
import { TenantMitIDScreen_ServiceProvider_Mutation } from './__generated__/TenantMitIDScreen_ServiceProvider_Mutation.graphql';

type Values = {
  companyName: string
  vatCountry: string
  vatNumber: string
}

export default function TenantMitIDScreen() {
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<TenantMitIDScreen_Query>(
    graphql`
      query TenantMitIDScreen_Query($id: ID!, $environment: Environment!) {
        admin {
          tenant(id: $id) @required(action: THROW) {
            id
            ...TenantMitIDScreen_KYC_tenant
            ...TenantMitIDScreen_ServiceProvider_tenant @arguments(environment: $environment)
          }
        }
      }
    `,
    {
      id: tenantId.relayId,
      environment
    }
  );


  return (
    <React.Fragment>
      <div className="mb-16">
        <ServiceProviderComponent tenant={data.admin.tenant} />
      </div>
      <KYCComponent tenant={data.admin.tenant} />
    </React.Fragment>
  )
}

function ServiceProviderComponent(props: {
  tenant: TenantMitIDScreen_ServiceProvider_tenant$key
}) {
  const environment = useEnvironment();
  const tenant = useFragment(
    graphql`
      fragment TenantMitIDScreen_ServiceProvider_tenant on AdminTenant @argumentDefinitions(
        environment: {type: "Environment!"}
      ) {
        id

        danishMitIdServiceProvider(environment: $environment) {
          vatCountry
          vatNumber
          serviceProviderId
          companyName
          companyType
          alias
          state
        }
      }
    `,
    props.tenant
  );

  const [executor, status] = useMutation<TenantMitIDScreen_ServiceProvider_Mutation>(
    graphql`
      mutation TenantMitIDScreen_ServiceProvider_Mutation($input: UpdateTenantDanishMitIdServiceProviderInput!, $environment: Environment!) {
        admin_updateTenantDanishMitIdServiceProvider(input: $input) {
          adminTenant {
            danishMitIdServiceProvider(environment: $environment) {
              vatCountry
              vatNumber
            }
          }
        }
      }
    `
  );

  const initialValues = {
    vatCountryCode: tenant.danishMitIdServiceProvider?.vatCountry ?? '',
    vatNumber: tenant.danishMitIdServiceProvider?.vatNumber ?? ''
  };

  const handleSubmit = async (values: {vatCountryCode: string, vatNumber: string, companyType: string}) => {
    await executor.executePromise({
      environment,
      input: {
        tenantId: tenant.id,
        environment,
        vatCountry: values.vatCountryCode,
        vatNumber: values.vatNumber
      }
    });
  }

  return (
    <div className="max-w-[750px]">
      <h2 className="mb-2 font-bold text-[18px]">Service Provider</h2>
      <Form
        key="tenant_kyc"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className="flex flex-col gap-[16px]"
      >
        {({isPending, error, isSuccess, values, setFieldValue}) => (
          <React.Fragment>
            <div className="form-group">
              <label className="control-label">Company type</label>
              <select value={tenant.danishMitIdServiceProvider?.companyType} disabled>
                <option value="private">private</option>
                <option value="public">public</option>
              </select>
            </div>
            <div className="form-group !mt-0">
              <label className="control-label">VAT</label>
              <div className="vat-identifier">
                <input type="text" className="form-control" value={values.vatCountryCode} onChange={(event) => setFieldValue('vatCountryCode', event.target.value.toUpperCase())} placeholder="Country code" minLength={2} maxLength={2} required />
                <input type="text" className="form-control" value={values.vatNumber} onChange={(event) => setFieldValue('vatNumber', event.target.value)} placeholder="VAT Number" required />
              </div>
            </div>

            <div className="form-group !mt-0">
              <label className="control-label">Service provider ID</label>
              <input type="text" className="form-control" value={tenant.danishMitIdServiceProvider?.serviceProviderId} disabled />
            </div>

            <div className="form-group !mt-0">
              <label className="control-label">Company name</label>
              <input type="text" className="form-control" value={tenant.danishMitIdServiceProvider?.companyName} disabled />
            </div>

            <div className="form-group !mt-0">
              <label className="control-label">Alias</label>
              <input type="text" className="form-control" value={tenant.danishMitIdServiceProvider?.alias} disabled />
            </div>

            <div className="form-group !mt-0">
              <label className="control-label">State</label>
              <input type="text" className="form-control" value={tenant.danishMitIdServiceProvider?.state} disabled />
            </div>

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'Service provider updated'} />
            )}
            <div>
              <Button variant="primary" type="submit" working={isPending}>Update service provider</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}

function KYCComponent(props: {
  tenant: TenantMitIDScreen_KYC_tenant$key
}) {
  const tenant = useFragment(
    graphql`
      fragment TenantMitIDScreen_KYC_tenant on AdminTenant {
        id
        kyc {
          companyName
          vatCountry
          vatNumber
        }
      }
    `,
    props.tenant
  );

  const [executor, status] = useMutation<TenantMitIDScreen_Mutation>(
    graphql`
      mutation TenantMitIDScreen_Mutation($input: UpdateTenantKYCInput!) {
        admin_updateTenantKYC(input: $input) {
          adminTenant {
            kyc {
              companyName
              vatCountry
              vatNumber
            }
          }
        }
      }
    `
  );

  const initialValues : Values = {
    companyName: tenant.kyc?.companyName ?? '',
    vatCountry: tenant.kyc?.vatCountry ?? '',
    vatNumber: tenant.kyc?.vatNumber ?? ''
  };
  const handleSubmit = async (values: Values) => {
    await executor.executePromise({
      input: {
        tenantId: tenant.id,
        companyName: values.companyName,
        vatCountry: values.vatCountry,
        vatNumber: values.vatNumber,
      }
    });
  }

  return (
    <div className="max-w-[750px]">
      <h2 className="mb-2 font-bold text-[18px]">KYC</h2>
      <Form
        key="tenant_kyc"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className="flex flex-col gap-[16px]"
      >
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <InputField  type="text" label="Company name" name="companyName" required />

            <div className="flex flex-row gap-[16px]">
              <Select
                name="vatCountry"
                label="VAT Country"
                groupClassName="flex-1 basis-1/6"
                options={[
                  {value: '', label: 'Select country'}
                ].concat(Object.entries(countries).map(([key, value]) => ({
                  value: key,
                  label: value
                })))}
                required
              />

              <InputField groupClassName="grow !mt-0" type="text" label="VAT number" name="vatNumber" required />
            </div>

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'KYC details updated'} />
            )}
            <div>
              <Button variant="primary" type="submit" working={isPending}>Update KYC details</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}