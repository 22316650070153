import React, {useState} from 'react';
import _ from 'lodash';

import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import { Application, isClientSecretTechnology, technologyTags, TechnologyGroupTag, technologyToGroup, isClientSecretTechnologyGroup, technologyGroupTags } from '@app/models';
import {Form, FormError, FormSuccess, Switch} from '@app/components/Form';
import InputField from '@app/components/FormFields/InputField/InputField';
import ApplicationNameField from '../ApplicationEditor/ApplicationNameField';
import DomainField, {RelayDomain } from '../ApplicationEditor/DomainField';
import { translate } from '@app/i18n';
import ApplicationRealmValidation from '../ApplicationEditor/ApplicationRealmValidation';
import ReturnURLs from '../ApplicationEditor/ReturnURLs';
import Button from '@app/components/Button/Button';
import { useHistory } from 'react-router-dom';
import ClientSecretModal from '../ClientSecretModal/ClientSecretModal';
import TechnologySelector, { isClientSecretTechnologyChoice, isOtherTechnologyChoice, TechnologyChoice } from '../TechnologySelector/TechnologySelector';
import TechnologyGroupSelector from '../TechnologyGroupSelector/TechnologyGroupSelector';
import { ConnectionHandler, useFragment } from 'react-relay';
import { AgeverificationApplicationCreator_tenant$key } from './__generated__/AgeverificationApplicationCreator_tenant.graphql';
import { ApplicationScopeStrategy, AgeverificationApplicationCreatorMutation } from './__generated__/AgeverificationApplicationCreatorMutation.graphql';
import useEnvironment from '@app/hooks/useEnvironment';
import { useMfa } from '@app/hooks/useMfa';

interface Props {
  tenant: AgeverificationApplicationCreator_tenant$key,
  parentUrl: string
}

type Values = {
  name: string
  realm: string
  returnUrls: string[]
  scopeStrategy: ApplicationScopeStrategy
}

export default function AgeverificationApplicationCreator(props: Props)  {
  const environment = useEnvironment();
  const tenant = useFragment(
    graphql`
      fragment AgeverificationApplicationCreator_tenant on Tenant @argumentDefinitions(
        environment: {type: "Environment"}
      ) {
        ... DomainField_tenant @arguments(environment: $environment)
      }
    `,
    props.tenant
  );

  const [mutationExecutor, mutationState] = useMutation<AgeverificationApplicationCreatorMutation>(graphql`
    mutation AgeverificationApplicationCreatorMutation(
      $connections: [ID!]!
      $input: CreateApplicationInput!
      $environment: Environment!
    ) {
      createApplication(input: $input) {
        ... on CreateVerifyApplicationOutput {
          applicationEdge @appendEdge(connections: $connections) {
            cursor
            node {
              id
              __typename
              name
              realm

              domain {
                name
              }

              ... GeneralSection_application
              ... VerifyApplicationIntegrate_application
            }
          }
          application {
            id
            domain {
              name
            }
            name
            realm

            ... GeneralSection_application
            ... VerifyApplicationIntegrate_application
          }
          clientSecret

          tenant {
            id
            onboarding {
              hasApplication(environment: $environment, product: Ageverification_DK)
            }
            extensionsEnabled(environment: $environment)
            extensions(environment: $environment) {
              installed {
                id
                name
              }
              available {
                id
                name
              }
            }
          }
        }
      }
    }
  `);

  const checkMfa = useMfa(environment === 'PRODUCTION');
  const [isRealmValid, setRealmValid] = useState(true);
  const history = useHistory();
  const [domain, setDomain] = useState<RelayDomain | undefined>(undefined);
  const [application, setApplication] = useState<{domain: {name: string}, realm: string, name: string, id: string} | null>(null);
  const [technologyGroup, setTechnologyGroup] = useState<TechnologyGroupTag | null>(null);
  const [technology, setTechnology] = useState<TechnologyChoice | null>(null);

  const [showClientSecret, setShowClientSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const initialValues : Values = {
    name: 'Age verification',
    realm: `urn:my:application:identifier:${_.random(1000, 999999)}`,
    returnUrls: [],
    scopeStrategy: 'DYNAMIC'
  };

  const handleRedirect = (application: {domain: {name: string}, realm: string, id: string}) => {
    if (technology && !isOtherTechnologyChoice(technology)) {
      history.push(props.parentUrl + `/${application.id}/integrate`);
      return;
    }
    history.push(props.parentUrl + `/${application.id}`);
  }

  const isFormValid = isRealmValid && domain;
  const handleSubmit = async (values : Values) => {
    if (!isFormValid) throw new Error('Invalid form fields');
    const connections = [
      ConnectionHandler.getConnectionID(
        domain.id,
        'domain_applications'
      ),
      ConnectionHandler.getConnectionID(
        domain.id,
        'domain_applications',
        null
      )
    ];

    const oauth2ClientSecretEnabled = isClientSecretTechnologyChoice(
      technology ?? undefined,
      technologyGroup ?? undefined
    );

    await checkMfa();
    const result = await mutationExecutor.executePromise({
      input: {
        domainId: domain.id,
        name: values.name,
        realm: values.realm,
        callbackUrls: values.returnUrls,
        oauth2ClientSecretEnabled,
        product: 'Ageverification_DK',
        technology,
        technologyGroup,
        scopeStrategy: values.scopeStrategy
      },
      connections,
      environment
    });

    if (result.createApplication.clientSecret) {
      setApplication(result.createApplication.application!);
      setClientSecret(result.createApplication.clientSecret);
      setShowClientSecret(true);
    } else {
      // Show success message for a bit
      setTimeout(() => {
        handleRedirect(result.createApplication.application!);
      }, 1000);
    }
  }

  const handleClientSecretHide = () => {
    setClientSecret(null);
    setShowClientSecret(false);
    handleRedirect(application!);
  }
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        data-test-id="form"
        key="application_creater"
        className="max-w-[750px] flex flex-col gap-[8px] form-gap"
      >
        {({isPending, error, isSuccess, values}) => (
          <>
            <ApplicationNameField />

            <DomainField tenant={tenant} domain={domain} onChange={setDomain} />

            <div>
              <InputField<Application>
                type="text"
                label={translate('LABEL_CLIENT_ID')}
                name="realm"
                required
                placeholder={translate('LABEL_CLIENT_ID')}
                helpText={
                  <span>
                    The Client ID/Realm must be unique for each application within the selected domain.<br />
                    When integrating Criipto into your code, you will need the domain name and your client ID.
                  </span>
                }
                iconRight={!(isSuccess || isPending) && domain && (
                  <React.Suspense fallback={<i className="fa fa-spinner fa-pulse" />}>
                    <ApplicationRealmValidation domainId={domain.id} realm={values.realm} onValid={setRealmValid} />
                  </React.Suspense>
                )}
                error={!isRealmValid && domain &&
                  <>
                    {translate('REALM_EXISTS')}
                  </>
                }
              />
            </div>

            <ReturnURLs />

            <div className="form-group">
              <label className="control-label" style={{fontSize: '18px'}}><strong>Which technology are you using for your project?</strong></label>
              <p>Selecting a technology will help us guide you to the right tutorials and documentation.</p>
            </div>

            <TechnologyGroupSelector
              groups={technologyGroupTags.filter(s => s !== 'technology-group:native').slice().reverse()}
              group={technologyGroup}
              onChange={setTechnologyGroup}
            />

            {technologyGroup ? (
              <>
                <p>If your platform/technology is not listed, please provide feedback by selecting "Other" and inputting your platform. This helps inform SDK and guide development.</p>

                <TechnologySelector
                  technology={technology}
                  onChange={setTechnology}
                  technologies={technologyTags.filter(t => technologyToGroup(t) === technologyGroup)}
                />
              </>
            ) : null}

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message="Application created" />
            )}

            {technologyGroup ? (
              <div>
                <Button variant="primary" type="submit" working={isPending}>Create application</Button>
              </div>
            ) : null}
          </>
        )}
      </Form>
      {application && (<ClientSecretModal open={showClientSecret} onHide={handleClientSecretHide} application={application} clientSecret={clientSecret!} />)}
    </>
  )
}