import React from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { NativeSection_application$key } from './__generated__/NativeSection_application.graphql';

import { ApplicationRelayForm } from '../ApplicationForm';
import InputField from '@app/components/FormFields/InputField/InputField';
import Textarea from '@app/components/FormFields/Textarea/Textarea';
import useMutation from '@app/hooks/useMutation';
import { NativeSectionSectionMutation } from './__generated__/NativeSectionSectionMutation.graphql';

interface Props {
  application: NativeSection_application$key
}

type Values = {
  android: {
    packageName: string,
    sha256Fingerprints: string
  }
  iOS: {
    teamId: string,
    bundleId: string
  }
}

const fingerprintsPattern = /^(([a-zA-Z0-9]{2}\:){31}([a-zA-Z0-9]{2})(,|\n|\s+)?)+$/

export default function NativeSection(props: Props) {
  const application = useFragment(graphql`
    fragment NativeSection_application on VerifyApplication {
      id
      android {
        packageName
        sha256Fingerprints
      }

      iOS {
        teamId
        bundleId
      }

      domain {
        name
      }
    }
  `, props.application);

  const initialValues : Values = {
    android: {
      packageName: application.android?.packageName ?? '',
      sha256Fingerprints: application.android?.sha256Fingerprints?.join('\n') ?? ''
    },
    iOS: {
      teamId: application.iOS?.teamId ?? '',
      bundleId: application.iOS?.bundleId ?? ''
    }
  }

  const [mutationExecutor, mutationState] = useMutation<NativeSectionSectionMutation>(graphql`
    mutation NativeSectionSectionMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          ... NativeSection_application
        }
      }
    }
  `);

  return (
    <ApplicationRelayForm
      formSuccessMessage="Native/mobile settings updated"
      values={initialValues}
      valid={true}
      onSubmit={async values => {
        await mutationExecutor.executePromise({
          input: {
            applicationId: application.id,
            android: values.android.packageName?.length ? {
              packageName: values.android.packageName,
              sha256Fingerprints: values.android.sha256Fingerprints.split(/\n|,/).map(id => id.trim()).filter(id => id?.length)
            } : null,
            iOS: (values.iOS.teamId?.length && values.iOS.bundleId?.length) ? {
              teamId: values.iOS.teamId,
              bundleId: values.iOS.bundleId
            } : null
          }
        });
      }}
    >
      {({values}) => (
        <div className="flex flex-col gap-[12px]">
          <div>
            <strong>Android</strong>
            <p className="!mt-0">
              You can inspect&nbsp;
              <a href={`https://${application.domain.name}/.well-known/assetlinks.json`} target="_blank" className="!underline">
                https://{application.domain.name}/.well-known/assetlinks.json
              </a> after saving.
            </p>
            <div className='flex flex-col gap-[12px]'>
              <InputField
                type="text"
                label="App package name"
                name="android.packageName"
                placeholder="com.example"
              />
              <Textarea
                className="!font-mono"
                label="SHA256 fingerprints"
                name="android.sha256Fingerprints"
                placeholder="12:AF:B4:C3:9E:33..., E1:C2:95:C8:E1..."
                helpText={
                  <span>
                  {!values.android.sha256Fingerprints.match(fingerprintsPattern) ? (
                    <p className="text-danger" style={{marginBottom: '5px'}}>OBS! These values do not look like proper fingerprints</p>
                  ) : null}
                  The SHA256 fingerprints of your app's signing certificate.
                  You can specify multiple key hashes by comma-separating them or one by line.
                </span>
                }
              />
            </div>
          </div>
          <div>
            <strong>iOS</strong>
            <p className="!mt-0">
              You can inspect&nbsp;
              <a href={`https://${application.domain.name}/.well-known/apple-app-site-association`} target="_blank" className="!underline">
                https://{application.domain.name}/.well-known/apple-app-site-association
              </a> after saving.
            </p>
            <div className='flex flex-col gap-[12px]'>
              <InputField
                type="text"
                label="Team ID"
                name="iOS.teamId"
                placeholder="9JA8..."
              />
              <InputField
                type="text"
                label="Bundle ID"
                name="iOS.bundleId"
                placeholder="com.example.bundle"
              />
            </div>
          </div>
        </div>
      )}
    </ApplicationRelayForm>
  )
}