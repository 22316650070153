import React, { useState } from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { isSignaturesTechnology, TechnologyTag, technologyTags } from "@app/models"
import DocsEmbed from './DocsEmbed';
import ApplicationTechnologySelector from './ApplicationTechnologySelector';
import { SignaturesApplicationIntegrate_application$key } from './__generated__/SignaturesApplicationIntegrate_application.graphql';

interface Props {
  application: SignaturesApplicationIntegrate_application$key
}
export default function SignaturesApplicationIntegrate(props: Props) {
  const application = useFragment(graphql`
    fragment SignaturesApplicationIntegrate_application on Application {
      id
      technology
      technologyGroup
      ...ApplicationTechnologySelector_application
    }
  `, props.application);

  const applicationTechnology = (application.technology ? `technology:${application.technology}` : null) as (TechnologyTag | null);
  const [technology, setTechnology] = useState(applicationTechnology);
  const signaturesTechnology = technology ? isSignaturesTechnology(technology) : null;

  const generic = (
    <React.Fragment>
      <p>
        Regardless of your technology choice, Criipto Signatures is offered as a GraphQL API which has support for type-strong code-generated clients in many languages and frameworks.
      </p>
      <p>
        <a href="https://docs.criipto.com/signatures" target="_blank" className="text-primary-900 text-underline">Our documentation</a> covers a wide range of examples that you can test with your client credentials.
        The documentation also has <a href="https://docs.criipto.com/signatures/getting-started/codegen/" target="blank" className="text-primary-900 text-underline">section on GraphQL codegen.</a>
      </p>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!signaturesTechnology ? (
        <React.Fragment>
          <div className="max-w-[750px]">
            <div className="form-group mb-3">
              <label className="control-label"><strong>Which technology are you using for your project?</strong></label>
              <small className="help-text">Selecting a technology will help us guide you to the right tutorials and documentation.</small>
            </div>
            <ApplicationTechnologySelector
              application={application}
              technologies={technologyTags.filter(isSignaturesTechnology)}
              onChange={setTechnology}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <button className="text-primary-900 border-0 mb-5" onClick={() => setTechnology(null)}><i className="fa fa-angle-left"/> Change technology</button>
          {signaturesTechnology === 'technology:nodejs' ? (
            <React.Fragment>
              <DocsEmbed path="/signatures/integrations/nodejs/" />
            </React.Fragment>
          ) : signaturesTechnology === 'technology:csharp' ? (
            <React.Fragment>
              <DocsEmbed path="/signatures/integrations/csharp/" />
            </React.Fragment>
          ) : signaturesTechnology === 'technology:php' ? (
            <div className="max-w-[750px]">
              <strong>Integrate with PHP</strong>
              <p>
                A <a href="https://github.com/criipto/samples/tree/master/Signing/php" target="_blank" className="text-primary-900 text-underline">PHP example</a>&nbsp;
                using <a href="https://github.com/spawnia/sailor" target="_blank" className="text-primary-900 text-underline">Sailor</a>&nbsp;
                is available at <a href="https://github.com/criipto/samples/tree/master/Signing/php" target="_blank" className="text-primary-900 text-underline">GitHub</a>.
              </p>
              {generic}
            </div>
          ) : signaturesTechnology === 'technology:java' ? (
            <div className="max-w-[750px]">
              <strong>Integrate with Java</strong>
              <p>
                We can recommend <a href="https://netflix.github.io/dgs/" target="_blank" className="text-primary-900 text-underline">Netflix DGS</a> for building type-safe GraphQL clients.
              </p>
              {generic}
            </div>
          ) : assertUnreachableTechnology(signaturesTechnology)}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

function assertUnreachableTechnology(t: never): never {
  throw new Error(`Unexpected technology: ${t}`);
}