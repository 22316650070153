/**
 * @generated SignedSource<<cac908dc0157409379b3bdc4f53bf2d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type EnvironmentFeature = "DK_CompanySignatory" | "DK_PocesAddress" | "EXTENSIONS" | "FREE_CUSTOM_DOMAINS" | "%future added value";
export type ToggleEnvironmentFeatureInput = {
  enabled: boolean;
  environment: Environment;
  feature: EnvironmentFeature;
  note?: string | null;
  tenantId: string;
};
export type EnvironmentFeatureToggleMutation$variables = {
  input: ToggleEnvironmentFeatureInput;
};
export type EnvironmentFeatureToggleMutation$data = {
  readonly admin_toggleEnvironmentFeature: {
    readonly tenant: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EnvironmentFeatureToggle_tenant">;
    };
  };
};
export type EnvironmentFeatureToggleMutation = {
  response: EnvironmentFeatureToggleMutation$data;
  variables: EnvironmentFeatureToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "DK_PocesAddress",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "DK_CompanySignatory",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "FREE_CUSTOM_DOMAINS",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "EXTENSIONS",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnvironmentFeatureToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleEnvironmentFeatureOutput",
        "kind": "LinkedField",
        "name": "admin_toggleEnvironmentFeature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EnvironmentFeatureToggle_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnvironmentFeatureToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleEnvironmentFeatureOutput",
        "kind": "LinkedField",
        "name": "admin_toggleEnvironmentFeature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminTenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTenantFeatures",
                "kind": "LinkedField",
                "name": "features",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantEnvironmentFeatures",
                    "kind": "LinkedField",
                    "name": "test",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminTenantEnvironmentFeatures",
                    "kind": "LinkedField",
                    "name": "production",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2ebfde64d6cd7582d12a692420bf0df",
    "id": null,
    "metadata": {},
    "name": "EnvironmentFeatureToggleMutation",
    "operationKind": "mutation",
    "text": "mutation EnvironmentFeatureToggleMutation(\n  $input: ToggleEnvironmentFeatureInput!\n) {\n  admin_toggleEnvironmentFeature(input: $input) {\n    tenant {\n      id\n      ...EnvironmentFeatureToggle_tenant\n    }\n  }\n}\n\nfragment EnvironmentFeatureToggle_tenant on AdminTenant {\n  id\n  features {\n    test {\n      DK_PocesAddress\n      DK_CompanySignatory\n      FREE_CUSTOM_DOMAINS\n      EXTENSIONS\n    }\n    production {\n      DK_PocesAddress\n      DK_CompanySignatory\n      FREE_CUSTOM_DOMAINS\n      EXTENSIONS\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b63af2ec5b1242770c3699d77ed44ec2";

export default node;
