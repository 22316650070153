import React from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, FormError, FormSuccess, Switch} from '@components/Form';
import CertificateForm from '@components/CertificateForm';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { SEBankIDProviderMutation } from './__generated__/SEBankIDProviderMutation.graphql';
import { fileToBase64 } from '@app/helpers';
import { SEBankIDProvider_identityProvider$key } from './__generated__/SEBankIDProvider_identityProvider.graphql';

interface Props {
  identityProvider: SEBankIDProvider_identityProvider$key
}

export interface FormValues {
  certificate?: {
    file: File,
    password: string
  },
  disableSSNInput: boolean
}

export function SEBankIDProvider(props : Props) {
  const identityProvider = useFragment(graphql`
    fragment SEBankIDProvider_identityProvider on SwedishBankID {
      id
      tenant {
        id
      }

      longName
      disableSSNInput
      certificate {
        name
        pkcs7
        validFrom
        validTo
      }
    }
  `, props.identityProvider);

  const [{executePromise}] = useMutation<SEBankIDProviderMutation>(graphql`
    mutation SEBankIDProviderMutation(
      $input: UpdateSwedishBankIDInput!
    ) {
      updateSwedishBankID(input: $input) {
        id
        isConfigured
        ... on SwedishBankID {
          certificate {
            name
            pkcs7
            validFrom
            validTo
          }
        }
      }
    }
  `);

  const environment = useEnvironment();

  return (
    <Form
      key={environment}
      initialValues={{
        disableSSNInput: identityProvider.disableSSNInput ?? false
      }}
      onSubmit={async (values : FormValues, actions) => {
        const certificate = (values.certificate && values.certificate.file) ? await fileToBase64(values.certificate.file!) : null;

        await executePromise({
          input: {
            tenantId: identityProvider!.tenant.id,
            environment: environment,
            certificate: certificate,
            password: values.certificate?.password,
            disableSSNInput: values.disableSSNInput
          }
        });

        actions.setFieldValue('certificate.file', undefined);
        actions.setFieldValue('certificate.password', '');
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_SEBANKID_WHAT_TO_AVOID')}</em></p>
            <p className="green">❓{translate('HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_SEBANKID')}</p>
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} {identityProvider?.longName}
              </h3>
              <EnvironmentTag />
              <CertificateForm
                certificate={identityProvider?.certificate}
                fileFieldName="certificate.file"
                passwordFieldName="certificate.password"
              />
              {environment === 'TEST' ? (
                <small className="block help-text mt-2 mb-6">You can <a href="/assets/FPTestcert4_20230629.pfx" target="_blank">download a test certificate</a>, the password is qwerty123</small>
              ) : null}
              <div className="form-group switch-group">
                <Switch
                  name="disableSSNInput"
                  label="Disable SSN input"
                  help={(
                    <small className="help-text">By disabling SSN input you will automatically start using the new BankID API and will be ready for migration in May 2024.</small>
                  )}
                />
              </div>
              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your swedish bankid settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_CERTIFICATE', { provider: identityProvider!.longName })}</p>
              <p>{translate('INFO_SE_BANKID')}</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}