import React, { useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {translate} from '@app/i18n';

interface Props {
  domain: string
  tenantId: string
}

import './TokenSigningKeys.scss';
import { TokenSigningKeysQuery } from './__generated__/TokenSigningKeysQuery.graphql';
import useMutation from '@app/hooks/useMutation';
import { TokenSigningKeysPrepareMutation } from './__generated__/TokenSigningKeysPrepareMutation.graphql';
import { TokenSigningKeysActivateMutation } from './__generated__/TokenSigningKeysActivateMutation.graphql';
import Button from '@app/components/Button/Button';
import Alert from '@app/components/Alert/Alert';

const HOUR = 60 * 60 * 1000;
const DAY = HOUR * 24;
function renderDate(input: string) {
  const date = new Date(input);
  return date.getDate() + '-' + date.toLocaleString('default', { month: 'short' }) + '-' + date.getFullYear();
}

function renderDayDiff(input: string) {
  const currentDate = new Date();
  const date = new Date(input);
  const diffDays = Math.round(Math.abs((currentDate.getTime() - date.getTime()) / DAY));
  return diffDays;
}

export type Key = NonNullable<TokenSigningKeysQuery["response"]["domain"]>["keys"]["edges"][0]["node"];

export function activateInMinutes(key: Key, now: number) {
  const at = new Date(key.mayActivateAt);
  const diff = Math.max(at.valueOf() - now, 0);
  const minutes = Math.round(diff / 60000);
  if (minutes < 9) return `0${minutes}`;
  return minutes.toString();
}

export default function DomainTokenSigningKeysSection(props : Props) {
  const [now, setNow] = useState(Date.now());

  const data = useLazyLoadQuery<TokenSigningKeysQuery>(graphql`
    query TokenSigningKeysQuery($id: ID!) {
      domain(id: $id) {
        id
        keys(first: 1000) {
          mayPrepare
          edges {
            node {
              id
              kid
              active
              expired

              mayActivate
              mayActivateAt
              certificate {
                validFrom
                validTo
                pkcs7
              }
            }
          }
        }
      }
    }
  `, {
    id: btoa(`domain:${props.tenantId}|${props.domain}`)
  });

  const [prepareExecutor, prepareState] = useMutation<TokenSigningKeysPrepareMutation>(graphql`
    mutation TokenSigningKeysPrepareMutation($input: PrepareDomainKeyInput!) {
      prepareDomainKey(input: $input) {
        __typename
        ... on PrepareDomainKeyOutput {
          domain {
            keys(first: 1000) {
              mayPrepare
              edges {
                node {
                  id
                  kid
                  active
                  expired

                  mayActivate
                  mayActivateAt
                  certificate {
                    validFrom
                    validTo
                    pkcs7
                  }
                }
              }
            }
          }
        }
        ... on TimeoutError {
          message
        }
      }
    }
  `);

  const [activateExecutor, activateState] = useMutation<TokenSigningKeysActivateMutation>(graphql`
    mutation TokenSigningKeysActivateMutation($input: ActivateDomainKeyInput!) {
      activateDomainKey(input: $input) {
        domain {
          keys(first: 1000) {
            mayPrepare
            edges {
              node {
                id
                active
                mayActivate
                mayActivateAt
              }
            }
          }
        }
      }
    }
  `);


  useEffect(() => {
    setInterval(() => {
      setNow(Date.now())
    }, 60000);
  }, []);

  const domain = data.domain;
  if (!domain) return null;
  const handlePrepareClick = () => {
    prepareExecutor.execute({
      input: {
        domainId: domain.id,
      }
    });
  }

  const handleActivateClick = (key: Key) => {
    activateExecutor.execute({
      input: {
        domainId: domain.id,
        id: key.id,
        kid: key.kid
      }
    });
  }

  return (
    <div className="token-signing-keys">
      <table>
        <thead>
          <tr>
            <th className="text-left">Key ID (kid)</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_VALID_FROM')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_VALID_TO')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_PKCS7')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_EXPIRES_IN')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_IS_ACTIVE')}</th>
          </tr>
        </thead>
        <tbody>
          {domain.keys.edges.map(edge => edge.node).sort((a, b) => new Date(b.certificate.validFrom).valueOf() - new Date(a.certificate.validFrom).valueOf()).map((key, index) => (
            <tr key={index}>
              <td className="text-left">
                {key.kid}
              </td>
              <td>
                {renderDate(key.certificate.validFrom)}
              </td>
              <td>
                {renderDate(key.certificate.validTo)}
              </td>
              <td>
              <div className="pkcs7">
                {translate('INFO_TOKEN_SIGNING_KEY_PKCS7')}
                <i className="fa fa-chevron-down fa-lg"></i>
                <textarea readOnly className="showPkcs7OnHover" value={key.certificate.pkcs7} />
              </div>
              </td>
              <td>
                {key.expired ? 'Expired' : `${renderDayDiff(key.certificate.validTo)} days`}
              </td>
              <td>
                <div className="flex items-center justify-center p-1">
                  {
                    key.active ? 'Yes' :
                    key.expired ? 'No' :
                    (key.mayActivate && !activateState.pending) ? (
                      <Button variant="primary" type="button" className="button-small" onClick={() => handleActivateClick(key)}>
                        Activate
                      </Button>
                    ) : activateInMinutes(key, now) ? (
                      <span>May activate in <strong>00:{activateInMinutes(key, now)}:00</strong></span>
                    ) : null
                  }
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {prepareState.error && (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={prepareState.error.message} />
      )}
      {activateState.error && (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={activateState.error.message} />
      )}
      {prepareState.response?.prepareDomainKey.__typename === 'TimeoutError' ? (
        <Alert variant="success" className="mt-[15px]" title="Your new key is being prepared, check back in a few minutes."/>
      ) : null}
      {domain.keys.mayPrepare && !prepareState.response && (
        <div className="button-group flex-end" style={{marginTop: '15px'}}>
          <Button variant="primary" type="button" working={prepareState.pending} onClick={handlePrepareClick} autoWidth>
            Prepare new
          </Button>
        </div>
      )}
    </div>
  );
}

DomainTokenSigningKeysSection.Help = <p>{translate('INFO_TOKEN_SIGNING_KEYS_MANAGE')}</p>;