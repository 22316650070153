import React, { useState } from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { isVerifyTechnology, TechnologyGroupTag, TechnologyTag, technologyTags, technologyToGroup } from "@app/models"
import DocsEmbed from './DocsEmbed';
import ApplicationTechnologySelector from './ApplicationTechnologySelector';
import { Link } from 'react-router-dom';
import { VerifyApplicationIntegrate_application$key } from './__generated__/VerifyApplicationIntegrate_application.graphql';

interface Props {
  application: VerifyApplicationIntegrate_application$key
}
export default function VerifyApplicationIntegrate(props: Props) {
  const application = useFragment(graphql`
    fragment VerifyApplicationIntegrate_application on Application {
      id
      technology
      technologyGroup
      realm
      domain {
        name
      }
      tenant {
        shortTenantId
      }
      ...ApplicationTechnologySelector_application
    }
  `, props.application);

  const applicationTechnology = (application.technology ? `technology:${application.technology}` : null) as (TechnologyTag | null);
  const [technology, setTechnology] = useState(applicationTechnology);

  const applicationTechnologyGroup = (application.technologyGroup ? `technology-group:${application.technologyGroup}` : null) as (TechnologyGroupTag | null);
  const [technologyGroup, setTechnologyGroup] = useState(applicationTechnologyGroup);

  const verifyTechnology = technology ? isVerifyTechnology(technology) : null;
  const verifyDocsQuery = `?verify_domain=${application.domain.name}&verify_client_id=${application.realm}`;
  return (
    <React.Fragment>
      {!verifyTechnology ? (
        <React.Fragment>
          <div className="max-w-[750px]">
            <div className="form-group mb-3">
              <label className="control-label"><strong>Which technology are you using for your project?</strong></label>
              <small className="help-text">Selecting a technology will help us guide you to the right tutorials and documentation.</small>
            </div>
            <ApplicationTechnologySelector
              application={application}
              technologies={technologyTags.filter(t => (!technologyGroup && isVerifyTechnology(t)) || (technologyToGroup(t) === technologyGroup))}
              onChange={setTechnology}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <button className="text-primary-900 border-0 mb-5" onClick={() => setTechnology(null)}><i className="fa fa-angle-left"/> Change technology</button>
          {verifyTechnology === 'technology:aspnet-core-v6' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/aspnet-core-v6/${verifyDocsQuery}`} />
            </React.Fragment>
          ) : verifyTechnology === 'technology:aspnet-core-v3' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/aspnet-core-v3/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:nodejs-express' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/nodejs-express/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:wordpress' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/wordpress/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:php' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/php/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:okta' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/okta/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:auth0' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/auth0/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:onelogin' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/onelogin/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:pingfederate' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/pingfederate/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:aws-cognito' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/aws-cognito/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:firebase' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/firebase/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:react' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/react/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:javascript' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/javascript/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:vuejs' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/vuejs/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:ios' ? (
            <div className="max-w-[750px]">
              <strong>Integrate with iOS</strong>
              <p>
                Criipto is working on an SDK for iOS, please <Link to={`/tenant/${application.tenant.shortTenantId}/support`} className="text-primary-900 text-underline">join our slack channel</Link> to inquire
              </p>
            </div>
          ) : verifyTechnology === 'technology:android' ? (
            <div className="max-w-[750px]">
              <strong>Integrate with Android</strong>
              <p>
                Criipto is working on an SDK for Android, please <Link to={`/tenant/${application.tenant.shortTenantId}/support`} className="text-primary-900 text-underline">join our slack channel</Link> to inquire
              </p>
            </div>
          ) : verifyTechnology === 'technology:react-native' ? (
            <div className="max-w-[750px]">
              <strong>Integrate with React-Native</strong>
              <p>
                Criipto is working on an SDK for React-Native, please <Link to={`/tenant/${application.tenant.shortTenantId}/support`} className="text-primary-900 text-underline">join our slack channel</Link> to inquire
              </p>
            </div>
          ) : verifyTechnology === 'technology:expo-react-native' ? (
            <React.Fragment>
              <DocsEmbed path={`/verify/integrations/react-native-expo/${verifyDocsQuery}`}  />
            </React.Fragment>
          ) : verifyTechnology === 'technology:dotnet-maui' ? (
            <div className="max-w-[750px]">
              <strong>Integrate with .NET MAUI</strong>
              <p>
                Thank you for your feedback, Criipto does not yet offer an SDK for .NET MAUI but we suggest you take a look at&nbsp;
                <a href="https://learn.microsoft.com/en-us/dotnet/maui/platform-integration/communication/authentication" target="blank" className="text-primary-900 text-underline">Web authenticator</a>
              </p>
            </div>
          ) : assertUnreachableTechnology(verifyTechnology)}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

function assertUnreachableTechnology(t: never): never {
  throw new Error(`Unexpected technology: ${t}`);
}