import React from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Permissions_tenant$key, UserPermission } from './__generated__/Permissions_tenant.graphql';
import { upperFirst } from 'lodash';
import Checkbox from '@app/components/FormFields/Checkbox/Checkbox';

type PermissionsRelayProps = {
  tenant: Permissions_tenant$key,
  selectable: boolean
  selected: string[]
  onSelect?: (id: UserPermission, selected: boolean) => void
}
export function PermissionsRelay(props: PermissionsRelayProps) {
  const {selectable, selected, onSelect} = props;

  const tenant = useFragment(graphql`
    fragment Permissions_tenant on Tenant {
      userPermissions
    }
  `, props.tenant);

  return (
    <div className='mt-[10px]'>
      {tenant.userPermissions.map(p => (
        <label key={p} className="font-normal cursor-pointer m-0 mr-[10px] pr-3 py-1.5">
          <Checkbox
            name='user_permissions'
            label={permissionDisplayName(p)}
            disabled={!selectable}
            checked={selected.includes(p)}
            onChange={(event) => onSelect ? onSelect(p, event.target.checked) : null}/>
        </label>
      ))}
    </div>
  )
}
export default PermissionsRelay;

export function permissionDisplayName(input: UserPermission) {
  switch (input) {
    case 'FINANCIALS': return 'Financials';
    case 'REPORTING': return 'Reporting';
    case 'CONFIGURATION_MANAGEMENT': return 'Application Management';
    case 'PRIVATE_KEY_MANAGEMENT': return 'Security Configuration';
    case 'USER_ADMIN': return 'User administration';
    case 'EXTENSIONS_PRODUCTION_READ': 'extensions:production:read';
    case 'EXTENSIONS_PRODUCTION_WRITE': 'extensions:production:write';
    case 'EXTENSIONS_TEST_READ': 'extensions:test:read';
    case 'EXTENSIONS_TEST_WRITE': 'extensions:test:write';
    case '%future added value': return upperFirst(input.replace(/_/g, ' ').toLowerCase());
  }
  assertUnreachable(input);
}

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}