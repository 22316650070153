import React, { useEffect, useState } from 'react';

import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import moment from 'moment';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, LabelList } from 'recharts';


import { OrganizationUsageScreenQuery } from './__generated__/OrganizationUsageScreenQuery.graphql';
import { Environment, TenantRouteParams } from '@app/models';
import { useRouteMatch } from 'react-router-dom';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';

import './OrganizationUsageScreen.scss';

const Query =
  graphql`
    query OrganizationUsageScreenQuery (
      $organizationId: ID!,
      $environment: Environment!
      $from: String!
      $to: String!
    ) {
      admin {
        organization(id: $organizationId) {
          tenants {
            id
            tenant {
              name
              usage(environment: $environment, interval: MONTH, from: $from, to: $to) {
                bucket
                authentications
                signatures
              }
            }
          }
        }
      }
    }
  `

export default function OrganizationUsageScreen(props: {
  organizationId: string
}) {
  const match = useRouteMatch<TenantRouteParams>();
  const [environment, setEnvironment] = useState<Environment>("PRODUCTION");
  const [from, setFrom] = useState(() => moment().subtract(12, 'month').toDate());
  const [to, setTo] = useState(() => new Date());

  const [queryReference, loadQuery] = useQueryLoader<OrganizationUsageScreenQuery>(
    Query,
    null
  );

  useEffect(() => {
    loadQuery({
      organizationId: props.organizationId,
      environment: environment,
      from: from.toJSON(),
      to: to.toJSON()
    })
  }, [environment, match.params]);

  return (
    <div className="organization-usage-screen">
      <select value={environment} onChange={event => setEnvironment(event.target.value as Environment)} className="mb-[15px]">
        <option value="TEST">Test</option>
        <option value="PRODUCTION">Production</option>
      </select>
      {queryReference ? (
        <GraphQLErrorBoundary>
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            <Results queryReference={queryReference} />
          </React.Suspense>
        </GraphQLErrorBoundary>
      ) : null}
    </div>
  );
}

type Usage = NonNullable<OrganizationUsageScreenQuery["response"]["admin"]["organization"]>["tenants"][0]["tenant"]["usage"][0];
function Results(props: {
  queryReference: PreloadedQuery<OrganizationUsageScreenQuery>,
}) {
  const data = usePreloadedQuery<OrganizationUsageScreenQuery>(Query, props.queryReference);

  const combinedUsage = (data.admin.organization?.tenants ?? []).reduce((memo: Usage[], {tenant}) => {
    const buckets = Array.from(new Set(memo.map(s => s.bucket).concat(tenant.usage.map(s => s.bucket))));
    return buckets.map(bucket => ({
      bucket,
      authentications: (memo.find(s => s.bucket === bucket)?.authentications ?? 0) + (tenant.usage.find(s => s.bucket === bucket)?.authentications ?? 0),
      signatures: (memo.find(s => s.bucket === bucket)?.signatures ?? 0) + (tenant.usage.find(s => s.bucket === bucket)?.signatures ?? 0)
    }));
  }, []);

  return (
    <React.Fragment>
      <h2>Authentication usage</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={combinedUsage as any}
        >
          <CartesianGrid stroke="#faf6f6" fill="#FFF"  />
          <XAxis
            dataKey="bucket"
            interval="preserveStartEnd"
            label="Month"
          />
          <YAxis
            domain={[0, 'auto']}
            tickCount={7}
            padding={{top: 25}}
          />
          <Tooltip cursor={{fill: 'transparent'}} />
          <Bar dataKey="authentications" name="Authentications" fill="#E28A85">
            <LabelList dataKey="authentications" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <h2>Signatures usage</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={combinedUsage as any}
        >
          <CartesianGrid stroke="#faf6f6" fill="#FFF"  />
          <XAxis
            dataKey="bucket"
            label="Month"
            interval="preserveStartEnd"
          />
          <YAxis
            domain={[0, 'auto']}
            tickCount={7}
            padding={{top: 25}}
          />
          <Tooltip cursor={{fill: 'transparent'}} />
          <Bar dataKey="signatures" name="Signatures" fill="#5A4BDB" label="123" >
            <LabelList dataKey="signatures" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

