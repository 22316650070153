/**
 * @generated SignedSource<<3eaaeba733bcfcc0394e6237b9dcca59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "DKK" | "EUR" | "%future added value";
export type Interval = "MONTH" | "YEAR" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionScreen_ProductPanel_product$data = {
  readonly allowRemove: boolean;
  readonly plan: {
    readonly currency: Currency;
    readonly id: string;
    readonly interval: Interval;
    readonly name: string;
    readonly product: Product;
    readonly subscriptionPrice: number;
    readonly unitPrice: number;
    readonly volume: number;
    readonly " $fragmentSpreads": FragmentRefs<"SubscriptionPlanModal_plan">;
  } | null;
  readonly product: Product;
  readonly " $fragmentType": "SubscriptionScreen_ProductPanel_product";
};
export type SubscriptionScreen_ProductPanel_product$key = {
  readonly " $data"?: SubscriptionScreen_ProductPanel_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionScreen_ProductPanel_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionScreen_ProductPanel_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Plan",
      "kind": "LinkedField",
      "name": "plan",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volume",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitPrice",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SubscriptionPlanModal_plan"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowRemove",
      "storageKey": null
    }
  ],
  "type": "SubscriptionProduct",
  "abstractKey": null
};
})();

(node as any).hash = "afd831ae3be2d47cfc4eeaffa84d1fb7";

export default node;
