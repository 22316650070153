import React, { useReducer } from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import Button from "@app/components/Button";
import { ConfirmModal } from '@app/components/Modal/Modal';
import { useHistory } from 'react-router-dom';
import { RemoveUserButton_Mutation } from './__generated__/RemoveUserButton_Mutation.graphql';
import useMutation from '@app/hooks/useMutation';
import { getRelayUsersConnections } from '@app/screens/DomainsScreen/utils';
import { RemoveUserButton_user$key } from './__generated__/RemoveUserButton_user.graphql';
import { RemoveUserButton_tenant$key } from './__generated__/RemoveUserButton_tenant.graphql';
import useMounted from '@app/hooks/useMounted';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

interface Props {
  tenant: RemoveUserButton_tenant$key
  user: RemoveUserButton_user$key
}
export default function RemoveUserButton(props: Props) {
  const isMounted = useMounted();
  const toast = useToast();
  const tenant = useFragment(
    graphql`
      fragment RemoveUserButton_tenant on Tenant {
        id
        viewerPermissions {
          users
        }
      }
    `,
    props.tenant
  );

  const user = useFragment(
    graphql`
      fragment RemoveUserButton_user on User {
        id
        name

        isViewer
        isUserAdmin
      }
    `,
    props.user
  );

  const [showConfirm, toggleConfirm] = useReducer(value => !value, false);
  const history = useHistory();

  const [executor, state] = useMutation<RemoveUserButton_Mutation>(graphql`
    mutation RemoveUserButton_Mutation(
      $connections: [ID!]!
      $input: DeleteUserInput!
    ) {
      deleteUser(input: $input) {
        deletedUserID @deleteEdge(connections: $connections)
      }
    }
  `);

  const handleClick = () => {
    toggleConfirm();
  }

  const handleConfirm = async () => {
    await executor.executePromise({
      input: {
        id: user.id
      },
      connections: getRelayUsersConnections(tenant.id)
    });

    if (user.isViewer) {
      history.push('/refreshlogin');
    } else {
      toast.success({title: 'User removed'});
    }

    if (isMounted.current) toggleConfirm();
  };

  if (tenant.viewerPermissions.users !== 'WRITE') return null;

  return (
    <React.Fragment>
      <Button variant="danger" className="button-small" tooltip={'Remove user'} autoWidth onClick={handleClick}>
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        open={showConfirm}
        onCancel={toggleConfirm}
        onConfirm={handleConfirm}
        confirmText="Remove user"
        confirmVariant="danger"
      >
        <div>
          <h2>Remove user</h2>
          <p className='!m-0'>Are you sure you want to remove this invited user {user.name}?</p>
        </div>

        {state.error ? (
          <Alert variant="error" className="mt-[15px]" title="There was an error removing the user" message={state.error.message} />
        ) : null}
      </ConfirmModal>
    </React.Fragment>
  )
}