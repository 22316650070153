import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import './UsersScreen.scss';

import InvitationForm, {ROLE_DESCRIPTION} from './components/InvitationForm';
import Tooltip from '@app/components/Tooltip/Tooltip';
import RemoveUserButton from './components/RemoveUserButton/RemoveUserButton';
import ResendInvitationButton from './components/ResendInvitationButton/ResendInvitationButton';
import { useParams } from 'react-router-dom';
import EditUserButton from './components/EditUserButton/EditUserButton';
import UserInvitationButton from './components/UserInvitationButton/UserInvitationButton';
import { UsersScreen_Query } from './__generated__/UsersScreen_Query.graphql';
import { UsersScreen_invitation$key } from './__generated__/UsersScreen_invitation.graphql';
import { UsersScreen_Invitation_tenant$key } from './__generated__/UsersScreen_Invitation_tenant.graphql';
import DeleteInvitationButton from './components/DeleteInvitationButton/DeleteInvitationButton';
import { UsersScreen_user$key } from './__generated__/UsersScreen_user.graphql';
import { UsersScreen_User_tenant$key } from './__generated__/UsersScreen_User_tenant.graphql';
import { TenantRouteParams } from '@app/models';

export default function UsersScreen() {
  const params = useParams<TenantRouteParams>();

  const data = useLazyLoadQuery<UsersScreen_Query>(graphql`
    query UsersScreen_Query($tenantId: ID!) {
      tenant(id: $tenantId) {
        id

        viewerPermissions {
          users
        }

        ... UserInvitationButton_tenant
        ... InvitationForm_tenant
        ... UsersScreen_Invitation_tenant
        ... UsersScreen_User_tenant

        users(first: 1000) @connection(key: "tenant_users") {
          edges {
            node {
              id

              isUserAdmin
              ...UsersScreen_user
            }
          }
        }

        invitations(first: 1000) @connection(key: "tenant_invitations") {
          edges {
            node {
              id

              ...UsersScreen_invitation
            }
          }
        }
      }
    }
  `, {
    tenantId: btoa(`tenant:${params.tenantId}`),
  });


  if (!data.tenant) return null;
  if (data.tenant.viewerPermissions.users === 'NONE') return null;

  return (
    <React.Fragment>
      <div className="app-content-header flex align-items-center justify-content-between">
        <h1>Users</h1>
        <div className="actions">
          <UserInvitationButton tenant={data.tenant} variant="primary"><i className="fa fa-plus"></i>&nbsp;Add user</UserInvitationButton>
        </div>
      </div>
      <div className="app-content-main users-screen">
        <div className="accordion">
          <div className="accordion-header">
            <div className="row">
              <div className="col-xs-3">
                <h3>Name</h3>
              </div>
              <div className="col-xs-4">
                <h3>Email</h3>
              </div>
              <div className="col-xs-3">
                <h3>Role</h3>
              </div>
              <div className="col-xs-2">

              </div>
            </div>
          </div>

          {data.tenant.users.edges.map(edge => (
            <User
              key={edge.node.id}
              user={edge.node}
              tenant={data.tenant!}
              allowRemove={edge.node.isUserAdmin ? (
                data.tenant!.users.edges.filter(edge => edge.node.isUserAdmin).length > 1
              ) : true}
            />
          ))}

          {data.tenant.invitations.edges.map(edge => (
            <Invitation key={edge.node.id} invitation={edge.node} tenant={data.tenant!} />
          ))}

          <div className="accordion-item ">
            <div className="accordion-header large" style={{height: 'auto', paddingTop: '20px'}}>
              <InvitationForm tenant={data.tenant} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

type UserProps = {
  tenant: UsersScreen_User_tenant$key
  user: UsersScreen_user$key
  allowRemove: boolean
};
function User(props: UserProps) {
  const tenant = useFragment(
    graphql`
      fragment UsersScreen_User_tenant on Tenant {
        ... RemoveUserButton_tenant
        ... EditUserButton_tenant
      }
    `,
    props.tenant
  );

  const user = useFragment(
    graphql`
      fragment UsersScreen_user on User {
        id
        name
        email
        role

        ... RemoveUserButton_user
        ... EditUserButton_user
      }
    `,
    props.user
  );

  const roleTooltip = user.role ? ROLE_DESCRIPTION[user.role] : 'Custom permissions';

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-3">
            <strong>{user.name}</strong>
          </div>
          <div className="col-xs-4">
            {user.email}
          </div>
          <div className="col-xs-3">
            <Tooltip id={`${user.name}_permissions`} tooltip={roleTooltip ?? ''}>
              <span>{user.role ?? 'Custom'}</span>
            </Tooltip>
          </div>
          <div className="col-xs-2 flex justify-content-end" style={{gap: '10px'}}>
            <EditUserButton tenant={tenant} user={user} />
            {props.allowRemove && (<RemoveUserButton tenant={tenant} user={user} />)}
          </div>
        </div>
      </div>
    </div>
  )
}


type InvitationProps = {
  tenant: UsersScreen_Invitation_tenant$key
  invitation: UsersScreen_invitation$key
};
function Invitation(props: InvitationProps) {
  const tenant = useFragment(
    graphql`
      fragment UsersScreen_Invitation_tenant on Tenant {
        ...DeleteInvitationButton_tenant
      }
    `,
    props.tenant
  );

  const invitation = useFragment(
    graphql`
      fragment UsersScreen_invitation on Invitation {
        id
        name
        email
        role

        ... DeleteInvitationButton_invitation
        ... ResendInvitationButton_invitation
      }
    `,
    props.invitation
  );

  const roleTooltip = invitation.role ? ROLE_DESCRIPTION[invitation.role] : 'Custom permissions';

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-3">
            <strong>{invitation.name}</strong>
          </div>
          <div className="col-xs-4">
            {invitation.email}
          </div>
          <div className="col-xs-3">
            <Tooltip id={`${invitation.name}_permissions`} tooltip={roleTooltip ?? ''}>
              <span>{invitation.role ?? 'Custom'} (Pending)</span>
            </Tooltip>
          </div>
          <div className="col-xs-2 flex justify-content-end" style={{gap: '10px'}}>
            <ResendInvitationButton invitation={invitation} />
            <DeleteInvitationButton tenant={tenant} invitation={invitation} />
          </div>
        </div>
      </div>
    </div>
  )
}