import React from 'react';

import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  DanishMitIdConfiguration, Environment
} from '@app/models';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch, Select} from '@components/Form';
import { ConfigurationMutation } from './__generated__/ConfigurationMutation.graphql';
import { Configuration_identityProvider$key, CprStrategy } from './__generated__/Configuration_identityProvider.graphql';
import { Configuration_tenant$key } from './__generated__/Configuration_tenant.graphql';

interface Props {
  environment: Environment
  identityProvider: Configuration_identityProvider$key
  tenant: Configuration_tenant$key
}


type FormValues = Required<Omit<ConfigurationMutation["variables"]["input"], 'tenantId' | 'environment'>>;

export default function DKMitIDProviderConfiguration(props : Props) {
  const {environment} = props;

  const identityProvider = useFragment(
    graphql`
      fragment Configuration_identityProvider on IdentityProvider {
        id
        tenant {
          id
        }

        longName

        ... on DanishMitID {
          brokerLandingPageDomain
          nemIdDomain
          cprLookupStrategy
          cprIsOptional
          includePostalAddress
          disableSignatories
          showOnNemId
          referenceText
          serviceProviderId
          serviceProviderReference
        }
      }
    `,
    props.identityProvider
  );

  const tenant = useFragment(
    graphql`
      fragment Configuration_tenant on Tenant {
        id
        domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
          edges {
            node {
              id
              name
              environment
            }
          }
        }

        features {
          DKNEMID_ENABLED
        }
      }
    `,
    props.tenant
  );

  const [{executePromise}] = useMutation<ConfigurationMutation>(graphql`
    mutation ConfigurationMutation(
      $input: UpdateDanishMitIDInput!
    ) {
      updateDanishMitID(input: $input) {
        id
        isConfigured
        ... on DanishMitID {
          brokerLandingPageDomain
          nemIdDomain
          cprLookupStrategy
          cprIsOptional
          includePostalAddress
          showOnNemId
          referenceText
          serviceProviderId
          serviceProviderReference
        }
      }
    }
  `);

  const initialValues : FormValues = {
    cprLookupStrategy: identityProvider.cprLookupStrategy ?? "ASKUSER",
    cprIsOptional: identityProvider.cprIsOptional ?? true,
    includePostalAddress: identityProvider.includePostalAddress ?? false,
    disableSignatories: identityProvider.disableSignatories ?? false,
    showOnNemId: identityProvider.showOnNemId ?? false,
    brokerLandingPageDomain: identityProvider.brokerLandingPageDomain ?? null,
    nemIdDomain: identityProvider.nemIdDomain ?? null,
    referenceText: identityProvider.referenceText ?? '',
    serviceProviderReference: identityProvider.serviceProviderReference ?? '',
  };

  let cprStrategyOff : CprStrategy = "OMIT";
  let cprStrategyOn : CprStrategy = "ASKUSER";

  const domains = tenant.domains.edges.map(edge => edge.node);
  const domainSuffix = environment === 'PRODUCTION' ? '.mitid.dk' : '.pp.mitid.dk';
  const criiptoDomain = process.env.DASHBOARD_ENV === 'test' ? `criipto-test${domainSuffix}` : `criipto${domainSuffix}`;

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <div className="max-w-[750px]">
          <h3>
            {translate('CONNECT')} Danish MitID
          </h3>
          <EnvironmentTag />

          <Select<DanishMitIdConfiguration>
            name="brokerLandingPageDomain"
            label={translate('LABEL_DK_MITID_BROKER_DOMAIN')}
            options={[
              {label: criiptoDomain, value: ""},
              ...domains.filter(domain => domain.name.endsWith('.mitid.dk')).map(domain => ({label: domain.name, value: domain.name}))
            ]}
            help={(
              <small className="help-text">
                Your MitID broker domain is the DNS domain on which users will perform MitID logins.
              </small>
            )}
          />

          {tenant.features.DKNEMID_ENABLED ? (
            <Select<DanishMitIdConfiguration>
              name="nemIdDomain"
              label={translate('LABEL_DK_MITID_NEMID_DOMAIN')}
              options={domains.map(domain => ({label: domain.name, value: domain.name}))}
              help={(
                <small className="help-text">
                  NemID fallback domain is the DNS domain which will be used for side-by-side switching between NemID/MitID during transition.
                </small>
              )}
            />
          ) : null}

          <InputField<DanishMitIdConfiguration>
            type="text"
            label={translate('LABEL_DK_MITID_REFERENCE_TEXT')}
            name="referenceText"
            help={(
              <small className="help-text">
                By default, we show the name of your application to end users during the MitID login flow. If your application does not have a name, this value is used as a fallback.
              </small>
            )}
          />
          <Switch<DanishMitIdConfiguration>
            name="cprLookupStrategy"
            off={cprStrategyOff}
            on={cprStrategyOn}
            label={translate('HINT_DK_MITID_CPR')}
          />
          <Switch<DanishMitIdConfiguration>
            name="cprIsOptional"
            label={translate('HINT_DK_MITID_CPR_IS_OPTIONAL')}
            help={(
              <small className="help-text">
                Not all MitID users have a CPR number. If your application can handle that (authenticated MitID users without a CPR number), you can enable this toggle.
              </small>
            )}
          />
          <Switch<DanishMitIdConfiguration>
            name="disableSignatories"
            label="Disable signatories for MitID business logins"
            help={(
              <small className="help-text">
                Looking up signatories requires asking for the MitID users CPR number. After disabling, can be selectively enabled via <code>login_hint=business_signatories</code>
              </small>
            )}
          />

          {/* <Switch<DanishMitIdConfiguration>
            name="includePostalAddress"
            label={translate('HINT_DK_MITID_INCLUDE_POSTAL_ADDRESS')}
          /> */}
          {tenant.features.DKNEMID_ENABLED ? (
            <Switch<DanishMitIdConfiguration>
              name="showOnNemId"
              label={translate('HINT_DK_MITID_SHOW_ON_NEMID')}
            />
          ) : null}
          <div className="form-group">
            <label className="control-label">{translate('LABEL_DK_MITID_SERVICE_PROVIDER_ID')}</label>
            <input type="text" className="form-control" disabled value={identityProvider?.serviceProviderId ?? ''} />
            <small className="help-text">
              Your service provider id is an internal technical identifier that Criipto may ask you to supply in support requests
            </small>
          </div>

          <FormError error={error} />
          <FormSuccess message={!isPending && isSuccess && `Your Danish MitID settings have been updated` || null} />
          <div className="flex flex-end mt-[25px]">
            <Button variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
          </div>
        </div>
      )}
    </Form>
  )
}
