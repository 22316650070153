import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Tag } from '@app/components/Tag/Tag';
import { TenantRouteParams } from '@app/models';

import DeleteApplicationButton from '../../components/DeleteApplicationButton/DeleteApplicationButton';
import OIDCSection from '../../components/ApplicationEditor/Sections/OIDC';
import CopyApplicationButton from '../../components/CopyApplicationButton/CopyApplicationButton';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import useEnvironment from '@app/hooks/useEnvironment';
import GeneralSection from '../../components/ApplicationEditor/Sections/General';
import { EditAgeverificationApplicationScreen_application$key, EditAgeverificationApplicationScreen_application$data } from './__generated__/EditAgeverificationApplicationScreen_application.graphql';
import AgeverificationApplicationIntegrate from '../../components/ApplicationEditor/AgeverificationApplicationIntegrate';
import { EditAgeverificationApplicationScreenQuery } from './__generated__/EditAgeverificationApplicationScreenQuery.graphql';

interface Props {
  parentUrl: string,
  application: EditAgeverificationApplicationScreen_application$key
  onDelete: () => void
}
export default function EditAgeverificationApplicationScreen(props: Props) {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();

  const application = useFragment(
    graphql`
      fragment EditAgeverificationApplicationScreen_application on Application {
        id
        name
        ... GeneralSection_application
        ... OIDCSection_application
        ... AgeverificationApplicationIntegrate_application
        ... DeleteApplicationButton_application
        ... CopyApplicationButton_application
      }
    `, props.application
  );

  const data = useLazyLoadQuery<EditAgeverificationApplicationScreenQuery>(
    graphql`
      query EditAgeverificationApplicationScreenQuery($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          id
          ... UISection_tenant @arguments(environment: $environment)
        }
      }
    `
  , {
    tenantId: btoa(`tenant:${match.params.tenantId}`),
    environment
  });

  if (!data.tenant) return null;

  return (
    <React.Fragment>
      <EditAgeverificationApplicationScreenHeader {...props} application={application} />
      <div className="app-tab-content">
        <Switch>
          <Route path={match.path} exact={true}>
            <div className="max-w-[750px]">
              <GraphQLErrorBoundary>
                <GeneralSection
                  parentUrl={props.parentUrl}
                  application={application}
                />
              </GraphQLErrorBoundary>
            </div>
          </Route>
          <Route path={`${match.path}/oidc`} exact={true}>
            <div className="max-w-[750px]">
              <OIDCSection application={application}/>
            </div>
          </Route>
          <Route path={`${match.path}/integrate`}>
            <AgeverificationApplicationIntegrate application={application} />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}

interface HeaderProps {
  parentUrl: string,
  application: EditAgeverificationApplicationScreen_application$data
  onDelete: () => void,
}
export function EditAgeverificationApplicationScreenHeader(props: HeaderProps) {
  const {parentUrl, application, onDelete} = props;
  const match = useRouteMatch();
  return (
    <div className="app-content-header with-tabs">
      <div className="breadcrumb">
        <Link to={parentUrl}>Applications</Link>
        <i className="fa fa-angle-right" />
        <div>Age verification Application Details</div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-row items-center gap-[10px]">
          <h1>{application?.name}</h1>
          <Tag>Age verification</Tag>
        </div>
        <div className="flex flex-row items-center gap-[10px]">
          <CopyApplicationButton key={`copy_application_button_${application.id}`} variant="default" application={application} />
          <DeleteApplicationButton onDelete={onDelete} application={application} autoWidth />
        </div>
      </div>

      <div className="app-content-tabs">
        <ul>
          <li>
            <NavLink to={`${match.url}/integrate`} activeClassName="active">Integrate</NavLink>
          </li>
          <li>
            <NavLink to={match.url} exact={true} activeClassName="active">General</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/oidc`} activeClassName="active">OpenID Connect</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}