import React, { useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import {LOGOUT_URL} from '@app/constants';
import { translate } from '@app/i18n';

import { useSelector } from '@app/redux';

import './Header.scss';
import { useVerifyAccessToken } from '@app/hooks/useAuth';
import { HeaderQuery } from './__generated__/HeaderQuery.graphql';
import { useOptionalEnvironment } from '@app/hooks/useEnvironment';
import { upperFirst } from 'lodash';

export default function Header(props: {onHamburger?: () => void}) {
  const location = useLocation();
  const authClient = useSelector(state => state.auth.client);
  const token = useVerifyAccessToken();
  const environmentCandidate = useOptionalEnvironment();

  const skipGraphQL = !token;
  const data = useLazyLoadQuery<HeaderQuery>(
    graphql`
      query HeaderQuery($skip: Boolean!) {
        viewer @skip (if: $skip) {
          ... on UserViewer {
            name
            email
            isCriiptoAdmin

            organizations {
              name
              shortId
            }
          }
        }
      }
    `,
    {
      skip: skipGraphQL
    },
    {
      fetchKey: token ?? undefined
    }
  );

  const viewer = data.viewer;

  const handleLogout = () => {
    authClient.logout({
      returnTo: LOGOUT_URL
    });
  }

  const [showDropDown, setShowDropDown] = useState(false);
  const handleDropDownToggle = (isOpen: boolean) => {
    setShowDropDown(isOpen);
  }

  const isGlobalScreen = location.pathname.includes('/users') || location.pathname.includes('/billing') || location.pathname.includes('tools/criipto-admin');
  const isTenantScreen = location.pathname.startsWith('/tenant');
  const showEnvironment = isTenantScreen && !isGlobalScreen;
  const environment = showEnvironment ? (environmentCandidate ?? 'TEST') : null;

  if (!viewer) return null;

  return (
    <header id="header" className={environment ?? undefined}>
      <div className="toolbar">
        <div className="flex align-items-center gap-[16px]">
          {props.onHamburger ? (
            <div className="sidebar-hamburger" onClick={props.onHamburger}>
              <i className="fa fa-bars" />
            </div>
          ) : null}
          <div className="flex align-items-center justify-content-center gap-[16px]">
            <Link to="/">
              <img src="/assets/images/logomark.svg" alt="Criipto" />
            </Link>

            {environment && showEnvironment && (
              <span>{upperFirst(environment.toLowerCase())} environment</span>
            )}
          </div>
        </div>


        <nav id="nav" className="tenant-dropdown">
          <div className="nav-drop">
            <ul className="admin-menu" >
              <Dropdown id="tenant_dropdown" componentClass={"li"} onToggle={handleDropDownToggle} open={showDropDown}>
                <Dropdown.Toggle useAnchor={true} noCaret={true}>
                  <span className="hidden-xs">{viewer.name ?? 'Your account'}&nbsp;</span>
                  <i className="fa fa-angle-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  {viewer.email && (
                    <React.Fragment>
                      <li>
                        <div className="menu-option">
                          {viewer.email}
                        </div>
                      </li>
                      <li className="menu-seperator" />
                    </React.Fragment>
                  )}
                  <li>
                    <Link to={`/user/mfa`} className="menu-option">
                      Enroll MFA
                    </Link>
                  </li>
                  <li className="menu-seperator" />
                  {viewer.organizations?.length ? (
                    <React.Fragment>
                      {viewer.organizations.map(organization => (
                        <li key={organization.shortId}>
                          <Link to={`/organization/${organization.shortId}`} className="menu-option">
                            {organization.name}
                          </Link>
                        </li>
                      ))}
                      <li className="menu-seperator" />
                    </React.Fragment>
                  ) : null}
                  {viewer.isCriiptoAdmin && (
                    <li>
                      <Link to="/admin" className="menu-option">
                        Admin
                      </Link>
                    </li>
                  )}
                  <li>
                    <div className="menu-option" onClick={handleLogout} style={{cursor: 'pointer'}}>
                      <span>
                        {translate('LOG_OUT')}
                      </span>
                    </div>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}