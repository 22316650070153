import React from 'react';
import cx from 'classnames';
import {Link, LinkProps} from 'react-router-dom';

import styles from './Button.module.css';
import Tooltip from '@app/components/Tooltip';

export type BaseButtonProps = {
  variant: "primary" | "default" | "danger"
  /** default: sm */
  size?: "sm" | "md" | "lg" | "xl"

  working?: boolean
}

export type TextButtonProps = {
  iconLeft?: React.ReactNode
  children: React.ReactNode
  iconRight?: React.ReactNode
}

export type IconButtonProps = {
  icon: React.ReactNode
}

function className(props: BaseButtonProps & (TextButtonProps | IconButtonProps)) {
  const size = props.size ?? 'sm';
  return cx(
    styles.button,
    styles[`variant-${props.variant}`],
    styles[`size-${size}`],
    {
      [styles.working]: props.working,
      [styles['icon-button']]: "icon" in props ? true : false
    }
  )
}

function Inner(props: BaseButtonProps & (TextButtonProps | IconButtonProps)) {
  if ("icon" in props) {
    return (
      <div className={styles.icon}>
        {props.working ? <Spinner /> : <>{props.icon}</>}
      </div>
    );
  }

  const iconLeft = props.working && !props.iconRight && props.iconLeft ? <Spinner /> : props.iconLeft;
  const iconRight = props.working && (props.iconRight || !props.iconLeft) ? <Spinner /> : props.iconRight;

  return (
    <>
      {iconLeft ? <div className={styles.icon}>{iconLeft}</div> : null}
      {props.children}
      {iconRight ? <div className={styles.icon}>{iconRight}</div> : null}
    </>
  )
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, BaseButtonProps {
  working?: boolean,
  /** deprecated */
  autoWidth?: boolean
  tooltip?: string,
  buttonRef?: React.RefObject<HTMLButtonElement>
}
export default function Button({working, ...props} : ButtonProps & (TextButtonProps | IconButtonProps)) {
  const disabled = props.disabled || working;
  const button = (
    <button type="button" {...props} className={cx(className(props), props.className)} disabled={disabled} ref={props.buttonRef}>
      <Inner {...props} working={working} />
    </button>
  );

  if (props.tooltip) {
    return (
      <Tooltip id="button_tooltip" tooltip={props.tooltip}>
        {button}
      </Tooltip>
    )
  }

  return button;
}

export interface AnchorButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>, BaseButtonProps {
  /** deprecated */
  autoWidth?: boolean,
  children: React.ReactNode,
}
export function AnchorButton(props : AnchorButtonProps & (TextButtonProps | IconButtonProps)) {
  return (
    <a {...props} className={cx(className(props), props.className)}>
      <Inner {...props} />
    </a>
  )
}

export interface LinkButtonProps extends LinkProps, BaseButtonProps {
  /** deprecated */
  autoWidth?: boolean,
  children: React.ReactNode
}
export function LinkButton(props : LinkButtonProps & (TextButtonProps | IconButtonProps)) {
  return (
    <Link {...props} className={cx(className(props), props.className)}>
      <Inner {...props} />
    </Link>
  )
}

function Spinner(props: {className?: string}) {
  const className = cx(styles.spin, props.className);
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
    </svg>
  );
}