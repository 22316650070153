import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useRouteMatch } from 'react-router-dom';

import { OrganizationRouteParams } from '@app/models';
import Tooltip from '@app/components/Tooltip';
import { Users_OrganizationUsersScreen_Query } from './__generated__/Users_OrganizationUsersScreen_Query.graphql';
import { Users_OrganizationUser$key } from './__generated__/Users_OrganizationUser.graphql';
import { Users_OrganizationUser_User$key } from './__generated__/Users_OrganizationUser_User.graphql';
import { Users_OrganizationUser_Organization$key } from './__generated__/Users_OrganizationUser_Organization.graphql';
import Button from '@app/components/Button/Button';
import useToast from '@app/hooks/useToast';
import { useMfa } from '@app/hooks/useMfa';
import useMutation from '@app/hooks/useMutation';
import { Users_ChangePermissions_Mutation } from './__generated__/Users_ChangePermissions_Mutation.graphql';

export default function OrganizationUsersScreen() {
  const match = useRouteMatch<OrganizationRouteParams>();
  const data = useLazyLoadQuery<Users_OrganizationUsersScreen_Query>(
    graphql`
      query Users_OrganizationUsersScreen_Query($organizationId: ID!) {
        organization(id: $organizationId) @required(action: THROW) {
          ...Users_OrganizationUser_Organization

          users {
            edges {
              node {
                id
                ...Users_OrganizationUser_User
              }
            }
          }
        }
      }
    `,
    {
      organizationId: btoa(`organization:${match.params.organizationId}`),
    }
  );

  return (
    <div className="max-w-[750px]">
      <div className="accordion">
        <div className="accordion-header">
          <div className="row">
            <div className="col-xs-4">
              <h3>User</h3>
            </div>
            <div className="col-xs-6">
              <h3>Permissions</h3>
            </div>
            <div className="col-xs-2">
            </div>
          </div>
        </div>
        {data.organization.users.edges.map(edge => edge.node).map(user => (
          <OrganizationUser key={user.id} organization={data.organization} user={user} />
        ))}
      </div>
    </div>
  );
}

function OrganizationUser(props: {
  organization: Users_OrganizationUser_Organization$key
  user: Users_OrganizationUser_User$key
}) {
  const organization = useFragment(graphql`
    fragment Users_OrganizationUser_Organization on Organization {
      allPermissions {
        id
        name
        description
      }
      viewerPermissions {
        users
      }
    }
  `, props.organization)
  const user = useFragment(graphql`
    fragment Users_OrganizationUser_User on OrganizationUser {
      id
      name
      email

      permissions {
        id
        name
        description
      }
    }
  `, props.user);

  const canModify = organization.viewerPermissions.users === 'WRITE';
  const [permissions, setPermissions] = useState(() => user.permissions.map(p => p.id));
  const toast = useToast();
  const checkMFA = useMfa(true);

  const [executor, state] = useMutation<Users_ChangePermissions_Mutation>(graphql`
    mutation Users_ChangePermissions_Mutation($input: ChangeOrganizationUserPermissionsInput!) {
      changeOrganizationUserPermissions(input: $input) {
        user {
          ... Users_OrganizationUser_User
        }
      }
    }
  `);

  const handleSave = async () => {
    await checkMFA();

    return executor.executePromise({
      input: {
        userId: user.id,
        permissions
      }
    }).then(() => {
      toast.success({title: 'User permissions updated'});
    }).catch(err => {
      toast.error({title: 'Failed to update user permissions', message: err?.toString()})
    });
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <div className="row !items-start">
          <div className="col-xs-4">
            <strong>{user.name} ({user.email})</strong>
          </div>
          <div className="col-xs-6 flex flex-row flex-wrap gap-[10px]">
            {organization.allPermissions.map(p => (
              <label className="font-normal flex flex-row items-center gap-[2px] !m-0" key={p.id}>
                <input
                  type="checkbox"
                  disabled={!canModify}
                  checked={permissions.some(s => s === p.id)}
                  className="!m-0"
                  onChange={event => {
                    setPermissions(permissions => permissions.filter(s => s !== p.id).concat(event.target.checked ? [p.id] : []))
                  }}
                />
                {p.name}
              </label>
            ))}
          </div>
          <div className="col-xs-2">
            {canModify ? (
              <Button variant="default" onClick={handleSave} working={state.pending}>
                Save
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}