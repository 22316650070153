import React, { useReducer, useState } from 'react';
import useMutation from '@app/hooks/useMutation';
import graphql from 'babel-plugin-relay/macro';

import { ConfirmModal } from '@app/components/Modal/Modal';
import Textarea from '@app/components/FormFields/Textarea/Textarea';
import Alert from '@app/components/Alert/Alert';
import { IdpOrderToggleMutation } from './__generated__/IdpOrderToggleMutation.graphql';
import { useFragment } from 'react-relay';
import { IdpOrderToggle_tenant$key, IdpOrderToggle_tenant$data } from './__generated__/IdpOrderToggle_tenant.graphql';
import { StandaloneSwitch } from '@app/components/Form/Form';

interface Props {
  idp: IdpOrderToggleMutation["variables"]["input"]["identityProvider"],
  tenant: IdpOrderToggle_tenant$key
}

export default function IdpOrderToggle(props: Props) {
  const [showConfirm, toggleConfirm] = useReducer((value) => !value, false);

  const tenant = useFragment(graphql`
    fragment IdpOrderToggle_tenant on Tenant {
      id

      features {
        NOBANKID_order_form
        SEBANKID_order_form
        DKMITID_CUSTOM_DOMAIN_order_form
      }
    }
  `, props.tenant);

  const [enabled, setEnabled] = useState(tenant.features[props.idp + '_order_form' as keyof IdpOrderToggle_tenant$data["features"]]);
  const [note, setNote] = useState('');

  const [{executePromise}, state] = useMutation<IdpOrderToggleMutation>(graphql`
    mutation IdpOrderToggleMutation($input: ToggleIdentityProviderOrderInput!) {
      admin_toggleIdentityProviderOrder(input: $input) {
        tenant {
          id

          tenant {
            ...IdpOrderToggle_tenant
          }
        }
      }
    }
  `);

  const handleChange = (value: boolean) => {
    setEnabled(value);
    toggleConfirm();
  }

  const handleConfirm = () => {
    return executePromise({
      input: {
        tenantId: tenant.id,
        identityProvider: props.idp as IdpOrderToggleMutation["variables"]["input"]["identityProvider"],
        enabled,
        note: note?.length ? note : null
      }
    }).then(() => {
      toggleConfirm()
    }).catch(() => {});
  }

  return (
    <React.Fragment>
      <StandaloneSwitch
        label={`${props.idp} orders enabled`}
        value={tenant.features[props.idp + '_order_form' as keyof IdpOrderToggle_tenant$data["features"]]}
        onChange={handleChange}
      />

      <ConfirmModal
        open={showConfirm}
        onCancel={() => toggleConfirm()}
        onConfirm={handleConfirm}
        confirmText={`${enabled ? 'Enable' : 'Disable'} order form for ${props.idp}`}
        confirmVariant="primary"
        data-test-id="confirm-modal"
      >
        <div>
          <h2>{`${enabled ? 'Enable' : 'Disable'} order form for ${props.idp}`}</h2>
          <p>Are you sure you want to {enabled ? 'enable' : 'disable'} the order form for {props.idp}?</p>
        </div>

        <Textarea className='mt-[15px] !h-[100px] resize-none' name='note-text' label='Note:' value={note} onChange={event => setNote(event.target.value)}/>

        {state.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
        )}

      </ConfirmModal>
    </React.Fragment>
  );
}