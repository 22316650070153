import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta, FetchArgs } from '@reduxjs/toolkit/query/react';
import { singleton as config } from '@app/config';
import { Environment } from '@app/models';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { RootState } from '@app/redux/store';
import { TENANT_ID_PREFIX } from '@app/constants';

type FetchResult<T> = MaybePromise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>>

const baseQuery = fetchBaseQuery({
  baseUrl: config.subscriptionBase
});

// , 'criipto-signature'
export const TRANSACTION_TYPES = ['authentication', 'signature'] as const;
export type TRANSACTION_TYPE = typeof TRANSACTION_TYPES[number];
export const BUCKETS = ["HOUR", "DAY", "WEEK", "MONTH"] as const;
export type BUCKET = typeof BUCKETS[number];
export const DIMENSIONS = ["authenticationType", "domain"] as const;
export type DIMENSION = typeof DIMENSIONS[number];

type SeriesRequest = {
  tenantId: string
  timezone: string
  bucket: BUCKET
  from: Date
  to: Date
  transactionType: TRANSACTION_TYPE[]
  environment: Environment[]
  dataDimension: DIMENSION
  domain?: string[]
}

export type SeriesResponse = {
  bucket: string,
  total: number,
  dimension: {
    [key in DIMENSION]: {
      [key: string]: number
    }
  }
}[];

export type DimensionData = {
  [key in DIMENSION]: string[]
}

export type DimensionsResponse = DimensionData & {
  transactionType: TRANSACTION_TYPE[]
  from: string
  to: string
}
type DimensionsRequest = {
  tenantId: string
  timezone: string
  transactionType: TRANSACTION_TYPE[]
  environment: Environment[]
}

type InvoicesResponse = {
  hasMore: boolean
  invoices: {
    amountDue: number
    amountPaid: number
    amountRemaining: number
    currency: string
    dueDate: string
    endingBalance: number
    hostedInvoiceUrl: string
    id: string
    invoicePdf: string
    paid: boolean
    paidAt: string
    periodEnd: string
    periodStart: string
    status: string
  }[]
}

export type CustomerRendition = {
  city?: string | null
  company?: string | null
  country?: string | null
  name?: string | null
  email?: string | null
  street?: string | null
  taxId?: string | null
  zip?: string | null
}

export type Currency = "dkk" | "eur";
export type Period = "month" | "year";
export type CustomerResponse = CustomerRendition & {
  cardBrand?: string
  cardLast4?: string
  currency?: Currency
  id: string
  nameOnCard?: string
  period?: Period
  plan?: string
  subscription?: {
    cancelAtPeriodEnd: boolean
    canceledAt: string
    // https://stripe.com/docs/api/subscriptions/object?lang=dotnet#subscription_object-status
    status: "active" | "past_due" | "unpaid" | "canceled" | "incomplete" | "incomplete_expired" | "trialing"
    periodStartAt: string
    periodEndAt: string
    paymentIntentSecret?: string
    paymentIntentStatus?: string
  }
}
type CustomerRequest = CustomerRendition & {

}

export type SubscriptionRequest = CustomerRequest & {paymentToken?: string} &  {
  currency: Currency
  period: Period
  plan: string
}

type CustomPlanRendition = {
  id: string
  type: 'free' | 'invoicing'
  name: string
}
type StripePlanRendition = {
  price: number
  stripePlan: string
  unitprice: number
}
export type PricedPlanRendition = {
  id: string
  name: string
  volume: number
  active?: boolean
  product?: 'verify' | 'signatures'
} & {
  [key in Period]: {
    [key in Currency]: StripePlanRendition
  }
}
export type PlanRendition = CustomPlanRendition | PricedPlanRendition;

export type UpdateCardResponse = {
  success: boolean
  paymentIntentSecret?: string
  paymentIntentStatus?: string
}

export function toISOLocal(d: Date) {
  var z  = (n: number) => ('0' + n).slice(-2);
  var zz = (n: number) =>('00' + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off < 0? '+' : '-';
  off = Math.abs(off);

  return d.getFullYear() + '-'
         + z(d.getMonth()+1) + '-' +
         z(d.getDate()) + 'T' +
         z(d.getHours()) + ':'  +
         z(d.getMinutes()) + ':' +
         z(d.getSeconds()) + '.' +
         zz(d.getMilliseconds()) +
         sign + z(off/60|0) + ':' + z(off%60);
}


export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery,
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    analyticsTotals: builder.query<{uniqueUsersAllTime: number}, string>({
      async queryFn(tenantId, queryApi, options, fetch) {
        const accessToken : string = (queryApi.getState() as RootState).auth.access_token!;

        return await fetch({
          url: `/reporting/${tenantId.replace(TENANT_ID_PREFIX, '')}/events/totals`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<{uniqueUsersAllTime: number}>;
      }
    }),
    analyticsSeries: builder.query<SeriesResponse, SeriesRequest>({
      async queryFn(request, queryApi, options, fetch) {
        const accessToken : string = (queryApi.getState() as RootState).auth.access_token!;

        const query = new URLSearchParams({
          timezone: request.timezone,
          bucket: request.bucket,
          from: toISOLocal(request.from),
          to: toISOLocal(request.to),
          dataDimension: request.dataDimension
        });

        if (request.environment) {
          request.environment.forEach((value, index) => {
            query.append(`environment[${index}]`, value);
          });
        }
        if (request.transactionType) {
          request.transactionType.forEach((value, index) => {
            query.append(`transactionType[${index}]`, value);
          });
        }

        if (request.domain) {
          request.domain.forEach((value, index) => {
            query.append(`domain[${index}]`, value);
          });
        }

        let url = `/reporting/${request.tenantId.replace(TENANT_ID_PREFIX, '')}/events/series?`+query;
        return await fetch({
          url: url,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<SeriesResponse>;
      }
    }),
    analyticsDimensions: builder.query<DimensionsResponse, DimensionsRequest>({
      async queryFn(request, queryApi, options, fetch) {
        const accessToken : string = (queryApi.getState() as RootState).auth.access_token!;

        let url = `/reporting/${request.tenantId.replace(TENANT_ID_PREFIX, '')}/events/dimensions?timezone=${request.timezone}`;

        if (request.environment) {
          request.environment.forEach((value, index) => {
            url += `&environment[${index}]=${value}`
          });
        }
        if (request.transactionType) {
          request.transactionType.forEach((value, index) => {
            url += `&transactionType[${index}]=${value}`
          });
        }

        return await fetch({
          url,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<DimensionsResponse>;
      }
    })
  })
});

export const {
  useAnalyticsTotalsQuery,
  useAnalyticsDimensionsQuery,
  useAnalyticsSeriesQuery
} = subscriptionApi;
