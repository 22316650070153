import React, {useState} from 'react';
import _ from 'lodash';
import graphql from 'babel-plugin-relay/macro';

import { Application, Domain, isSignaturesTechnology, technologyTags } from '@app/models';

import {Form, FormError, FormSuccess, Switch} from '@app/components/Form';
import InputField from '@app/components/FormFields/InputField/InputField';
import ApplicationNameField from '../ApplicationEditor/ApplicationNameField';
import DomainField, { RelayDomain } from '../ApplicationEditor/DomainField';
import useMutation from '@app/hooks/useMutation';
import ApiKeyModal from '../ApplicationEditor/SignaturesApplicationEditor/ApiKeyModal/ApiKeyModal';
import Button from '@app/components/Button/Button';
import useEnvironment from '@app/hooks/useEnvironment';
import { IdentityProvidersSection } from '../ApplicationEditor/Sections/IdentityProviders';
import { ApplicationScopeStrategy, SignaturesApplicationCreatorMutation } from './__generated__/SignaturesApplicationCreatorMutation.graphql';
import { translate } from '@app/i18n';
import ApplicationRealmValidation from '../ApplicationEditor/ApplicationRealmValidation';
import TechnologySelector, { TechnologyChoice } from '../TechnologySelector/TechnologySelector';
import useTracking from '@app/hooks/useTracking';
import { useHistory } from 'react-router-dom';
import { ConnectionHandler, useFragment } from 'react-relay';
import { SignaturesApplicationCreator_tenant$key } from './__generated__/SignaturesApplicationCreator_tenant.graphql';

interface Props {
  tenant: SignaturesApplicationCreator_tenant$key
  parentUrl: string
}

type Values = {
  name: string
  realm: string
  authMethods: string[]
  scopeStrategy: ApplicationScopeStrategy
}


export default function SignaturesApplicationCreator(props: Props) {
  const history = useHistory();
  const tracking = useTracking();
  const [domain, setDomain] = useState<RelayDomain | undefined>(undefined);
  const [application, setApplication] = useState<{domain: {name: string}, realm: string, name: string, id: string} | null>(null);
  const environment = useEnvironment();
  const [isRealmValid, setRealmValid] = useState(true);
  const [technology, setTechnology] = useState<TechnologyChoice | null>(null);

  const tenant = useFragment(
    graphql`
      fragment SignaturesApplicationCreator_tenant on Tenant @argumentDefinitions(
        environment: {type: "Environment"}
      ) {
        ... DomainField_tenant @arguments(environment: $environment)
        features {
          FRENCH_PUBLIK
          DKNEMID_ENABLED
        }
      }
    `,
    props.tenant
  );

  const [mutationExecutor, mutationState] = useMutation<SignaturesApplicationCreatorMutation>(graphql`
    mutation SignaturesApplicationCreatorMutation(
      $connections: [ID!]!
      $input: CreateApplicationInput!
    ) {
      createApplication(input: $input) {
        ... on CreateSignaturesApplicationOutput {
          applicationEdge @appendEdge(connections: $connections) {
            cursor
            node {
              id
              __typename
              name
              realm

              domain {
                name
              }

              ... GeneralSection_application
              ... VerifyApplicationIntegrate_application

              ... on SignaturesApplication {
                signatures {
                  ... ApiKeys_application
                }
              }
            }
          }
          application {
            id
            domain {
              name
            }
            name
            realm

            ... GeneralSection_application
            ... VerifyApplicationIntegrate_application

            ... on SignaturesApplication {
              signatures {
                ... ApiKeys_application
              }
            }
          }

          apiKey {
            ... ApiKeyModal_apiKey
          }
        }
      }
    }
  `);

  const initialValues : Values = {
    name: '',
    realm: `urn:my:application:identifier:${_.random(1000, 999999)}`,
    authMethods: [],
    scopeStrategy: 'DYNAMIC'
  };

  const isFormValid = isRealmValid && domain;
  const handleSubmit = async (values : Values) => {
    if (!isFormValid) return Promise.reject(new Error('Invalid form fields'));

    const connections = [
      ConnectionHandler.getConnectionID(
        domain.id,
        'domain_applications'
      ),
      ConnectionHandler.getConnectionID(
        domain.id,
        'domain_applications',
        null
      )
    ];

    const result = await mutationExecutor.executePromise({
      input: {
        domainId: domain.id,
        name: values.name,
        realm: values.realm,
        product: 'Signatures',
        technology,
        scopeStrategy: values.scopeStrategy
      },
      connections
    });

    setApplication(result.createApplication.application!);
    setShowApiKeySecret(true);
  }

  const [showApiKeySecret, setShowApiKeySecret] = useState(false);
  const handleApiKeySecretHide = () => {
    setShowApiKeySecret(false);
    history.push(props.parentUrl + `/${application!.id}/integrate`);
  }

  return (
    <React.Fragment>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        data-test-id="form"
        key="application_creater"
        className="max-w-[750px] flex flex-col gap-[8px] form-gap"
      >
        {({isPending, error, isSuccess, values, setFieldValue}) => (
          <React.Fragment>
            <ApplicationNameField />

            <DomainField tenant={tenant} domain={domain} onChange={setDomain} data-test-id="domain" />

            <InputField<Application>
              type="text"
              label={translate('LABEL_CLIENT_ID')}
              name="realm"
              placeholder={translate('LABEL_CLIENT_ID')}
              iconRight={!(isSuccess || isPending) && domain && (
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse" />}>
                  <ApplicationRealmValidation domainId={domain.id} realm={values.realm} onValid={setRealmValid} />
                </React.Suspense>
              )}
              error={!isRealmValid && domain &&
                <React.Fragment>
                  {translate('REALM_EXISTS')}
                </React.Fragment>
              }
            />

            <Switch
              name="scopeStrategy"
              label={translate('LABEL_APPLICATION_SCOPESTRATEGY')}
              off="STATIC" on="DYNAMIC"
              help={(
                <small className="help-text">
                  Dynamic scopes let you define the data you need per request.&nbsp;
                  <a href="https://docs.criipto.com/verify/getting-started/oidc-intro/#the-scope-parameter" target="_blank">Read more in our documentation</a>.<br />
                  Default for new applications was changed from static to dynamic in June 2024.
                </small>
              )}
            />

            <div>
              <IdentityProvidersSection
                tenant={tenant}
                isSignatures={true}
                help={(
                  <p className='!mt-[20px]'>
                    These e-IDs define the authentication choices a user can make when signing a document.
                  </p>
                )}
                acrValues={values.authMethods}
                setAcrValues={acrValues => setFieldValue('authMethods', acrValues)}
              />
            </div>

            <div className="form-group">
              <label className="control-label"><strong>Which technology are you using for your project?</strong></label>
              <small className="help-text">Selecting a technology will help us guide you to the right tutorials and documentation.</small>
            </div>
            <TechnologySelector
              technology={technology}
              onChange={setTechnology}
              technologies={technologyTags.filter(isSignaturesTechnology)}
            />

            <p>
              After your signatures application is created your client credentials will be shown on the screen.
              Make sure to store them securely as they will be required to integrate your application.
            </p>

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'Application created'} />
            )}

            <div>
              <Button variant="primary" type="submit" working={isPending}>Create signature application</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
      <ApiKeyModal
        open={showApiKeySecret}
        onHide={handleApiKeySecretHide}
        apiKey={mutationState.response?.createApplication!.apiKey!}
        data-test-id="api-key-modal"
      />
    </React.Fragment>
  );
}

function assertUnreachable(_value: never): never {
  throw new Error("Statement should be unreachable");
}