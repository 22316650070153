import React from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import useMutation from '@app/hooks/useMutation';

import { Form, FormError, FormSuccess } from '@app/components/Form/Form';
import CertificateForm from '@app/components/CertificateForm/CertificateForm';
import { translate } from '@app/i18n';
import Button from '@app/components/Button/Button';
import useTracking from '@app/hooks/useTracking';
import { DomainCertificateUploadForm_domain$key } from './__generated__/DomainCertificateUploadForm_domain.graphql';
import { DomainCertificateUploadFormMutation } from './__generated__/DomainCertificateUploadFormMutation.graphql';
import { fileToBase64 } from '@app/helpers';

interface Props {
  onSave?: () => void,
  domain: DomainCertificateUploadForm_domain$key
}

type Values = {
  certificate?: {
    file: File,
    password: string
  }
}

export default function DomainCertificateUploadForm(props: Props) {
  const {onSave} = props;
  const tracking = useTracking();

  const domain = useFragment(graphql`
    fragment DomainCertificateUploadForm_domain on Domain {
      id
      name
      environment

      tenant {
        longTenantId
      }
    }
  `, props.domain);

  const [executor, state] = useMutation<DomainCertificateUploadFormMutation>(graphql`
    mutation DomainCertificateUploadFormMutation($input: UploadDomainCertificateInput!) {
      uploadDomainCertificate(input: $input) {
        ... on UploadDomainCertificateOutput {
          domain {
            certificate {
              type
              isExpired
              isValid

              issuedAt
              expiresAt
              issuer
              subject
            }
          }
        }
      }
    }
  `);

  const handleSubmit = async (values: Values) => {
    if (!values.certificate) return;
    const certificate = await fileToBase64(values.certificate.file!);
    await executor.executePromise({
      input: {
        domainId: domain.id,
        certificate,
        password: values.certificate.password
      }
    });

    tracking.track(
      'DOMAIN_SSL_CERT_ADDED',
      {
        "Production": domain.environment === 'PRODUCTION',
        "Domain": domain.name,
        tenantId: domain.tenant.longTenantId
      }
    );

    if (onSave) onSave();
  };

  return (
    <Form initialValues={{}} onSubmit={handleSubmit} data-test-id="form" key="domain_certificate_form">
      {({isPending, error, isSuccess, values}) => (
        <React.Fragment>
          <CertificateForm
            certificate={null}
            fileFieldName="certificate.file"
            passwordFieldName="certificate.password"
          />

          {error && (
            <FormError error={error} />
          )}

          {isSuccess && (
            <FormSuccess message={'Domain SSL certificate uploaded'} />
          )}

          <p className='mt-[15px] text-light-blue-700'>
            NOTE: It may take several minutes before your new certificate will take effect.
          </p>

          <div className="button-group flex-end" style={{marginTop: '15px'}}>
            <Button variant="primary" type="submit" working={isPending} autoWidth>
              {translate('SAVE')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}