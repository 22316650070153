import React, { useReducer } from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import Button from "@app/components/Button";
import { DeleteInvitationButton_invitation$key } from './__generated__/DeleteInvitationButton_invitation.graphql';
import useMutation from '@app/hooks/useMutation';
import { ConfirmModal } from '@app/components/Modal/Modal';
import { DeleteInvitationButton_Mutation } from './__generated__/DeleteInvitationButton_Mutation.graphql';
import { getRelayInvitationsConnections } from '@app/screens/DomainsScreen/utils';
import { DeleteInvitationButton_tenant$key } from './__generated__/DeleteInvitationButton_tenant.graphql';
import useToast from '@app/hooks/useToast';
import Alert from '@app/components/Alert/Alert';

interface Props {
  tenant: DeleteInvitationButton_tenant$key
  invitation: DeleteInvitationButton_invitation$key
}
export default function DeleteInvitationButton(props: Props) {
  const toast = useToast();
  const tenant = useFragment(
    graphql`
      fragment DeleteInvitationButton_tenant on Tenant {
        id
      }
    `,
    props.tenant
  );

  const invitation = useFragment(
    graphql`
      fragment DeleteInvitationButton_invitation on Invitation {
        id
        name
      }
    `,
    props.invitation
  );

  const [showConfirm, toggleConfirm] = useReducer(value => !value, false);
  const handleClick = () => {
    toggleConfirm();
  }

  const [executor, state] = useMutation<DeleteInvitationButton_Mutation>(graphql`
    mutation DeleteInvitationButton_Mutation(
      $connections: [ID!]!
      $input: DeleteInvitationInput!
    ) {
      deleteInvitation(input: $input) {
        deletedInvitationID @deleteEdge(connections: $connections)
      }
    }
  `);
  const handleConfirm = async () => {
    await executor.executePromise({
      input: {
        id: invitation.id
      },
      connections: getRelayInvitationsConnections(tenant.id)
    })
    toast.success({title: 'Invitation removed'});
  };

  return (
    <React.Fragment>
      <Button variant="danger" className="button-small" tooltip={'Remove invited user'} autoWidth onClick={handleClick}>
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        open={showConfirm}
        onCancel={toggleConfirm}
        onConfirm={handleConfirm}
        confirmText="Remove invited user"
        confirmVariant="danger"
      >
        <div>
          <h2>Remove invited user</h2>
          <p className='!m-0'>Are you sure you want to remove this invited user {invitation.name}?</p>
        </div>

        {state.error ? (
          <Alert variant="error" className="mt-[15px]" title="There was an error removing the invitation" message={state.error.message} />
        ) : null}
      </ConfirmModal>
    </React.Fragment>
  )
}