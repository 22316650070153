import React, { useEffect, useReducer, useState } from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {translate} from '@app/i18n';
import InputField from '@app/components/FormFields/InputField/InputField';
import Select from '@app/components/FormFields/Select/Select';
import {StandaloneSwitch} from '@app/components/Form';
import { useField } from 'formik';
import { UISection_application$key, ViewVersion } from './__generated__/UISection_application.graphql';
import { UISection_tenant$data, UISection_tenant$key } from './__generated__/UISection_tenant.graphql';
import { ApplicationRelayForm } from '../../ApplicationForm/ApplicationForm';
import useMutation from '@app/hooks/useMutation';
import { UISectionMutation } from './__generated__/UISectionMutation.graphql';

type Props = {
  application: UISection_application$key,
  tenant: UISection_tenant$key
}

type Styling = UISection_tenant$data["styling"];

type Values = {
  cssClass: string | null
  stylesheet: string | null
  viewVersion: ViewVersion | null
}

export default function UISection(props: Props) {
  const application = useFragment(graphql`
    fragment UISection_application on VerifyApplication {
      id
      ui {
        stylesheet
        cssClass
        viewVersion
      }
    }
  `, props.application);

  const tenant = useFragment(graphql`
    fragment UISection_tenant on Tenant @argumentDefinitions(
      environment: {type: "Environment!"}
    ) {
      styling(environment: $environment) {
        cssUrl
        viewVersion {
          version
          editable
        }
      }
    }
  `, props.tenant);

  const [mutationExecutor, mutationState] = useMutation<UISectionMutation>(graphql`
    mutation UISectionMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          ... UISection_application
        }
      }
    }
  `);

  const initialValues : Values = {
    cssClass: application.ui.cssClass ?? '',
    stylesheet: application.ui.stylesheet,
    viewVersion: application.ui.viewVersion
  };

  return (
    <ApplicationRelayForm
      formSuccessMessage="UI settings updated"
      values={initialValues}
      onSubmit={async values => {
        await mutationExecutor.executePromise({
          input: {
            applicationId: application.id,
            ui: values
          }
        })
      }}
      valid={true}
    >

    <section className='flex flex-col gap-[25px]'>

      <ViewVersionField styling={tenant.styling} />

      <StylesheetField styling={tenant.styling} />

      <InputField<Values>
        type="text"
        label={translate('LABEL_APPLICATION_CSS_CLASS')}
        name="cssClass"
        placeholder="Custom CSS class"
        helpText={translate('INFO_CSS_CLASS')
        }
      />

    </section>
    </ApplicationRelayForm>
  )
}

export function ViewVersionField(props: {styling: Styling}) {
  const [{value}, _, {setValue}] = useField<Values["viewVersion"]>('viewVersion');
  const styling = props.styling;

  const [original] = useState(() => value);
  const [useDefault, toggleDefault] = useReducer(value => !value, !value);

  useEffect(() => {
    if (useDefault) setValue(null);
    else if (original) setValue(original);
  }, [useDefault, original]);

  if (!styling) return null;

  let viewVersions : NonNullable<Values["viewVersion"]>[] = ['UNIFIED'];
  if (styling.viewVersion.editable) {
    const initial : NonNullable<Values["viewVersion"]>[] = ["INITIAL"];
    viewVersions = initial.concat(viewVersions);
  }

  if (viewVersions.length <= 1) return null;

  return (
    <div>
      <label className="form-group-label">View version</label>
      <StandaloneSwitch
        label="Use default"
        value={useDefault}
        onChange={() => toggleDefault()}
      />
      <Select
        name="version"
        label='View version'
        disabled={useDefault}
        onChange={((event) => setValue(event.target.value as Styling["viewVersion"]["version"]))}>
        {viewVersions.map(viewVersion => (
          <option value={viewVersion} key={viewVersion}>
            {viewVersion === 'INITIAL' ? 'Legacy' : 'Unified'}
          </option>
        ))}
      </Select>
    </div>
  )
}

export function StylesheetField(props: {styling: Styling}) {
  const [{value}, _, {setValue}] = useField<Values["stylesheet"]>('stylesheet');
  const [original] = useState(() => value);
  const [useDefault, toggleDefault] = useReducer(value => !value, !value);
  const styling = props.styling;

  useEffect(() => {
    if (useDefault) setValue(null);
    else if (original) setValue(original);
  }, [useDefault, original]);

  return (
    <div>
      <label className="form-group-label">Stylesheet</label>
      <StandaloneSwitch
        label="Use default"
        value={useDefault}
        onChange={() => toggleDefault()}
      />
      <InputField
        name="use_stylesheet"
        type="text"
        disabled={useDefault}
        value={(useDefault ? styling?.cssUrl : (value || '')) ?? ''}
        onChange={((event) => setValue(event.target.value))}
        helpText='Adds a custom CSS stylesheet to the login/signing screen'
      />
    </div>
  );
}