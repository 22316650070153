import {Variables, GraphQLResponse} from 'relay-runtime';
import { AuthState } from './redux/authSlice';

export class AuthenticationError extends Error {
  constructor(m: string) {
      super(m);
      Object.setPrototypeOf(this, AuthenticationError.prototype);
  }
}

interface NetlifyResponse {
  errorMessage: string
}

async function fetchGraphQL(text : string, variables : Variables, auth: AuthState) {
  const response = await fetch(`${process.env.FUNCTIONS_HOST || ''}/.netlify/functions/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth.access_token}, id_token ${auth.id_token}`
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  if (response.status === 401) {
    throw new AuthenticationError('Unauthenticated, please refresh your browser.');
  }

  // Get the response as JSON
  const payload = await response.json() as (GraphQLResponse | NetlifyResponse);

  if ("errorMessage" in payload) {
    throw new Error(payload.errorMessage);
  }

  if ("errors" in payload && payload.errors) {
    throw new Error(payload.errors[0].message);
  }
  return payload;
}

export default fetchGraphQL;