import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DomainApplicationsQuery } from './__generated__/DomainApplicationsQuery.graphql';
import { Link } from 'react-router-dom';
import { Tag } from '@app/components/Tag/Tag';
import DeleteApplicationButton from '@app/screens/ApplicationsScreen/components/DeleteApplicationButton/DeleteApplicationButton';

interface Props {
  domain: string
  tenantId: string
}

type Domain = NonNullable<DomainApplicationsQuery["response"]["domain"]>;

export default function DomainApplications(props : Props) {
  const data = useLazyLoadQuery<DomainApplicationsQuery>(graphql`
    query DomainApplicationsQuery(
      $id: ID!
    ) {
      domain(id: $id) {
        applications(first: 1000) @connection(key: "domain_applications") {
          edges {
            node {
              __typename
              id
              name
              realm

              domain {
                name
              }

              ...DeleteApplicationButton_application
            }
          }
        }
      }
    }
  `, {
    id: btoa(`domain:${props.tenantId}|${props.domain}`)
  });
  if (!data.domain) return null;
  const domain = data.domain;

  return (
    <div className="accordion">
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-5">
            <h3>Name</h3>
          </div>
          <div className="col-xs-5">
            <h3>Realm</h3>
          </div>
          <div className="col-xs-2">
          </div>
        </div>
      </div>
      {domain.applications.edges.map(edge => (
        <div className="accordion-item" key={edge.node.id}>
          <Link to={`/tenant/${props.tenantId}/applications/${edge.node.id}`} className="accordion-header clickable">
            <div className="row">
              <div className="col-xs-5">
                <strong>{edge.node.name}</strong>
                {edge.node.__typename === 'SignaturesApplication' && (
                  <Tag className="tag-small" style={{marginLeft: '5px'}}>Signatures</Tag>
                )}
              </div>
              <div className="col-xs-5">
                {edge.node.realm}
              </div>
              <div className="col-xs-2" onClick={(e) => e.stopPropagation() /* Fix react-portal click propagation */}>
                <div className="button-group flex-end">
                  <DeleteApplicationButton application={edge.node} className="button-small" />
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}