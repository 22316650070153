import React, { useReducer, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import {singleton as config} from '@app/config';
import { translate } from '@app/i18n';
import Button from '@components/Button';
import {FormError, FormSuccess} from '@components/Form';
import useMutation from '@app/hooks/useMutation';
import { SettingsQuery } from './__generated__/SettingsQuery.graphql';

import './Settings.scss';
import { SettingsProvisionManagedCertificateMutation } from './__generated__/SettingsProvisionManagedCertificateMutation.graphql';
import DomainCertificateUploadModal from '../../DomainCertificateUploadModal/DomainCertificateUploadModal';

interface Props {
  domain: string
  tenantId: string
}

export default function DomainSettingsSection(props : Props) {
  const [fetchKey, setFetchKey] = useState<string | undefined>(undefined);
  const [showUploadModal, toggleUploadModal] = useReducer(value => !value, false);

  const data = useLazyLoadQuery<SettingsQuery>(graphql`
    query SettingsQuery($id: ID!) {
      domain(id: $id) {
        id
        name
        type

        dns {
          cname {
            actual
            expected
            matches
          }
          txt {
            actual
            expected
            matches
          }
        }

        certificate {
          type
          isExpired
          isValid

          issuedAt
          expiresAt
          issuer
          subject
        }

        ... DomainCertificateUploadModal_domain
      }
    }
  `, {
    id: btoa(`domain:${props.tenantId}|${props.domain}`)
  }, {
    fetchKey,
    fetchPolicy: 'network-only'
  });

  const [provisionManagedExecutor, provisionManagedState] = useMutation<SettingsProvisionManagedCertificateMutation>(graphql`
    mutation SettingsProvisionManagedCertificateMutation($input: ProvisionManagedCertificateInput!) {
      provisionManagedCertificate(input: $input) {
        __typename
        ... on ProvisionManagedCertificateOutput {
          domain {
            certificate {
              type
              isExpired
              isValid

              issuedAt
              expiresAt
              issuer
              subject
            }
          }
        }
        ... on TimeoutError {
          message
        }
      }
    }
  `);

  if (!data.domain) return null;
  const domain = data.domain;

  const refetch = () => {
    setFetchKey(Math.random().toString());
  };

  const txtMatches = domain.type === 'CRIIPTO' || domain.dns.txt.matches || !domain.dns.txt.actual;
  const dnsCorrect = domain.type === 'CRIIPTO' || (domain.dns.cname.matches && txtMatches);

  return (
    <div className="domain-settings-tab">
      <div className="form-section">
        <div className="form-fields">
          <div className="form-group">
            <label className="control-label">
              {translate('LABEL_DOMAIN')}
              &nbsp;
              {dnsCorrect ? (
                <i className="fa fa-check lookup-checkmark" />
              ) : (
                <i className="fa fa-times lookup-cross" />
              )}
            </label>
            <input type="text" className="form-control" readOnly value={domain.name} disabled />
            {!dnsCorrect ? (
              <React.Fragment>
                <div className="help-block">
                  <i className="fa fa-exclamation-triangle"></i> DNS is not configured correctly.
                </div>
                {!domain.dns.cname.matches ? (
                  <p className="help-block">
                    To make your domain work you must configure a <strong>DNS CNAME record</strong> to point from your domain ({domain.name}) to <strong>idp.{config.easyIdDns}</strong>
                  </p>
                ) : null}
                {!txtMatches ? (
                  <p className="help-block">
                    You appear to have an unexpected or malformed <strong>TXT record</strong>, please contact support.
                  </p>
                ) : null}
                <Button variant="primary" onClick={() => refetch()}>{translate('VERIFY_DOMAIN')}</Button>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className="info">
          {domain.type === 'CUSTOM' ? (
            <React.Fragment>
              <p>
                <span>{translate('INFO_NEW_DOMAIN')}</span>&nbsp;<strong>idp.{config.easyIdDns}</strong>
              </p>

              <p>
                <code>{domain.name} CNAME idp.{config.easyIdDns}.</code>
              </p>
            </React.Fragment>
          ) : null}
        </div>
      </div>

      {domain.type === 'CUSTOM' ? (
        <React.Fragment>
          <h3>HTTPS/SSL</h3>
          <div className="form-section">
            <div className="form-fields">
              <div style={{marginBottom: '25px'}}>
                {domain.certificate?.isValid ? (
                  <span>
                    <i className="fa fa-check" />&nbsp;
                    HTTPS is enabled for domain.
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-times" />&nbsp;
                    HTTPS is <strong>not</strong> enabled for domain.
                  </span>
                )}
              </div>

              {domain.certificate && domain.certificate.type !== 'AZURE' ? (
                <div className="mb-[25px]">
                  <div className="form-group">
                    <label className="control-label">Type</label>
                    <div style={{marginBottom: '10px'}}>{domain.certificate.type === 'MANAGED' ? 'Managed certificate' : 'Uploaded certificate'}</div>
                  </div>
                  <div className="form-group">
                    <label className="control-label">Issuer</label>
                    <pre className="whitespace-pre-wrap text-lg">{domain.certificate.issuer}</pre>
                  </div>
                  <div className="form-group">
                    <label className="control-label">Subject</label>
                    <pre className="whitespace-pre-wrap text-lg">{domain.certificate.subject}</pre>
                  </div>
                  <div className="form-group">
                    <div className="flex align-items-center gap-[20px]">
                      <div style={{width: '100%'}}>
                        <label className="control-label">Created At</label>
                        <pre className="whitespace-pre-wrap text-lg">{new Date(domain.certificate.issuedAt).toLocaleString()}</pre>
                      </div>
                      <div style={{width: '100%'}}>
                        <label className="control-label">Expires At</label>
                        <pre className="whitespace-pre-wrap text-lg">{new Date(domain.certificate.expiresAt).toLocaleString()}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <DomainCertificateUploadModal open={showUploadModal} domain={domain} onHide={toggleUploadModal} />
              <div className="flex" style={{marginBottom: '15px', gap: '10px'}}>
                <Button variant="default" onClick={toggleUploadModal}>Upload SSL certificate</Button>
                <Button variant="default" onClick={() => provisionManagedExecutor.execute({input: {domainId: domain.id}})} working={provisionManagedState.pending}>Provision managed certificate</Button>
              </div>

              {provisionManagedState.error && (
                <FormError error={provisionManagedState.error.message} />
              )}

              {provisionManagedState.success && (
                <FormSuccess message={'Managed SSL certificate provisioned'} />
              )}
            </div>
            <div className="info">
              <p><span>{translate('INFO_MANAGED_CERTIFICATE')}</span></p>
              <p><strong>{translate('INFO_MANAGED_CERTIFICATE_DELAY_SHORT')}</strong></p>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
