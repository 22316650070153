import React from 'react';
import {Switch, Route, Link, useRouteMatch, useParams} from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { translate } from '@app/i18n';

import {BEeIDProvider} from './components/BEeIDProvider';
import {DESofortProvider} from './components/DESofortProvider';
import {DKMitIDProvider} from './components/DKMitIDProvider';
import {SEBankIDProvider} from './components/SEBankIDProvider';
import {NOBankIDProvider} from './components/NOBankIDProvider';
import {NOBankIDOldProvider} from './components/NOBankIDOldProvider';
import {NOVippsProvider} from './components/NOVippsProvider';
import {NLDigIDProvider} from './components/NLDigIDProvider';
import {ItsmeProvider} from './components/ItsmeProvider';
import {FITeliaProvider} from './components/FITeliaProvider';
import {NLiDINEvidosProvider} from './components/NLiDINEvidosProvider/NLiDINEvidosProvider';

import './ProvidersScreen.scss';
import useEnvironment from '@app/hooks/useEnvironment';
import { useTenantId } from '@app/hooks/useTenant';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { FRToulouseProvider } from './components/FRToulouseProvider/FRToulouseProvider';
import { ProvidersScreenQuery } from './__generated__/ProvidersScreenQuery.graphql';
import { ProvidersScreen_ProviderScreenQuery } from './__generated__/ProvidersScreen_ProviderScreenQuery.graphql';
import Screen from '@app/components/Screen/Screen';
import IdentityProvidersIcon from '@app/components/Icons/IdentityProvidersIcon';
import styles from './ProvidersScreen.module.css';

export function ProvidersScreen() {
  const match = useRouteMatch();
  const environment = useEnvironment();
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<ProvidersScreenQuery>(
    graphql`
      query ProvidersScreenQuery($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) @required(action: THROW) {
          id

          identityProviders(environment: $environment) {
            id
            shortName
            configurationRequired
            isConfigured
          }
        }
      }
    `
  , {
    tenantId: tenantId.relayId,
    environment
  });

  const checkmark = (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 32C11.25 32 6 29 3.125 24C0.25 19.0625 0.25 13 3.125 8C6 3.0625 11.25 0 17 0C22.6875 0 27.9375 3.0625 30.8125 8C33.6875 13 33.6875 19.0625 30.8125 24C27.9375 29 22.6875 32 17 32ZM24.0625 13.0625H24C24.625 12.5 24.625 11.5625 24 10.9375C23.4375 10.375 22.5 10.375 21.9375 10.9375L15 17.9375L12.0625 15C11.4375 14.375 10.5 14.375 9.9375 15C9.3125 15.5625 9.3125 16.5 9.9375 17.0625L13.9375 21.0625C14.5 21.6875 15.4375 21.6875 16.0625 21.0625L24.0625 13.0625Z" fill="#604FED"/>
    </svg>
  );

  return (
    <div>
      <Screen.Header fullscreen={true}>
        <Screen.Header.Title>
          <h1><IdentityProvidersIcon /> Identity providers</h1>
        </Screen.Header.Title>
      </Screen.Header>

      <div className="p-[32px] grow">
        <div className={styles.container}>
          {data.tenant.identityProviders.map(provider => (
            <Link key={provider.id} to={`${match.url}/${provider.id}`} className={styles.provider}>
              <div className="flex flex-row gap-[16px] items-center">
                {/** logo when we have it */ }
                <h3>
                  {provider.shortName}
                </h3>
              </div>
              <div className={styles.configured}>
                {provider.configurationRequired ? (
                  <>
                    {provider.isConfigured ? (
                      <>
                        {checkmark} Provider is configured
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {checkmark} {translate('HINT_ID_SERVICE_CONFIG_ALL_SET')}. {translate('HINT_ID_SERVICE_CONFIG_NOT_NEEDED')}
                  </>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export function ProviderScreen(props : {parentUrl: string}) {
  const params = useParams<{providerId: string}>();
  const tenantId = useTenantId();
  const environment = useEnvironment();

  const data = useLazyLoadQuery<ProvidersScreen_ProviderScreenQuery>(
    graphql`
      query ProvidersScreen_ProviderScreenQuery($id: ID!) {
        identityProvider(id: $id) @required(action: THROW) {
          __typename
          id
          longName

          ... on NorwegianBankID {
            id
          }
          ... on SwedishBankID {
            __typename
            ... SEBankIDProvider_identityProvider
          }
          ... on DutchIdinEvidos {
            id
          }
          ... on NorwegianVipps {
            id
          }
          ... on ItsME {
            id
          }
          ... on BelgianEID {
            id
          }
          ... on NorwegianBankIDCServer {
            id
          }
          ... on DanishMitID {
            id
          }
          ... on DutchDigID {
            id
          }
          ... on FrenchToulouse {
            id
          }
          ... on FinnishTelia {
            id
          }
          ... on DutchiDINBluem {
            id
          }
          ... on GermanSofort {
            id
          }
        }
      }
    `
  , {
    id: params.providerId
  });

  const provider = data.identityProvider;

  return (
    <div>
      <Screen.Header fullscreen={false}>
        <Screen.Header.Breadcrumbs>
          <Link to={props.parentUrl}>
            <IdentityProvidersIcon /> Identity Providers
          </Link>
          <span>Provider Details</span>
        </Screen.Header.Breadcrumbs>
        <Screen.Header.Title>
          <h1>{provider.longName}</h1>
        </Screen.Header.Title>
      </Screen.Header>
      {/** TODO: remove these two provider classes when all subscreens are converted to new Screen/fields logic */}
      <div className="providers-screen">
        <div className="provider">
          <GraphQLErrorBoundary>
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              {provider.__typename === 'SwedishBankID' ? <SEBankIDProvider identityProvider={provider} /> : null}
              {provider.__typename === 'GermanSofort' ? <DESofortProvider tenant={tenantId} environment={environment}  /> : null}
              {provider.__typename === 'NorwegianBankID' ? <NOBankIDProvider tenant={tenantId} environment={environment} /> : null}
              {provider.__typename === 'DanishMitID' ? <DKMitIDProvider environment={environment} /> : null}
              {provider.__typename === 'BelganEID' ? <BEeIDProvider tenant={tenantId} environment={environment}  /> : null}
              {provider.__typename === 'DutchIdinEvidos' ? <NLiDINEvidosProvider tenant={tenantId} environment={environment} /> : null}
              {provider.__typename === 'ItsME' ? <ItsmeProvider tenant={tenantId} environment={environment} /> : null}
              {provider.__typename === 'NorwegianVipps' ? <NOVippsProvider tenant={tenantId} environment={environment}  /> : null}
              {provider.__typename === 'DutchIdin' ? <NLDigIDProvider tenant={tenantId} environment={environment} /> : null}
              {provider.__typename === 'NorwegianBankIDCServer' ? <NOBankIDOldProvider tenant={tenantId} environment={environment} /> : null}
              {provider.__typename === 'FinnishTelia' ? <FITeliaProvider /> : null}
              {provider.__typename === 'FrenchToulouse' ? <FRToulouseProvider tenant={tenantId} environment={environment} /> : null}
            </React.Suspense>
          </GraphQLErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default function ProviderRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <Screen nav={Screen.tenantNavigation}>
          <ProvidersScreen />
        </Screen>
      </Route>
      <Route path={`${match.path}/:providerId`}>
        <Screen nav={Screen.tenantNavigation} fullscreen={false}>
          <ProviderScreen parentUrl={match.url} />
        </Screen>
      </Route>
    </Switch>
  )
}