import React, { useReducer } from 'react';
import { useFragment } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { RemoveOrganizationTenantButton_tenant$key } from './__generated__/RemoveOrganizationTenantButton_tenant.graphql';
import Button from '@app/components/Button/Button';
import RemoveOrganizationTenantModal from './RemoveOrganizationTenantModal';

export default function RemoveOrganizationTenantButton(props: {
  tenant: RemoveOrganizationTenantButton_tenant$key
}) {
  const tenant = useFragment(
    graphql`
      fragment RemoveOrganizationTenantButton_tenant on AdminTenant {
        id
        organization {
          id
        }
        ... RemoveOrganizationTenantModal_tenant
      }
    `,
    props.tenant
  );

  const [showModal, toggleModal] = useReducer(val => !val, false);
  if (!tenant.organization) return null;

  return (
    <React.Fragment>
      <Button variant="danger" onClick={toggleModal} className="!mt-0">
        Remove from organization
      </Button>
      <RemoveOrganizationTenantModal
        open={showModal}
        onCancel={toggleModal}
        tenant={tenant}
      />
    </React.Fragment>
  );
}