import React, {useCallback} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';

import Button from '@components/Button/Button';
import {Form, Switch} from '@components/Form';
import InputField from '@app/components/FormFields/InputField';

import useEnvironment from '@app/hooks/useEnvironment';
import { NOBankIDProviderQuery, NninConsentStrategy } from './__generated__/NOBankIDProviderQuery.graphql';
import { NOBankIDProviderMutation } from './__generated__/NOBankIDProviderMutation.graphql';
import { TenantId } from '@app/hooks/useTenant';
import ConfigurationSection from '@app/components/Configuration/ConfigurationSection';
import Alert from '@app/components/Alert/Alert';
import ConfigurationFooter from '@app/components/Configuration/ConfigurationFooter';
import { Link } from 'react-router-dom';

interface Props {
  tenant: TenantId,
  environment: Environment
}


export interface FormValues {
  clientId : string
  clientSecret : string,
  requestAddress: boolean,
  requestEmail: boolean,
  requestPhone: boolean,
  requestSocialNo: boolean
  nninConsentStrategy : NninConsentStrategy
  nninConsentDuration : number
}

export function NOBankIDProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<NOBankIDProviderQuery>(
    graphql`
      query NOBankIDProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on NorwegianBankID {
            clientId
            requestAddress
            requestEmail
            requestPhone
            requestSocialNo
            nninConsentStrategy
            nninConsentDuration
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|NO_BANKID`),
    tenantId: tenant.relayId,
    environment: props.environment
  });

  const [{executePromise}] = useMutation<NOBankIDProviderMutation>(graphql`
    mutation NOBankIDProviderMutation(
      $input: UpdateNorwegianBankIDInput!
    ) {
      updateNorwegianBankID(input: $input) {
        id
        isConfigured
        ... on NorwegianBankID {
          clientId
          requestAddress
          requestEmail
          requestPhone
          requestSocialNo
          nninConsentStrategy
          nninConsentDuration
        }
      }
    }
  `);

  if (!data.identityProvider) return null;
  const initialValues : FormValues = {
    clientId: data.identityProvider.clientId ?? '',
    clientSecret: '',
    requestAddress: data.identityProvider.requestAddress ?? false,
    requestEmail: data.identityProvider.requestEmail ?? false,
    requestPhone: data.identityProvider.requestPhone ?? false,
    requestSocialNo: data.identityProvider.requestSocialNo ?? false,
    nninConsentStrategy: data.identityProvider.nninConsentStrategy ?? "EPHEMERAL",
    nninConsentDuration: data.identityProvider.nninConsentDuration ?? 0
  };

  const environment = useEnvironment();
  const domains = data.tenant?.domains.edges.map(edge => edge.node);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess, values, setFieldValue}) => (
        <React.Fragment>
          <ConfigurationSection>
            <ConfigurationSection.TwoColumn>
              <div>
                <h2>Connect Norwegian BankID</h2>
                {environment === 'TEST' ? (
                  <p>
                    (No configuration is required when testing, Criipto provides built-in credentials.)
                  </p>
                ) : null}
                <p>Enter your client id and client secret as provided by Bidbax (BankID BankAxept).</p>
                <p>If you do not yet have any credentials for NO BankID you can <a href="https://docs.criipto.com/getting-started/no-bankid/" target="_blank">order them via Criipto</a></p>
                {domains?.length ? (
                  <>
                    <p>
                      When ordering, please specify which of your domains you intend to use:
                    </p>
                    <ul>
                      {domains?.map(domain => <li key={domain.id}>{domain.name}</li>)}
                    </ul>
                  </>
                ) : (
                  <p>
                    Before ordering, please <Link to={`/tenant/${tenant.tenantId}/domains`}>create a domain</Link>
                  </p>
                )}
              </div>
              <div>
                <div className="flex flex-col gap-[10px]">
                  <InputField
                    type="text"
                    label="Client ID (Norwegian BankID)"
                    name="clientId"
                    autoComplete="off"
                    helpText={(
                      <>
                        Client IDs from Norwegian BankID from usually end in {environment === 'PRODUCTION' ? '-bankid-prod' : '-bankid-current'}
                      </>
                    )}
                  />
                  <InputField
                    type="secret"
                    label="Client Secret (Norwegian BankID)"
                    name="clientSecret"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group switch-group mt-[10px]">
                <label className="control-label">{translate('VERIFIED_DATA')}</label>
                <Switch
                  name="requestSocialNo"
                  label={translate('LABEL_NOBANKID_SOCIALNOREQUIRED')}
                  help={environment === 'PRODUCTION' && values.requestSocialNo && (
                    <small className="help-text">"Require SSN" should only be enabled if you ordered SSN access with your NO BankID credentials, otherwise it will cause authentications to fail.</small>
                  )}
                />
              </div>
              <div className="form-group switch-group">
                <label className="control-label">{translate('UNVERIFIED_DATA')}</label>
                <Switch name="requestAddress" label={translate('LABEL_NOBANKID_REQUESTADDRESS')} />
                <Switch name="requestEmail" label={translate('LABEL_NOBANKID_REQUESTEMAIL')} />
                <Switch name="requestPhone" label={translate('LABEL_NOBANKID_REQUESTEPHONE')} />
              </div>
              <p className="form-group">Learn more about SSN consent by visiting our <a href="https://docs.criipto.com/verify/e-ids/norwegian-bankid/#consent-model" className="text-underline" target="_blank">BankID documentation</a></p>

              <div className="form-group switch-group">
                <Switch
                  name="nninConsentStrategy.strategy"
                  label="Remember SSN"
                  value={values.nninConsentStrategy === 'REMEMBERED'}
                  onChange={(value: boolean) => {
                    if (value) {
                      setFieldValue('nninConsentStrategy', 'REMEMBERED');
                      setFieldValue('nninConsentDuration', 365);
                      return;
                    }
                    setFieldValue('nninConsentStrategy', 'EPHEMERAL');
                  }}
                />
                {values.nninConsentStrategy === 'REMEMBERED' ? (
                  <div className="form-group">
                    <label className="control-label">Number of days to store SSN consent for</label>
                    <input
                      type="number"
                      className="form-control"
                      min={1}
                      max={365}
                      value={Math.min(Math.max(values.nninConsentDuration, 1), 365)}
                      onChange={(event) => setFieldValue('nninConsentDuration', parseInt(event.target.value, 10))}
                    />
                  </div>
                ) : null}
              </div>
              </div>
            </ConfigurationSection.TwoColumn>
          </ConfigurationSection>
          {error ? (
            <Alert variant="error" title="Unable to update configuration" message={error} className="mb-[12px]" />
          ) : null}
          {!isPending && isSuccess ? (
            <Alert variant="success" title="Your norwegian bankid settings have been updated" />
          ) : null}
          <ConfigurationFooter>
            <Button variant="primary" working={isPending} type="submit">Save</Button>
          </ConfigurationFooter>
        </React.Fragment>
      )}
    </Form>
  )
}
