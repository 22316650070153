
import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { useRouteMatch } from 'react-router-dom';
import { useTenantId } from '@app/hooks/useTenant';
import { SettingsScreen_Query } from './__generated__/SettingsScreen_Query.graphql';
import { Form, FormError, FormSuccess, InputField } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';
import useMutation from '@app/hooks/useMutation';

type Values = {
  name: string
}

export default function SettingsScreen() {
  const match = useRouteMatch();
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<SettingsScreen_Query>(
    graphql`
      query SettingsScreen_Query($tenantId: ID!) {
        tenant(id: $tenantId) @required(action: THROW) {
          id
          viewerPermissions {
            billing
          }

          name
        }
      }
    `,
    {
      tenantId: tenantId.relayId
    }
  );

  const hasAccess = data.tenant.viewerPermissions.billing === "READ" || data.tenant.viewerPermissions.billing === "WRITE"
  if (!hasAccess) return null;

  const [executor] = useMutation(
    graphql`
      mutation SettingsScreen_Mutation($input: RenameTenantInput!) {
        renameTenant(input: $input) {
          tenant {
            name
          }
        }
      }
    `
  )

  const handleSubmit = async (values: Values) => {
    await executor.executePromise({
      input: {
        tenantId: data.tenant.id,
        name: values.name
      }
    })
  }

  return (
    <React.Fragment>
      <div className="app-content-header ">
        <h1>Settings</h1>
      </div>
      <div className="app-content-main">
      <Form
        initialValues={{name: data.tenant.name}}
        key={`${data.tenant.id}_settings`}
        onSubmit={handleSubmit}
        className="max-w-[750px]"
      >
        {({isPending, isSuccess, error, values}) => (
          <React.Fragment>
            <InputField type="text" label="Tenant name" name="name" />

            <FormError error={error} />
            <FormSuccess message={!isPending && isSuccess && `Your tenant settings have been updated` || null} />
            <Button variant="primary" working={isPending} type="submit">Save tenant settings</Button>
          </React.Fragment>
        )}
      </Form>
      </div>
    </React.Fragment>
  );
}
