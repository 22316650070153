import React, { useEffect, useState } from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import InputField from '@app/components/FormFields/InputField/InputField';
import Select from '@app/components/FormFields/Select/Select';
import Textarea from '@app/components/FormFields/Textarea/Textarea';
import HelpText from '@app/components/FormFields/HelpText';

import CodeFlow from '../CodeFlow';
import OAuth2Config from '../OAuth2Config';
import { OIDCSection_application$data, OIDCSection_application$key } from './__generated__/OIDCSection_application.graphql';
import { ApplicationRelayForm } from '../../ApplicationForm/ApplicationForm';
import ClientSecretModal from '../../ClientSecretModal/ClientSecretModal';
import { ApplicationJwksInput, OIDCSectionMutation } from './__generated__/OIDCSectionMutation.graphql';
import useMutation from '@app/hooks/useMutation';
import { OIDCSection_ClientJwks_application$key } from './__generated__/OIDCSection_ClientJwks_application.graphql';
import { useField } from 'formik';

interface Props {
  application: OIDCSection_application$key
}

type Values = Pick<OIDCSection_application$data, 'clientSecretEnabled' | 'oauth2'> & {
  clientJwks: ApplicationJwksInput | null
}

export default function OIDCSection(props : Props) {
  const [showClientSecret, setShowClientSecret] = useState<string | null>(null);

  const application = useFragment(graphql`
    fragment OIDCSection_application on OIDCApplication {
      id
      name
      clientSecretEnabled
      oauth2 {
        nullClaimValues
        userInfoResponseStrategy
        claimPropertyFormat
        callbackOnLocationHash
      }

      clientJwks {
        __typename
        ... on ApplicationJwksUrl {
          url
        }
        ... on ApplicationJwksStatic {
          jwks
        }
      }

      ... CodeFlow_application
      ... OIDCSection_ClientJwks_application
    }
  `, props.application);

  const [mutationExecutor, mutationState] = useMutation<OIDCSectionMutation>(graphql`
    mutation OIDCSectionMutation($input: UpdateApplicationInput!) {
      updateApplication(input: $input) {
        application {
          id
          ... OIDCSection_application
        }
        clientSecret
      }
    }
  `);

  const initialValues : Values = {
    clientSecretEnabled: application.clientSecretEnabled,
    oauth2: {...application.oauth2},
    clientJwks: application.clientJwks?.__typename === 'ApplicationJwksStatic' ? {
      static: application.clientJwks.jwks,
      url: null
    } : application.clientJwks?.__typename === 'ApplicationJwksUrl' ? {
      static: null,
      url: application.clientJwks.url
    } : null
  };

  return (
    <ApplicationRelayForm
      valid={true}
      onSubmit={async (values) => {
        const response = await mutationExecutor.executePromise({
          input: {
            applicationId: application.id,
            oauth2ClientSecretEnabled: values.clientSecretEnabled,
            oauth2: values.oauth2,
            clientJwks: values.clientJwks
          }
        });

        if (response.updateApplication.clientSecret) {
          setShowClientSecret(response.updateApplication.clientSecret);
        }
      }}
      values={initialValues}
    >
      <CodeFlow application={application} />
      <OAuth2Config />
      <ClientJwks application={application} />

      <ClientSecretModal open={!!showClientSecret} onHide={() => setShowClientSecret(null)} application={application} clientSecret={showClientSecret!} />
    </ApplicationRelayForm>
  )
}

function ClientJwks(props: {
  application: OIDCSection_ClientJwks_application$key
}) {
  const [{value}, , {setValue}] = useField<Values['clientJwks']>('clientJwks');
  const application = useFragment(graphql`
    fragment OIDCSection_ClientJwks_application on VerifyApplication {
      clientJwks {
        __typename
        ... on ApplicationJwksUrl {
          url
        }
        ... on ApplicationJwksStatic {
          jwks
        }
      }
    }
  `, props.application);

  const [type, setType] = useState<'none' | 'static' | 'url'>(
    application.clientJwks?.__typename === 'ApplicationJwksStatic' ? 'static' :
    application.clientJwks?.__typename === 'ApplicationJwksUrl' ? 'url' :
    'none'
  );
  const [url, setUrl] = useState(application.clientJwks && "url" in application.clientJwks ? application.clientJwks.url : '');
  const [jwks, setJwks] = useState(application.clientJwks && "jwks" in application.clientJwks ? application.clientJwks.jwks : '');

  useEffect(() => {
    if (type === 'none') {
      setValue(null)
      return;
    }
    if (type === 'url') {
      if (url?.length) {
        setValue({url: url});
      } else {
        setValue(null)
      }
      return;
    }
    if (type === 'static') {
      if (jwks?.length) {
        setValue({static: jwks});
      } else {
        setValue(null)
      }
      return;
    }
  }, [type, url, jwks]);

  const jwksInputRenderer = (
    <div className='mt-[6px]'>
      {type === 'url' ? (
        <InputField
          name="jwks_uri"
          type="url"
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          placeholder="Add JWKS uri"
        />
      ) : null}
      {type === 'static' ? (
        <Textarea name="jwks" value={jwks} onChange={(event) => setJwks(event.target.value)} ></Textarea>
      ) : null}
    </div>
  );

  return (
    <div>
      <Select
        name='client_jwks'
        label='Client JWKS'
        value={type}
        onChange={(event) => setType(event.target.value as any)}
      >
        <option value="none">None</option>
        <option value="static">Static</option>
        <option value="url">JWKS endpoint</option>
      </Select>
      {jwksInputRenderer}
      <HelpText>Registering JWKS on behalf of your client enables you to use JWTs for client authentication and for CIBA.</HelpText>
    </div>
  );
}