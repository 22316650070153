/**
 * @generated SignedSource<<2e6ce5facf4db72ba557f749547c46b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Environment = "PRODUCTION" | "TEST" | "%future added value";
export type Product = "Ageverification_DK" | "Signatures" | "Verify" | "%future added value";
export type RemoveProductPlanInput = {
  product: Product;
  subscriptionId: string;
};
export type RemoveProductPlanModalMutation$variables = {
  input: RemoveProductPlanInput;
};
export type RemoveProductPlanModalMutation$data = {
  readonly removeProductPlan: {
    readonly product: {
      readonly " $fragmentSpreads": FragmentRefs<"SubscriptionScreen_ProductPanel_product">;
    };
    readonly subscription: {
      readonly " $fragmentSpreads": FragmentRefs<"SubscriptionScreen_ProductPanel_subscription" | "SubscriptionScreen_ProductPanels_subscription">;
    };
    readonly tenant: {
      readonly ageverificationEnvironments: ReadonlyArray<Environment>;
      readonly environments: ReadonlyArray<Environment>;
      readonly signaturesEnvironments: ReadonlyArray<Environment>;
      readonly verifyEnvironments: ReadonlyArray<Environment>;
    };
  };
};
export type RemoveProductPlanModalMutation = {
  response: RemoveProductPlanModalMutation$data;
  variables: RemoveProductPlanModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": null
},
v3 = {
  "alias": "verifyEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Verify"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Verify\")"
},
v4 = {
  "alias": "signaturesEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": "Signatures"
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:\"Signatures\")"
},
v5 = {
  "alias": "ageverificationEnvironments",
  "args": [
    {
      "kind": "Literal",
      "name": "product",
      "value": [
        "Ageverification_DK"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "environments",
  "storageKey": "environments(product:[\"Ageverification_DK\"])"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "volume",
      "storageKey": null
    },
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legacyId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowRemove",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveProductPlanModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveProductPlanOutput",
        "kind": "LinkedField",
        "name": "removeProductPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionScreen_ProductPanel_subscription"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionScreen_ProductPanels_subscription"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubscriptionProduct",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionScreen_ProductPanel_product"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveProductPlanModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveProductPlanOutput",
        "kind": "LinkedField",
        "name": "removeProductPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCancelling",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionProduct",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubscriptionProduct",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4361da6e633d6d108bd9e9cbfc0043ab",
    "id": null,
    "metadata": {},
    "name": "RemoveProductPlanModalMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveProductPlanModalMutation(\n  $input: RemoveProductPlanInput!\n) {\n  removeProductPlan(input: $input) {\n    subscription {\n      ...SubscriptionScreen_ProductPanel_subscription\n      ...SubscriptionScreen_ProductPanels_subscription\n      id\n    }\n    product {\n      ...SubscriptionScreen_ProductPanel_product\n      id\n    }\n    tenant {\n      environments\n      verifyEnvironments: environments(product: Verify)\n      signaturesEnvironments: environments(product: Signatures)\n      ageverificationEnvironments: environments(product: [Ageverification_DK])\n      id\n    }\n  }\n}\n\nfragment RemoveProductPlanModal_subscription on BillingSubscription {\n  id\n}\n\nfragment SubscriptionPlanModal_plan on Plan {\n  id\n  name\n  legacyId\n  currency\n  interval\n  product\n  subscriptionPrice\n}\n\nfragment SubscriptionPlanModal_subscription on BillingSubscription {\n  id\n  isCancelling\n  currency\n  interval\n  products {\n    plan {\n      id\n    }\n    id\n  }\n}\n\nfragment SubscriptionScreen_ProductPanel_product on SubscriptionProduct {\n  product\n  plan {\n    id\n    name\n    currency\n    interval\n    volume\n    product\n    subscriptionPrice\n    unitPrice\n    ...SubscriptionPlanModal_plan\n  }\n  allowRemove\n}\n\nfragment SubscriptionScreen_ProductPanel_subscription on BillingSubscription {\n  status\n  ...SubscriptionPlanModal_subscription\n  ...RemoveProductPlanModal_subscription\n}\n\nfragment SubscriptionScreen_ProductPanels_subscription on BillingSubscription {\n  ...SubscriptionScreen_ProductPanel_subscription\n  products {\n    id\n    ...SubscriptionScreen_ProductPanel_product\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d9a6c2edbdff9c639d6156776725f74";

export default node;
