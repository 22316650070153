import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';
import './StylingScreen.scss';

import {translate} from '@app/i18n';
import InputField from '@app/components/FormFields/InputField/InputField';
import Tag from '@app/components/FormFields/TagGroup/Tag';
import TagGroup from '@app/components/FormFields/TagGroup/TagGroup';
import Select from '@app/components/FormFields/Select/Select';
import {Form, FormError} from '@app/components/Form';
import Button from '@app/components/Button';

import { IdServicesStyling, TenantRouteParams } from '@app/models';
import PreviewsScreen from './PreviewsScreen';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { StylingScreenQuery } from './__generated__/StylingScreenQuery.graphql';
import { StylingScreenGeneralQuery, ViewVersion } from './__generated__/StylingScreenGeneralQuery.graphql';
import { StylingScreenGeneralMutation } from './__generated__/StylingScreenGeneralMutation.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';

const CustomUIScreen = React.lazy(() => import('./CustomUIScreen/CustomUIScreen'));

type Values = {
  cssUrl: string
  cssOrigins: string[]
  viewVersion: ViewVersion
};

export default function StylingScreen() {
  const environment = useEnvironment();
  const match = useRouteMatch<TenantRouteParams>();

  const data = useLazyLoadQuery<StylingScreenQuery>(
    graphql`
      query StylingScreenQuery($tenantId: ID!) {
        tenant(id: $tenantId) {
          id

          features {
            CUSTOM_UI
          }
        }
      }
    `
  , {
    tenantId: btoa(`tenant:${match.params.tenantId}`)
  });

  if (!data.tenant) return null;

  const screenLoader = <div className="app-tab-content full-screen"><i className="fa fa-spinner fa-pulse fa-2x" /></div>;

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <h1>Styling</h1>
        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={`${match.url}`} exact={true} activeClassName="active">General</NavLink>
            </li>
            {data.tenant.features.CUSTOM_UI ? (
              <li>
                <NavLink to={`${match.url}/custom-ui`} activeClassName="active">Custom UI</NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <Switch>
        <Route path={`${match.path}`} exact>
          <GraphQLErrorBoundary>
            <React.Suspense fallback={screenLoader}>
              <div className="app-tab-content full-screen">
                <StylingScreenGeneral />
              </div>
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Route>
        <Route path={`${match.path}/custom-ui`}>
          <GraphQLErrorBoundary>
            <React.Suspense fallback={screenLoader}>
              <CustomUIScreen />
            </React.Suspense>
          </GraphQLErrorBoundary>
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export function StylingScreenGeneral() {
  const environment = useEnvironment();
  const match = useRouteMatch<TenantRouteParams>();

  const data = useLazyLoadQuery<StylingScreenGeneralQuery>(
    graphql`
      query StylingScreenGeneralQuery($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          id
          styling(environment: $environment) {
            cssUrl
            cssOrigins
            viewVersion {
              version
              editable
            }
          }

          viewerPermissions {
            testStyling: styling(environment: TEST)
            productionStyling: styling(environment: PRODUCTION)
          }

          features {
            CUSTOM_UI
          }

          ...PreviewsScreen_tenant @arguments(environment: $environment)
        }
      }
    `
  , {
    tenantId: btoa(`tenant:${match.params.tenantId}`),
    environment
  });

  const [mutationExecutor, mutationState] = useMutation<StylingScreenGeneralMutation>(graphql`
    mutation StylingScreenGeneralMutation($input: UpdateStylingInput!, $environment: Environment!) {
      updateStyling(input: $input) {
        tenant {
          styling(environment: $environment) {
            cssUrl
            cssOrigins
            viewVersion {
              version
              editable
            }
          }

          ...PreviewsScreen_tenant @arguments(environment: $environment)
        }
      }
    }
  `);

  const handleSubmit = async (values: Values) => {
    await mutationExecutor.executePromise({
      environment,
      input: {
        tenantId: data.tenant!.id,
        environment,
        cssUrl: values.cssUrl,
        cssOrigins: values.cssOrigins,
        viewVersion: values.viewVersion
      }
    });
  };

  const tracking = useTracking();
  useEffect(() => {
    if (!data.tenant) return;
    tracking.viewedStylingScreen();
  }, [data.tenant]);

  if (!data.tenant) return null;

  const styling = data.tenant.styling;
  const initialValues : Values = {
    cssUrl: styling.cssUrl ?? '',
    cssOrigins: styling.cssOrigins.slice(),
    viewVersion: styling.viewVersion?.version
  };
  const viewVersionEditable = styling.viewVersion?.editable;

  let viewVersions : ViewVersion[] = ["UNIFIED"];
  if (viewVersionEditable) {
    const initial : ViewVersion[] = ["INITIAL"];
    viewVersions = initial.concat(viewVersions);
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} key={environment}>
      {({isPending, error, values}) => (
        <React.Fragment>
          <div className="w-full max-w-[1280px] flex flex-row gap-[16px]">
            <div className="w-[77%] flex flex-col gap-[25px] mb-[10px]">
              <InputField<Values> type="text" name="cssUrl" label={translate('LABEL_ID_SERVICE_STYLESHEET_TEST')}  />

              <CssOrigins />

              {error && <FormError error={error} />}

              <div className="d-flex align-items-center justify-content-between">
                {(viewVersions.length > 1) ? (
                  <Select<Values>
                    className="min-w-[140px]"
                    label="View version"
                    name="viewVersion"
                  >
                    {viewVersions.map(viewVersion => (
                      <option value={viewVersion} key={viewVersion}>
                        {viewVersion === 'INITIAL' ? 'Legacy' : 'Unified'}
                      </option>
                    ))}
                  </Select>
                ) : <div />}
                <Button type="submit" working={isPending} variant="primary" style={{marginTop: '0px'}}>
                  {translate('SAVE')}
                </Button>
              </div>
            </div>
            <div className="w-[33%]">
              <h3 style={{marginTop: '0px'}}><span className="experimental">{translate('INFO_FEATURE_STYLING')}</span></h3>
              <p>{translate('INFO_FEATURE_STYLING_DESCRIPTION')}</p>
              {styling.viewVersion.editable && (
                <p>{translate('INFO_FEATURE_STYLING_DESCRIPTION_B')}</p>
              )}
            </div>
          </div>
          {values.viewVersion !== 'INITIAL' ? (
            <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
              <PreviewsScreen tenant={data.tenant!} styling={values} />
            </React.Suspense>
          ) : (
            <p>
              Styling previews are not supported in 'legacy' view version modes.<br />
              Please consider upgrading.
            </p>
          )}
        </React.Fragment>
      )}
    </Form>
  )
}

function isValidOrigin(input: string) {
  try {
    const url = new URL(input);
    if (url.protocol !== 'https:') return false;
    if (!url.hostname) return false;
    return true;
  } catch (err) {
    return false;
  }
}

function CssOrigins() {
  const [{value}, , {setValue}] = useField<Values['cssOrigins']>('cssOrigins');
  const [addText, setAddText] = useState('');

  const handleRemove = (index: number) => {
    const newValue = value.slice(0);
    newValue.splice(index, 1);
    setValue(newValue);
  }

  const handleAdd = (input: string) => {
    const url = new URL(input);
    setValue(value.concat(url.origin));
  }

  const handleAddClick = (event: React.FormEvent) => {
    if (!addText || !isValidOrigin(addText)) return;
    handleAdd(addText);
    setAddText('');
  };
  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
  }
  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (!addText || !isValidOrigin(addText)) return;
      handleAdd(addText);
      setAddText('');
      return;
    }
  }

  return (
    <div>
      <TagGroup header="Style origins">
        {value.map((url, index) => (
          <Tag
            key={index}
            tag={url}
            onDelete={() => handleRemove(index)}
          />
        ))}
      </TagGroup>
      <InputField
        type="text"
        name="style_origin"
        placeholder="Add style origin"
        value={addText}
        onChange={(event) => setAddText(event.target.value)}
        onKeyDown={handleKeydown}
        onKeyUp={handleKeyUp}
        button = {
          <Button type="button" variant="default" onClick={handleAddClick}>Add</Button>
        }
        helpText="If you wish to support multiple stylesheet origins, for example if using CSS @import, you can define them here."
        error={addText && !isValidOrigin(addText) ? 'Not a valid origin (expected https://hostname.tld)' : undefined}
      />
    </div>
  )
}