import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';


import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { NOVippsProviderQuery } from './__generated__/NOVippsProviderQuery.graphql';
import { NOVippsProviderMutation } from './__generated__/NOVippsProviderMutation.graphql';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId,
  environment: Environment
}

export interface FormValues {
  clientId : string
  clientSecret : string,
  socialNoRequired: boolean
}

export function NOVippsProvider(props : Props) {
  const tenant = props.tenant;

  const data = useLazyLoadQuery<NOVippsProviderQuery>(
    graphql`
      query NOVippsProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on NorwegianVipps {
            clientId
            socialNoRequired
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
      }
    `
  , {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|NO_VIPPS`),
    tenantId: tenant.relayId,
    environment: props.environment
  });

  const [{executePromise}] = useMutation<NOVippsProviderMutation>(graphql`
    mutation NOVippsProviderMutation(
      $input: UpdateNorwegianVippsInput!
    ) {
      updateNorwegianVipps(input: $input) {
        id
        isConfigured
        ... on NorwegianVipps {
          clientId
          socialNoRequired
        }
      }
    }
  `);

  if (!data.identityProvider) return null;

  const initialValues : FormValues = {
    clientId: data.identityProvider.clientId ?? '',
    clientSecret: '',
    socialNoRequired: data.identityProvider.socialNoRequired || false
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";
  const domains = data.tenant?.domains.edges.map(edge => edge.node);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_NOBANKID_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          {!initialValues.clientId ? (
            <EnvironmentDialog environment="PRODUCTION" heading={(
              <React.Fragment>
                <h1>
                  <strong>{translate('INFO_NO_BANKID_CLIENT')}</strong>
                </h1>
                <br />
                <h3>{translate('INFO_NO_BANKID_CALLBACK_URL_PROD')}</h3>
              </React.Fragment>
            )}>
              <React.Fragment>
                {domains?.map(domain => (
                  <p className="green" key={domain.name}>
                  https://{domain.name}/NOVippsLogin/Callback
                </p>
                ))}
              </React.Fragment>
            </EnvironmentDialog>
          ) : null}
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} {data.identityProvider!.longName} Login
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_NOVIPPS_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_NOVIPPS_CLIENTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_NOVIPPS_SECRET')}
                name="clientSecret"
                placeholder={translate('LABEL_NOVIPPS_SECRET')}
              />
              <Switch name="socialNoRequired" label={translate('LABEL_NOVIPPS_SOCIALREQUIRED')} />
              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your norwegian vipps login settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_NO_VIPPS_CLIENT')}</p>
              <p>{translate(isProduction ? 'INFO_NO_VIPPS_CALLBACK_URL_PROD': 'INFO_NO_VIPPS_CALLBACK_URL_TEST')}</p>
              {domains?.map(domain => (
                <p className="green" key={domain.name}>
                https://{domain.name}/NOVippsLogin/Callback
              </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
