import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { OrganizationRouteParams } from '@app/models';
import { OrganizationScreen_Query } from './__generated__/OrganizationScreen_Query.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import OrganizationApiKeysScreen from './screens/ApiKeys';
import OrganizationTenantsScreen from './screens/Tenants';
import OrganizationUsersScreen from './screens/Users';

export default function OrganizationScreen() {
  const match = useRouteMatch<OrganizationRouteParams>();
  const query = useLazyLoadQuery<OrganizationScreen_Query>(
    graphql`
      query OrganizationScreen_Query($organizationId: ID!) {
        organization(id: $organizationId) @required(action: THROW) {
          name

          viewerPermissions {
            tenants
            users
            testApi: api(environment: TEST)
            productionApi: api(environment: PRODUCTION)
          }
        }
      }
    `,
    {
      organizationId: btoa(`organization:${match.params.organizationId}`)
    }
  );

  const apiAccess =
    query.organization.viewerPermissions.testApi !== 'NONE' ||
    query.organization.viewerPermissions.productionApi !== 'NONE';

  const tenantAccess = query.organization.viewerPermissions.tenants !== 'NONE';
  const userAccess = query.organization.viewerPermissions.users !== 'NONE';

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div>
          <h1>
            {query.organization.name}
          </h1>
        </div>
        <div className="app-content-tabs">
          <ul>
            {/* <li>
              <NavLink to={match.url} exact={true} activeClassName="active">Settings</NavLink>
            </li> */}
            {tenantAccess && (
              <li>
                <NavLink to={`${match.url}/tenants`} activeClassName="active">Tenants</NavLink>
              </li>
            )}
            {userAccess && (
              <li>
                <NavLink to={`${match.url}/users`} activeClassName="active">Users</NavLink>
              </li>
            )}
            {apiAccess && (
              <li>
                <NavLink to={`${match.url}/api-keys`} activeClassName="active">API Keys</NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="app-tab-content full-screen">
        <Switch>
          <Route path={match.path} exact={true}>
            {apiAccess && (
              <Redirect to={`${match.url}/api-keys`} />
            )}
          </Route>
          {tenantAccess && (
            <Route path={`${match.path}/tenants`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <OrganizationTenantsScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
          )}
          {userAccess && (
            <Route path={`${match.path}/users`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <OrganizationUsersScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
          )}
          {apiAccess && (
            <Route path={`${match.path}/api-keys`}>
              <GraphQLErrorBoundary>
                <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                  <OrganizationApiKeysScreen />
                </React.Suspense>
              </GraphQLErrorBoundary>
            </Route>
          )}
        </Switch>
      </div>
    </React.Fragment>
  )
}