import React from 'react';
import cx from 'classnames';
import styles from '../FormFields.module.css';
import useField from '../useField';
import HelpText from '../HelpText';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  helpText?: React.ReactNode;
  error?: string;
  className?: string;
}

function className(props: TextAreaProps) {
  return cx({
    [styles['disabled']]: 'disabled' in props ? true : false,
  });
}

export default function Textarea(props: TextAreaProps) {
  const fieldResult = useField(props.name);
  const [field] = fieldResult ?? [];
  const {helpText, ...rest} = props;

  const textarea = (
    <textarea
      {...field}
      {...(rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
      id={props.id}
      name={props.name}
      className={cx(
        className(props),
        props.className,
        styles['input-field-text'],
        'h-[200px]'
      )}
      placeholder={props.placeholder}
    />
  );

  const textArea = (
    <div className={`${styles['textarea-container']} w-full`}>
      <div className={`${className(props)} ${styles['textarea-wrapper']}`}>
        {props.label ? (
          <>
            <label
              htmlFor={props.id}
              className={styles['input-label']}
            >
              {props.label}
            </label>
            {textarea}
          </>
        ) : (
          <>{textarea}</>
        )}
      </div>
      {props.error && <div className={`${styles['help-text']} ${styles['invalid']}`}>{props.error}</div>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </div>
  );

  return textArea;
}
