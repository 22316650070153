import React from 'react';

import './StatBox.scss';

interface Props {
  prefix: string | React.ReactNode
  number: number | 'N/A'
  suffix: string | React.ReactNode
}
export default function StatBox(props: Props) {
  return (
    <div className="analytics-stat-box">
      <div className="prefix">{props.prefix}</div>
      <div className="number">{props.number}</div>
      <div className="suffix">{props.suffix}</div>
    </div>
  )
}