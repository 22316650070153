import React from 'react';
import { useDispatch } from 'react-redux';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {set as setEnvironment} from '@app/redux/environmentSlice';
import Modal from '../Modal/Modal';

import './ProductionUpgradeRequiredModal.scss';
import { ProductionUpgradeRequiredModal_tenant$key } from './__generated__/ProductionUpgradeRequiredModal_tenant.graphql';
import { LinkButton } from '../Button/Button';

type Props = {
  show: boolean,
  onHide: () => void
  tenant: ProductionUpgradeRequiredModal_tenant$key
}
export function ProductionUpgradeRequiredModal(props: Props) {
  const {show, onHide} = props;
  const dispatch = useDispatch();

  const tenant = useFragment(
    graphql`
      fragment ProductionUpgradeRequiredModal_tenant on Tenant {
        name
        shortTenantId
      }
    `,
    props.tenant
  );

  const hideModal = () => {
    dispatch(setEnvironment({tenantId: tenant.shortTenantId, environment: 'TEST'}));
    onHide();
  };
  const title = <span><strong>{tenant.name}</strong> is not ready for production.</span>;

  return (
    <Modal open={show} onHide={hideModal} className="production-upgrade-required-modal">
      <Modal.Header centered>
        <div>
          <h2>{title}</h2>
          <p className='!m-0'>Ready to go to production and let users log in with their real e-ID?</p>
        </div>
      </Modal.Header>
      <Modal.Actions>
        <LinkButton to={`/tenant/${tenant.shortTenantId}/billing`} variant="primary" onClick={() => hideModal()}>
          <i className="fa fa-plus text-TEST" />
          <span className="text-TEST">Upgrade</span> to paid subscription
        </LinkButton>
      </Modal.Actions>
    </Modal>
  )
}

export default ProductionUpgradeRequiredModal;