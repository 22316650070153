
import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { NavLink, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PaymentHistoryScreen from './screens/PaymentHistoryScreen';
import BillingInformationScreen from './screens/BillingInformationScreen';
import SubscriptionScreen from './screens/SubscriptionScreen/SubscriptionScreen';
import { useTenantId } from '@app/hooks/useTenant';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import { BillingScreen_Query } from './__generated__/BillingScreen_Query.graphql';

export default function BillingScreen() {
  const match = useRouteMatch();
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<BillingScreen_Query>(
    graphql`
      query BillingScreen_Query($tenantId: ID!) {
        tenant(id: $tenantId) {
          viewerPermissions {
            billing
          }

          environments

          billing {
            subscription {
              id
            }
          }
        }
      }
    `,
    {
      tenantId: tenantId.relayId
    }
  );

  const hasAccess = data.tenant?.viewerPermissions.billing === "READ" || data.tenant?.viewerPermissions.billing === "WRITE"
  if (!hasAccess) return null;

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <h1>Billing</h1>

        <div className="app-content-tabs">
          <ul>
          <li>
              <NavLink to={`${match.url}/subscription`} exact={true} activeClassName="active">Subscription</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/payment-method`} exact={true} activeClassName="active">Payment method</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/information`} exact={true} activeClassName="active">Billing information</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/history`} exact={true} activeClassName="active">Payment history</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-tab-content">
        <Switch>
          <Route path={match.path} exact={true}>
          <i className="fa fa-spinner fa-pulse fa-2x" />
            {data.tenant.environments.includes('PRODUCTION') ? (
              <Redirect to={`${match.url}/subscription`} />
            ) : (!data.tenant.billing?.subscription) ? (
              <Redirect to={`${match.url}/signup`} />
            ) : (
              <Redirect to={`${match.url}/subscription`} />
            )}
          </Route>
          <Route path={`${match.path}/subscription`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <SubscriptionScreen />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/payment-method`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <PaymentMethodScreen />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/history`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <PaymentHistoryScreen />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/information`}>
            <GraphQLErrorBoundary>
              <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
                <BillingInformationScreen />
              </React.Suspense>
            </GraphQLErrorBoundary>
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}
