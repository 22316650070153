import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Link, NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Tag } from '@app/components/Tag/Tag';
import { Application, TenantRouteParams } from '@app/models';

import DeleteApplicationButton from '../../components/DeleteApplicationButton';
import IdentityProvidersSection from '../../components/ApplicationEditor/Sections/IdentityProviders';
import AdvancedOptionsSection from '../../components/ApplicationEditor/Sections/AdvancedOptions';
import OIDCSection from '../../components/ApplicationEditor/Sections/OIDC';
import CopyApplicationButton from '../../components/CopyApplicationButton/CopyApplicationButton';
import VerifyApplicationIntegrate from '../../components/ApplicationEditor/VerifyApplicationIntegrate';
import UISection from '../../components/ApplicationEditor/Sections/UI';
import { EditVerifyApplicationScreenQuery } from './__generated__/EditVerifyApplicationScreenQuery.graphql';
import GraphQLErrorBoundary from '@app/components/GraphQLErrorBoundary';
import useEnvironment from '@app/hooks/useEnvironment';
import GeneralSection from '../../components/ApplicationEditor/Sections/General';
import { EditVerifyApplicationScreen_application$key, EditVerifyApplicationScreen_application$data } from './__generated__/EditVerifyApplicationScreen_application.graphql';
import NativeSection from '../../components/ApplicationEditor/NativeSection';
import { EditVerifyApplicationScreen_tenant$key } from './__generated__/EditVerifyApplicationScreen_tenant.graphql';

interface Props {
  parentUrl: string,
  application: EditVerifyApplicationScreen_application$key,
  tenant: EditVerifyApplicationScreen_tenant$key,
  onDelete: () => void
}
export default function EditVerifyApplicationScreen(props: Props) {
  const match = useRouteMatch<TenantRouteParams>();
  const environment = useEnvironment();

  const application = useFragment(
    graphql`
      fragment EditVerifyApplicationScreen_application on Application {
        id
        name
        ... GeneralSection_application
        ... IdentityProvidersSection_application
        ... UISection_application
        ... AdvancedOptionsSection_application
        ... OIDCSection_application
        ... VerifyApplicationIntegrate_application
        ... DeleteApplicationButton_application
        ... CopyApplicationButton_application
        ... NativeSection_application
      }
    `, props.application
  );
  const tenant = useFragment(
    graphql`
      fragment EditVerifyApplicationScreen_tenant on Tenant {
        ... IdentityProvidersSection_tenant
      }
    `
  , props.tenant);

  const data = useLazyLoadQuery<EditVerifyApplicationScreenQuery>(
    graphql`
      query EditVerifyApplicationScreenQuery($tenantId: ID!, $environment: Environment!) {
        tenant(id: $tenantId) {
          id
          ... UISection_tenant @arguments(environment: $environment)
        }
      }
    `
  , {
    tenantId: btoa(`tenant:${match.params.tenantId}`),
    environment
  });

  if (!data.tenant) return null;

  return (
    <React.Fragment>
      <EditVerifyApplicationScreenHeader {...props} application={application} />
      <div className="app-tab-content">
        <Switch>
          <Route path={match.path} exact={true}>
            <div className="max-w-[750px]">
              <GraphQLErrorBoundary>
                <GeneralSection
                  parentUrl={props.parentUrl}
                  application={application}
                />
              </GraphQLErrorBoundary>
            </div>
          </Route>
          <Route path={`${match.path}/e-ids`} exact={true}>
            <GraphQLErrorBoundary>
              <IdentityProvidersSection
                tenant={tenant}
                application={application}
              />
            </GraphQLErrorBoundary>
          </Route>
          <Route path={`${match.path}/oidc`} exact={true}>
            <div className="max-w-[750px]">
              <OIDCSection application={application}/>
            </div>
          </Route>
          <Route path={`${match.path}/ui`} exact={true}>
            <div className="max-w-[750px]">
              <GraphQLErrorBoundary>
                <UISection tenant={data.tenant} application={application} />
              </GraphQLErrorBoundary>
            </div>
          </Route>
          <Route path={`${match.path}/advanced`} exact={true}>
            <div className="max-w-[750px]">
              <AdvancedOptionsSection application={application} />
            </div>
          </Route>
          <Route path={`${match.path}/integrate`}>
            <VerifyApplicationIntegrate application={application} />
          </Route>
          <Route path={`${match.path}/native`} exact={true}>
            <div className="max-w-[750px]">
              <NativeSection application={application} />
            </div>
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}

interface HeaderProps {
  parentUrl: string,
  application: EditVerifyApplicationScreen_application$data
  onDelete: () => void,
}
export function EditVerifyApplicationScreenHeader(props: HeaderProps) {
  const {parentUrl, application, onDelete} = props;
  const match = useRouteMatch();
  return (
    <div className="app-content-header with-tabs">
      <div className="breadcrumb">
        <Link to={parentUrl}>Applications</Link>
        <i className="fa fa-angle-right" />
        <div>Login Application Details</div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-row items-center gap-[10px]">
          <h1>{application?.name}</h1>
          <Tag>Criipto Verify</Tag>
        </div>
        <div className="flex flex-row items-center gap-[10px]">
          <CopyApplicationButton key={`copy_application_button_${application.id}`} variant="default" application={application} />
          <DeleteApplicationButton onDelete={onDelete} application={application} autoWidth />
        </div>
      </div>

      <div className="app-content-tabs">
        <ul>
          <li>
            <NavLink to={`${match.url}/integrate`} activeClassName="active">Integrate</NavLink>
          </li>
          <li>
            <NavLink to={match.url} exact={true} activeClassName="active">General</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/e-ids`} activeClassName="active">e-IDs</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/oidc`} activeClassName="active">OpenID Connect</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/ui`} activeClassName="active">UI</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/advanced`} activeClassName="active">Advanced Options</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/native`} activeClassName="active">Native/Mobile</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}