import React from 'react';
import { useField } from "formik";
import { uniq } from 'lodash';

import {StandaloneSwitch} from '@app/components/Form';
import { singleton as config } from '@app/config';
import useEnvironment from '@app/hooks/useEnvironment';

export function qrURLs(domain: string) {
  return [
    `https://${domain}/qr/`,
    config.CRIIPTO_QR_INTERMEDIEARY.replace('{id}', '')
  ]
}

export default function QRReady(props: {domain: string}) {
  const environment = useEnvironment();
  const [{value}, , {setValue}] = useField<string[]>('returnUrls');

  const domainURL = `https://${props.domain}/qr/`;
  const criiptoURL = config.CRIIPTO_QR_INTERMEDIEARY.replace('{id}', '');

  const handleChange = (checked: boolean) => {
    if (checked) {
      setValue(
        uniq(
          value.concat([
            domainURL,
            criiptoURL
          ])
        )
      );
    } else {
      setValue(
        value.filter(url => ![domainURL, criiptoURL].includes(url))
      );
    }
  };

  const isEnabled = value.includes(domainURL) && value.includes(criiptoURL);

  return (
    <div className="form-group">
      <StandaloneSwitch
        label="QR Enabled"
        value={isEnabled}
        onChange={handleChange}
        help={(
          <small className="help-text">
            Offer your users a fast login experience on desktop.&nbsp;
            <React.Fragment>
              {environment === 'TEST' ? (
                <React.Fragment>
                  <a href={config.CRIIPTO_QR_DEMO} target="_blank">Try a demo.</a>&nbsp;
                </React.Fragment>
              ) : null}
            </React.Fragment>
          </small>
        )}
      />
    </div>
  );
}