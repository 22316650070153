export const OAUTH2_SCOPE = "openid email profile";
export const ENTITY_ID_PREFIX = "urn:grn:entityid:organization:easyid:";
export const TENANT_ID_PREFIX = "urn:grn:easyid:tenant:";
export const ROLE_CLAIM_TYPE = "role";
export const PERMISSION_CLAIM_TYPE = "http://schemas.grean.com/claims/authorizationservice/permission";
export const BASE_URL = typeof window !== 'undefined' ? window.location.protocol + "//" + window.location.host + '/' : '/';
export const LOGIN_CALLBACK_URL = BASE_URL + "callback";
export const SIGNUP_CALLBACK_URL = BASE_URL + "signup/callback";
export const LOGIN_NEMID_CALLBACK_URL = BASE_URL + "login/nemid/callback";
export const LOGOUT_URL = BASE_URL + 'login';
export const TENANT_PREFIX = TENANT_ID_PREFIX;

export const ROLE : {[key: string]: "configuration-management" | "private-key-management" | "extensibility-management"} = {
  PRODUCTION_DOMAINS: "configuration-management",
  TEST_DOMAINS: "configuration-management",
  APP_DEV: "configuration-management",
  APP_ADMIN: "configuration-management",
  ID_ADMIN: "private-key-management",
  EXTENSIBILITY_ADMIN: "extensibility-management"
};
export const PERMISSION = {
  SEE_USERS: "see-users",
  USER_ADMIN: "user-admin"
};

let rel = "easyid:";
export const REL = {
  TENANTS: rel + "tenants",
  TOKENSIGNING_KEYS: rel + "tokensigning-keys",
  PREPARE_NEW_TOKENSIGNING_KEYS: rel + "prepare-new-tokensigning-key",
  ACCESS_REQUEST: rel + "access-request",
  DNS_AVAILABLE: rel + "dns-available",
  CREATE_TENANT_DOMAIN: rel + "tenant-domains",
  DOMAINS: rel + "tenant-domains",
  ID_SERVICES: rel + "id-services",
  LOOKUP: rel + "lookup",
  APPLICATIONS: rel + "applications",
  ENROLLMENT: rel + "enrollment",
  CREATE_CERTIFICATE: rel + "certificates",
  MANAGED_CERTIFICATES: rel + "managed-certificates",
  SE_BANKID: rel + "sebankid",
  NO_BANKID_SETTINGS: rel + "nobankid-oidc-settings",
  NO_BANKID_OLD: rel + "nobankid",
  NO_VIPPS: rel + "novippslogin",
  NO_VIPPS_SETTINGS: rel + "novipps-settings",
  DK_NEMID: rel + "dknemid",
  DK_NEMID_SETTINGS: rel + "dknemid-settings",
  DK_MITID_SETTINGS: rel + "dkmitid-settings",
  DK_MITID_APPLY: rel + "dkmitid-apply",
  DK_MITID_DOMAIN: rel + "dkmitid-domain",
  DK_MITID_COMPLETE: rel + "dkmitid-complete",
  DK_MITID_STATUS: rel + "dkmitid-status",
  NL_DIGID: rel + "nldigid",
  NL_DIGID_SETTINGS: rel + "nldigid-settings",
  ITSME_SETTINGS: rel + "itsme-settings",
  BE_EID_SETTINGS: rel + "belgianeid-settings",
  DE_SOFORT_SETTINGS: rel + "germansofort-settings",
  NL_IDIN_SETTINGS: rel + "nlidin-settings",
  DASHBOARD: rel + "dashboard",
  CLIENTSECRET: rel + "clientsecret",
  ID_SERVICES_STYLING: rel + "id-services-styling",
  BILLABLE: rel + "billable",
  BACKOFFICE: rel + "backoffice",
  EXTENSIBILITY_CONFIGURATION: rel + "extensibility-configuration"
} as const;

let type = (x: string) => `application/vnd.grn.easyid.${x}+json`;
export const CONTENT_TYPE = {
  ACCESS_REQUEST: type("access-request"),
  APPLICATION: type("application"),
  DOMAIN: type("domain"),
  ENROLLMENT: type("enrollment"),
  DKNEMID_SETTINGS: type("dknemid-settings"),
  DKMITID_SETTINGS: type("dkmitid-settings"),
  DK_MITID_APPLY: type("dkmitid-apply"),
  DK_MITID_DOMAIN: type("dkmitid-domain"),
  DK_MITID_COMPLETE: type("dkmitid-complete"),
  ID_SERVICES_STYLING: type("id-services-stylings"),
  NO_BANKID_SETTINGS: type("nobankid-oidc-settings"),
  NOVIPPS_SETTINGS: type("novipps-settings"),
  ITSME_SETTINGS: type("itsme-settings"),
  BE_EID_SETTINGS: type("belgianeid-settings"),
  DE_SOFORT_SETTINGS: type("germansofort-settings"),
  NL_IDIN_SETTINGS: type("nlidin-settings"),
  TOKEN_SIGNING_KEY: type("tokensigning-key"),
  TOKEN_SIGNING_KEY_VERSION: type("tokensigning-key-version"),
  EXTENSIBILITY_ENVIRONMENT: type("extensibility-environment"),
  EXTENSIBILITY_ENABLED: type("extensibility-enabled"),
  BILLABLE: type("billable"),
  BACKOFFICE: type("backoffice"),
} as const;

export const REL_CONTENT_TYPE_MAP : {[key in keyof typeof REL]: null | keyof typeof CONTENT_TYPE} = {
  TENANTS: null,
  TOKENSIGNING_KEYS: null,
  PREPARE_NEW_TOKENSIGNING_KEYS: null,
  ACCESS_REQUEST: null,
  DNS_AVAILABLE: null,
  CREATE_TENANT_DOMAIN: null,
  DOMAINS: null,
  ID_SERVICES: null,
  LOOKUP: null,
  APPLICATIONS: null,
  ENROLLMENT: null,
  CREATE_CERTIFICATE: null,
  MANAGED_CERTIFICATES: null,
  SE_BANKID: null,
  NO_BANKID_SETTINGS: 'NO_BANKID_SETTINGS',
  NO_BANKID_OLD: null,
  NO_VIPPS: null,
  NO_VIPPS_SETTINGS: 'NOVIPPS_SETTINGS',
  DK_NEMID: null,
  DK_NEMID_SETTINGS: 'DKNEMID_SETTINGS',
  DK_MITID_SETTINGS: 'DKMITID_SETTINGS',
  DK_MITID_APPLY: null,
  DK_MITID_DOMAIN: null,
  DK_MITID_COMPLETE: null,
  DK_MITID_STATUS: null,
  NL_DIGID: null,
  NL_DIGID_SETTINGS: null,
  ITSME_SETTINGS: 'ITSME_SETTINGS',
  BE_EID_SETTINGS: 'BE_EID_SETTINGS',
  DE_SOFORT_SETTINGS: 'DE_SOFORT_SETTINGS',
  NL_IDIN_SETTINGS: 'NL_IDIN_SETTINGS',
  DASHBOARD: null,
  CLIENTSECRET: null,
  ID_SERVICES_STYLING: null,
  BILLABLE: null,
  BACKOFFICE: 'BACKOFFICE',
  EXTENSIBILITY_CONFIGURATION: null
} as const;

export const RESERVED_DOMAINS = ["grean.id", "manage.grean.id", "api.grean.id", "idp.grean.id", "criipto.id", "manage.criipto.id", "api.criipto.id", "idp.criipto.id"];

export const IS_DEVELOPER_MODE = ['localhost', 'dashboard-test.criipto.com'].includes(window.location.hostname) || window.location.hostname.includes('dashboard-test.netlify.app');