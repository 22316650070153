import React, { useState } from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import Modal, {ModalProps} from '@app/components/Modal/Modal';
import DomainCertificateUploadForm from '../DomainCertificateUploadForm';
import { FormSuccess } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';
import { DomainCertificateUploadModal_domain$key } from './__generated__/DomainCertificateUploadModal_domain.graphql';

interface Props {
  onHide: ModalProps["onHide"]
  open: ModalProps["open"]
  domain: DomainCertificateUploadModal_domain$key
}

export default function DomainCertificateUploadModal(props : Props) {
  const domain = useFragment(graphql`
    fragment DomainCertificateUploadModal_domain on Domain {
      name
      ... DomainCertificateUploadForm_domain
    }
  `, props.domain);

  const [saved, setSaved] = useState(false);

  return (
    <Modal open={props.open} onHide={props.onHide}>
      <Modal.Header>
        <div>
          <h2>{`Upload SSL certificate for ${domain.name}`}</h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        {saved ? (
          <React.Fragment>
            <FormSuccess message="Domain SSL certificate uploaded" />
            <Button variant="primary" onClick={props.onHide}>Close</Button>
          </React.Fragment>
        ) : (
          <DomainCertificateUploadForm domain={domain} onSave={() => setSaved(true)} />
        )}
      </Modal.Content>
    </Modal>
  );
}