import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {AddApiKeyForm_application$key} from './__generated__/AddApiKeyForm_application.graphql';
import {AddApiKeyFormMutation} from './__generated__/AddApiKeyFormMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';

import ApiKeyModal from '../ApiKeyModal';
import Alert from '@app/components/Alert/Alert';

interface Props {
  application: AddApiKeyForm_application$key,
}


export default function AddApiKeyForm(props: Props) {
  const [showApiKeySecret, setShowApiKeySecret] = useState(false);
  const [note, setNote] = useState('');
  const [mode, setMode] = useState<'READ_WRITE' | 'READ_ONLY'>('READ_WRITE');

  const application = useFragment(graphql`
    fragment AddApiKeyForm_application on Signatures_Application {
      id
    }
  `, props.application);

  const [addKeyMutationExecutor, addKeyMutationState] = useMutation<AddApiKeyFormMutation>(graphql`
    mutation AddApiKeyFormMutation($input: CreateApplicationApiKeyInput!) {
      createSignaturesApplicationApiKey(input: $input) {
        application {
          id
          ... ApiKeys_application
        }

        apiKey {
          ... ApiKeyModal_apiKey
        }
      }
    }
  `);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    addKeyMutationExecutor.executePromise({
      input: {
        applicationId: application.id,
        note,
        mode
      }
    }).then(() => {
      setNote('');
      setMode('READ_WRITE');
      setShowApiKeySecret(true);
    })
    .catch(() => {
      // handled by addKeyMutationState
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="flex flex-row gap-[8px]" data-test-id="form">
        <input type="text" className="form-control" placeholder="Note" value={note} onChange={event => setNote(event.target.value)} />
        <select className="form-control" value={mode} onChange={event => setMode(event.target.value as any)}>
          <option value="READ_WRITE">READ_WRITE</option>
          <option value="READ_ONLY">READ_ONLY</option>
        </select>
        <Button variant="primary" size="md" type="submit" autoWidth working={addKeyMutationState.pending} data-test-id="button">
          Add
        </Button>
      </form>
      {addKeyMutationState.error && (
        <Alert variant="error" className="mt-[15px]" title="An error occurred" message={addKeyMutationState.error.message} data-test-id="error" />
      )}
      <ApiKeyModal open={showApiKeySecret} onHide={() => setShowApiKeySecret(false)} apiKey={addKeyMutationState.response?.createSignaturesApplicationApiKey!.apiKey!} data-test-id="modal" />
    </React.Fragment>
  );
}