import React, { useReducer, useState } from 'react';
import useMutation from '@app/hooks/useMutation';
import graphql from 'babel-plugin-relay/macro';

import { ConfirmModal } from '@app/components/Modal/Modal';
import Textarea from '@app/components/FormFields/Textarea/Textarea';
import Alert from '@app/components/Alert/Alert';
import { FeatureToggleMutation } from './__generated__/FeatureToggleMutation.graphql';
import { useFragment } from 'react-relay';
import { FeatureToggle_tenant$key, FeatureToggle_tenant$data } from './__generated__/FeatureToggle_tenant.graphql';
import { upperFirst } from 'lodash';
import { StandaloneSwitch } from '@app/components/Form/Form';

interface Props {
  feature: FeatureToggleMutation["variables"]["input"]["feature"] | "%future added value"
  tenant: FeatureToggle_tenant$key
}

export default function FeatureToggle(props: Props) {
  const [showConfirm, toggleConfirm] = useReducer((value) => !value, false);

  const tenant = useFragment(graphql`
    fragment FeatureToggle_tenant on Tenant {
      id

      features {
        FRENCH_PUBLIK
        CUSTOM_UI
      }
    }
  `, props.tenant);

  if (props.feature === '%future added value') return null;

  const [enabled, setEnabled] = useState(tenant.features[props.feature]);
  const [note, setNote] = useState('');

  const [{executePromise}, state] = useMutation<FeatureToggleMutation>(graphql`
    mutation FeatureToggleMutation($input: ToggleFeatureInput!) {
      admin_toggleFeature(input: $input) {
        tenant {
          id

          ...FeatureToggle_tenant
        }
      }
    }
  `);

  const handleChange = (value: boolean) => {
    setEnabled(value);
    toggleConfirm();
  }

  const handleConfirm = () => {
    return executePromise({
      input: {
        tenantId: tenant.id,
        feature: props.feature as FeatureToggleMutation["variables"]["input"]["feature"],
        enabled,
        note: note?.length ? note : null
      }
    }).then(() => {
      toggleConfirm()
    }).catch(() => {});
  }

  const prompt = `${enabled ? 'enable' : 'disable'} ${props.feature}`

  return (
    <React.Fragment>
      <StandaloneSwitch
        label={`${props.feature} enabled`}
        value={tenant.features[props.feature]}
        onChange={handleChange}
      />

      <ConfirmModal
        open={showConfirm}
        onCancel={() => toggleConfirm()}
        onConfirm={handleConfirm}
        confirmText={upperFirst(prompt)}
        confirmVariant="primary"
        data-test-id="confirm-modal"
      >

        <div>
          <h2>{upperFirst(prompt)}</h2>
          <p>Are you sure you want to {prompt}?</p>
        </div>

        <Textarea className='mt-[15px] !h-[100px] resize-none' name='note-text' label='Note:' value={note} onChange={event => setNote(event.target.value)} />

        {state.error && (
          <Alert variant="error" className="mt-[15px]" title="An error occurred" message={state.error.message} />
        )}

      </ConfirmModal>
    </React.Fragment>
  );
}