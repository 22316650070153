import React from 'react';

import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Modal, ModalProps } from '@app/components/Modal/Modal';
import InvitationForm from '../InvitationForm';
import { UserInvitationModal_tenant$key } from './__generated__/UserInvitationModal_tenant.graphql';
import useToast from '@app/hooks/useToast';

type Props = {
  open: ModalProps['open'];
  onHide: ModalProps['onHide'];
  tenant: UserInvitationModal_tenant$key;
};

export default function UserInvitationModal(props: Props) {
  const toast = useToast();
  const tenant = useFragment(graphql`
    fragment UserInvitationModal_tenant on Tenant {
      ... InvitationForm_tenant
    }
  `, props.tenant);

  const handleClose = () => {
    toast.success({ title: 'User invited!' });
    if(props.onHide){
      props.onHide();
    }
  };

  return (
    <Modal open={props.open} onHide={props.onHide} className="!max-w-[840px] min-h-[280px]">
      <Modal.Header>
        <div>
          <h2>Invite user to organization</h2>
        </div>
      </Modal.Header>
      <Modal.Actions>
        <InvitationForm tenant={tenant} onInvited={handleClose} />
      </Modal.Actions>
    </Modal>
  );
}