import React, { useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { unwrapResult } from '@reduxjs/toolkit';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import { useDispatch, useSelector } from '@app/redux';
import { checkSession } from '@app/redux/authSlice';
import { set as setEnvironment } from '@app/redux/environmentSlice';
import { Environment } from '@app/models';
import { RedirectScreenQuery } from './__generated__/RedirectScreenQuery.graphql';

export default function RedirectScreen() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{environment?: string}>();
  const dispatch = useDispatch();
  const [skipTenants, setSkipTenants] = useState(true);
  const auth = useSelector(state => state.auth.client);
  const environment = params.environment ? params.environment.toUpperCase() as Environment : undefined;

  const data = useLazyLoadQuery<RedirectScreenQuery>(
    graphql`
      query RedirectScreenQuery($skip: Boolean!) {
        viewer @skip(if: $skip) {
          tenants {
            id
            shortTenantId
            longTenantId
          }
        }
      }
    `,
    {
      skip: skipTenants
    }
  );

  useEffect(() => {
    dispatch(checkSession()).then(unwrapResult).then(result => {
      if (!result) {
        auth.loginWithRedirect({
          appState: {
            returnTo: location.pathname + location.search
          },
          response_type: 'token id_token'
        });
      } else {
        setSkipTenants(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!data.viewer) return;
    let latestTenantId = localStorage.getItem('latest_tenant_id') || undefined;
    const tenants = data.viewer.tenants;
    const tenant = (latestTenantId && tenants.find(t => t.shortTenantId === latestTenantId || t.longTenantId === latestTenantId)) ?? tenants[0];
    let pathname = location.pathname;

    if (environment) {
      pathname = pathname.replace(`/${environment.toLowerCase()}`, '');
      if (tenant) dispatch(setEnvironment({tenantId: tenant.shortTenantId, environment}));
    }

    if (!pathname) pathname = '/';

    if (tenant) {
      pathname = pathname == '/' ? '/dashboard' : (pathname + (location.search || ''));
      history.push(`/tenant/${tenant.shortTenantId}${pathname}`);
    } else {
      history.push("/signup/tenant");
    }
  }, [data.viewer]);

  return (
    <RedirectScreenLoading />
  );
}

export function RedirectScreenLoading() {
  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/logo.svg" alt="Criipto" />
        </div>
        <div className="content">
          <p>Loading the dashboard ...</p>
        </div>
      </div>
    </div>
  );
}