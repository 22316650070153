import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { AnchorButton } from '@app/components/Button/Button';
import Panel from '@app/components/Panel/Panel';
import { SlackForm } from '@app/components/Slack/Slack';
import { useTenantId } from '@app/hooks/useTenant';
import { SupportScreenQuery } from './__generated__/SupportScreenQuery.graphql';
import CopyValueButton from '@app/components/CopyValueButton/CopyValueButton';

export default function SupportScreen() {
  const {relayId} = useTenantId();
  const data = useLazyLoadQuery<SupportScreenQuery>(
    graphql`
      query SupportScreenQuery($tenantId: ID!) {
        tenant(id: $tenantId) {
          name
          shortTenantId
        }
      }
    `,
    {
      tenantId: relayId
    }
  );

  if (!data.tenant) return null;

  const supportLink = window.location.origin + `/support/${data.tenant.shortTenantId}`;
  const mailto = `mailto:support@criipto.com&body=${encodeURIComponent(`My tenant is: ${supportLink}`)}`

  return (
    <React.Fragment>
      <div className="app-content-header">
        <h1>Support</h1>
      </div>
      <div className="app-content-main max-w-[850px]">
        <div className="flex flex-column lg:flex-row gap-[16px]">
          <Panel title="Slack support">
            <SlackForm />
          </Panel>
          <Panel title="Email support">
            <p>
              Slack is the easiest way to get direct support from Criipto Developers but you can also send us an email at <a href={mailto}>support@criipto.com</a>
            </p>
            <AnchorButton variant="default" href={mailto}>Write an email</AnchorButton>
          </Panel>
        </div>
        <div className="flex flex-column lg:flex-row gap-[16px]">
          <Panel title="Help us help you!">
            <p>
              When talking to support, please include the link shown below:
            </p>

            <div className="form-group">
              <label className="control-label">Link for Criipto Support</label>
              <div className="flex flex-row">
                <input className="form-control" readOnly={true} value={supportLink} />
                <CopyValueButton variant="primary" tooltip="Copy support link" value={supportLink}  className="!mt-0 !h-[34px]" />
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </React.Fragment>
  );
}