import React from 'react';

import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import {translate} from '@app/i18n';

import {
  Environment
} from '@app/models';


import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import useEnvironment from '@app/hooks/useEnvironment';
import { BEeIDProviderMutation } from './__generated__/BEeIDProviderMutation.graphql';
import { BEeIDProviderQuery } from './__generated__/BEeIDProviderQuery.graphql';
import { TenantId } from '@app/hooks/useTenant';

interface Props {
  tenant: TenantId,
  environment: Environment
}

export interface FormValues  {
  clientId : string
  clientSecret : string,
  requestPersonalInfo: boolean,
  requestAddress: boolean,
  requestPhoto: boolean,
  requestNationalNumber: boolean,
  skipSummary: boolean
}

export function BEeIDProvider(props : Props) {
  const {tenant} = props;

  const data = useLazyLoadQuery<BEeIDProviderQuery>(
    graphql`
      query BEeIDProviderQuery($id: ID!, $tenantId: ID!, $environment: Environment!) {
        identityProvider(id: $id) {
          id
          tenant {
            id
          }

          longName

          ... on BelgianEID {
            clientId
            requestPersonalInfo
            requestAddress
            requestPhoto
            requestNationalNumber
            skipSummary
          }
        }

        tenant(id: $tenantId) {
          domains(first: 1000, environment: $environment) @connection(key: "tenant_domains") {
            edges {
              node {
                id
                name
                environment
              }
            }
          }
        }
      }
  `, {
    id: btoa(`identityProvider:${tenant.tenantId}|${props.environment}|BE_EID`),
    tenantId: tenant.relayId,
    environment: props.environment
  });

  const [{executePromise}] = useMutation<BEeIDProviderMutation>(graphql`
    mutation BEeIDProviderMutation(
      $input: UpdateBelgianEIDInput!
    ) {
      updateBelgianEID(input: $input) {
        id
        isConfigured
        ... on BelgianEID {
          clientId
          requestPersonalInfo
          requestAddress
          requestPhoto
          requestNationalNumber
          skipSummary
        }
      }
    }
  `);


  if (!data.identityProvider) return null;
  const initialValues : FormValues = {
    clientId: data.identityProvider.clientId || '',
    clientSecret: '',
    requestPersonalInfo: data.identityProvider.requestPersonalInfo || false,
    requestAddress: data.identityProvider.requestAddress || false,
    requestPhoto: data.identityProvider.requestPhoto || false,
    requestNationalNumber: data.identityProvider.requestNationalNumber || false,
    skipSummary: data.identityProvider.skipSummary || false
  };

  const environment = useEnvironment();
  const isProduction = environment === 'PRODUCTION';
  const domains = data.tenant?.domains.edges.map(edge => edge.node);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return executePromise({
          input: {
            tenantId: data.identityProvider!.tenant.id,
            environment: environment,
            ...values
          }
        });
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_BE_EID_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          {!initialValues.clientId ? (
            <EnvironmentDialog environment="PRODUCTION" heading={(
              <React.Fragment>
                <h1>
                  <strong>{translate('INFO_BE_EID_CLIENT')}</strong>
                </h1>
                <br />
                <h3>{translate('INFO_BE_EID_CALLBACK_URL_PROD')}</h3>
              </React.Fragment>
            )}>
              {domains?.map(domain => (
                <p className="green" key={domain.name}>
                  https://{domain.name}/BEEIDConnective/Callback
                </p>
              ))}
            </EnvironmentDialog>
          ) : null}
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Belgian eID Authentication
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_BE_EID_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_BE_EID_CLIENTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_BE_EID_CLIENTSECRET')}
                name="clientSecret"
                placeholder={translate('LABEL_BE_EID_CLIENTSECRET')}
              />

              <Switch name="requestPersonalInfo" label={translate('LABEL_BE_EID_REQUEST_PERSONAL_INFO')} />
              <Switch name="requestAddress" label={translate('LABEL_BE_EID_REQUEST_ADDRESS')} />
              <Switch name="requestPhoto" label={translate('LABEL_BE_EID_REQUEST_PHOTO')} />
              <Switch name="requestNationalNumber" label={translate('LABEL_BE_EID_REQUEST_NATIONAL_NUMBER')} />
              <Switch name="skipSummary" label={translate('LABEL_BE_EID_SKIP_SUMMARY')} />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your belgian eID settings have been updated`} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_BE_EID_CLIENT')}</p>
              <p>{translate(isProduction ? 'INFO_BE_EID_CALLBACK_URL_PROD': 'INFO_BE_EID_CALLBACK_URL_TEST')}</p>
              {domains?.map(domain => (
                <p className="green" key={domain.name}>
                  https://{domain.name}/BEEIDConnective/Callback
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}