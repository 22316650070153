import React, { useEffect, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import { useDispatch } from '@app/redux';
import { Environment } from '@app/models';
import { set as setEnvironment } from '@app/redux/environmentSlice';

import './EnvironmentToggle.scss';
import Tooltip from '../Tooltip/Tooltip';
import useEnvironment from '@app/hooks/useEnvironment';
import {useTenantId} from '@app/hooks/useTenant';
import ProductionUpgradeRequiredModal from '../ProductionUpgradeRequiredModal/ProductionUpgradeRequiredModal';
import { EnvironmentToggle_tenant$key } from './__generated__/EnvironmentToggle_tenant.graphql';



type Props = {
  disableUpgrade: boolean
  tenant: EnvironmentToggle_tenant$key
}
export default function EnvironmentToggle(props: Props) {
  const {disableUpgrade} = props;
  const environment = useEnvironment();
  const tenantId = useTenantId();
  const dispatch = useDispatch();
  const [showUpgradeModal, setUpgradeModal] = useState(false);

  const tenant = useFragment(
    graphql`
      fragment EnvironmentToggle_tenant on Tenant {
        environments
        ... ProductionUpgradeRequiredModal_tenant
      }
    `,
    props.tenant
  );

  const handleEnvironment = (environment: Environment) => {
    if (!tenantId) return;

    if (environment === 'PRODUCTION') {
      if (!tenant.environments.includes(environment)) {
        if (!disableUpgrade) setUpgradeModal(true);
        return;
      }
    }

    dispatch(setEnvironment({tenantId: tenantId!.tenantId, environment}));
  }

  useEffect(() => {
    if (environment === 'PRODUCTION' && !tenant.environments.includes(environment)) {
      if (!disableUpgrade) setUpgradeModal(true);
      dispatch(setEnvironment({tenantId: tenantId!.tenantId, environment: 'TEST'}));
      return;
    }
    if (tenant.environments.includes('PRODUCTION') || environment === 'TEST') {
      if (!disableUpgrade) setUpgradeModal(false);
      return;
    }
  }, [tenant, environment]);

  const production = (
    <div className="production">
      {/* {!small && (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.8695 8.72316H19.2559C19.0569 8.03789 18.7916 7.39684 18.4601 6.8L19.6095 5.65053C19.8969 5.36316 19.8969 4.92105 19.6095 4.63368L17.4211 2.44526C17.1337 2.15789 16.6916 2.15789 16.4043 2.44526L15.2548 3.59474C14.658 3.26316 14.0169 2.99789 13.3316 2.79895V1.20737C13.3316 0.809474 13.0222 0.5 12.6243 0.5H9.52954C9.13164 0.5 8.82217 0.809474 8.82217 1.20737V2.82105C8.1369 3.02 7.49585 3.28526 6.89901 3.61684L5.74954 2.46737C5.46217 2.18 5.02006 2.18 4.73269 2.46737L2.54427 4.65579C2.2569 4.94316 2.2569 5.38526 2.54427 5.67263L3.69375 6.82211C3.36217 7.41895 3.0969 8.06 2.89796 8.74526H1.28427C0.886378 8.74526 0.576904 9.05474 0.576904 9.45263V12.5474C0.576904 12.9453 0.886378 13.2547 1.28427 13.2547H2.89796C3.0969 13.94 3.36217 14.5811 3.69375 15.1779L2.54427 16.3274C2.2569 16.6147 2.2569 17.0568 2.54427 17.3442L4.73269 19.5326C5.02006 19.82 5.46217 19.82 5.74954 19.5326L6.89901 18.3832C7.49585 18.7147 8.1369 18.98 8.82217 19.1789V20.7926C8.82217 21.1905 9.13164 21.5 9.52954 21.5H12.6243C13.0222 21.5 13.3316 21.1905 13.3316 20.7926V19.1789C14.0169 18.98 14.658 18.7147 15.2548 18.3832L16.4043 19.5326C16.6916 19.82 17.1337 19.82 17.4211 19.5326L19.6095 17.3442C19.8969 17.0568 19.8969 16.6147 19.6095 16.3274L18.4601 15.1779C18.7916 14.5811 19.0569 13.94 19.2559 13.2547H20.8695C21.2674 13.2547 21.5769 12.9453 21.5769 12.5474V9.45263C21.5769 9.05474 21.2674 8.72316 20.8695 8.72316ZM11.0769 16.2611C8.18111 16.2611 5.81585 13.9179 5.81585 11C5.81585 8.08211 8.18111 5.73895 11.0769 5.73895C13.9727 5.73895 16.338 8.08211 16.338 11C16.338 13.9179 13.9727 16.2611 11.0769 16.2611Z" />
        </svg>
      )} */}
      <span>Production</span>
    </div>
  );

  const test = (
    <div className="test">
      {/* {!small && (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.15582 10.1081C3.38071 7.60227 3.97554 4.8632 5.9403 2.89844C7.74089 1.04158 10.3396 0.405342 12.6788 0.95055C13.3564 1.10848 13.5111 1.93685 13.0191 2.42882L9.952 5.49593C9.56148 5.88646 9.56148 6.51962 9.952 6.91015L12.1122 9.0703C12.5027 9.46082 13.1358 9.46083 13.5264 9.0703L16.5935 6.00319C17.0855 5.51122 17.9135 5.66635 18.0674 6.34486C18.5981 8.68372 17.9241 11.2818 16.1239 13.082C14.1012 15.1721 11.0675 15.7119 8.50522 14.7014C8.5039 14.7009 8.5024 14.7012 8.5014 14.7022C8.50039 14.7032 8.49889 14.7035 8.49758 14.703C6.45099 13.8967 4.84797 12.2093 4.15582 10.1081Z"/>
          <rect x="3.02051" y="11.514" width="6.34653" height="4.06586" rx="1" transform="rotate(45 3.02051 11.514)" />
        </svg>
      )} */}
      <span>Test</span>
    </div>
  );

  const productionTooltip = !tenant.environments.includes('PRODUCTION') ? 'You must be on a paid plan to access production settings' : 'Click to show production settings & data';
  const testTooltip = 'Click to show test settings & data';
  const smallTooltip = environment === "TEST" ? productionTooltip : testTooltip;

  return (
    <React.Fragment>
      <Tooltip tooltip={smallTooltip} id="environment_toggle">
        <div className={`environment-switcher environment-${environment} cursor-pointer`} style={{justifyContent: 'flex-start'}} onClick={() => handleEnvironment(environment === 'PRODUCTION' ? 'TEST' : 'PRODUCTION')} data-test-id="environment_toggle" data-test-current={environment}>
          <div className="switch">
            <div className="line"></div>
            <div className="circle"></div>
          </div>
          {environment === 'PRODUCTION' ? production : test}
        </div>
      </Tooltip>
      <React.Suspense fallback={null}>
        <ProductionUpgradeRequiredModal tenant={tenant} show={showUpgradeModal} onHide={() => setUpgradeModal(false)} />
      </React.Suspense>
    </React.Fragment>
  );
}