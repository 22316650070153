/**
 * @generated SignedSource<<7487512600b4c916c868da703dddbc6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationErrorStrategy = "LEGACY" | "PROTOCOL" | "%future added value";
export type ApplicationScopeStrategy = "DYNAMIC" | "STATIC" | "%future added value";
export type ClaimPropertyFormat = "COMPACT" | "DEFAULT" | "NAMESPACED" | "%future added value";
export type NullClaimValues = "ALLOW" | "REMOVE" | "%future added value";
export type UserInfoResponseStrategy = "FROM_TOKEN_ENDPOINT" | "PLAIN_JSON" | "SIGNED_JWT" | "%future added value";
export type ViewVersion = "INITIAL" | "UNIFIED" | "%future added value";
export type UpdateApplicationInput = {
  acrValues?: ReadonlyArray<string> | null;
  android?: VerifyApplicationAndroidInput | null;
  applicationId: string;
  callbackUrls?: ReadonlyArray<string> | null;
  clientJwks?: ApplicationJwksInput | null;
  errorStrategy?: ApplicationErrorStrategy | null;
  frameOrigin?: string | null;
  iOS?: VerifyApplicationIOSInput | null;
  metadata?: ReadonlyArray<MetadataInput> | null;
  name?: string | null;
  oauth2?: ApplicationOAuth2SettingsInput | null;
  oauth2ClientSecretEnabled?: boolean | null;
  realm?: string | null;
  scopeStrategy?: ApplicationScopeStrategy | null;
  sessionLifetime?: string | null;
  technology?: string | null;
  technologyGroup?: string | null;
  tokenLifetime?: string | null;
  ui?: UpdateApplicationUIInput | null;
};
export type UpdateApplicationUIInput = {
  cssClass?: string | null;
  javascript?: string | null;
  stylesheet?: string | null;
  viewVersion?: ViewVersion | null;
};
export type ApplicationOAuth2SettingsInput = {
  callbackOnLocationHash?: boolean | null;
  claimPropertyFormat?: ClaimPropertyFormat | null;
  nullClaimValues?: NullClaimValues | null;
  userInfoResponseStrategy?: UserInfoResponseStrategy | null;
};
export type ApplicationJwksInput = {
  static?: string | null;
  url?: string | null;
};
export type MetadataInput = {
  key: string;
  value: string;
};
export type VerifyApplicationAndroidInput = {
  packageName: string;
  sha256Fingerprints: ReadonlyArray<string>;
};
export type VerifyApplicationIOSInput = {
  bundleId: string;
  teamId: string;
};
export type UISectionMutation$variables = {
  input: UpdateApplicationInput;
};
export type UISectionMutation$data = {
  readonly updateApplication: {
    readonly application: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UISection_application">;
    };
  };
};
export type UISectionMutation = {
  response: UISectionMutation$data;
  variables: UISectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UISectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateApplicationOutput",
        "kind": "LinkedField",
        "name": "updateApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UISection_application"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UISectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateApplicationOutput",
        "kind": "LinkedField",
        "name": "updateApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ApplicationUISettings",
                    "kind": "LinkedField",
                    "name": "ui",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stylesheet",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cssClass",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewVersion",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "VerifyApplication",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3e9f1a4043d8a953beab682354439e0",
    "id": null,
    "metadata": {},
    "name": "UISectionMutation",
    "operationKind": "mutation",
    "text": "mutation UISectionMutation(\n  $input: UpdateApplicationInput!\n) {\n  updateApplication(input: $input) {\n    application {\n      __typename\n      id\n      ...UISection_application\n    }\n  }\n}\n\nfragment UISection_application on VerifyApplication {\n  id\n  ui {\n    stylesheet\n    cssClass\n    viewVersion\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ef4c95688c793ca8977e473b8a5582d";

export default node;
