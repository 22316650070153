import React, {useState} from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import useMutation from '@app/hooks/useMutation';

import { Application } from '@app/models';
import { translate } from '@app/i18n';

import usePromise from '@app/hooks/usePromise';

import Button from '@app/components/Button';
import HelpText from '@app/components/FormFields/HelpText';
import ClientSecretModal from '../ClientSecretModal';
import { CodeFlowClientSecretMutation } from './__generated__/CodeFlowClientSecretMutation.graphql';
import { CodeFlow_application$key, CodeFlow_application$data } from './__generated__/CodeFlow_application.graphql';
import { CodeFlow_MatchQuery } from './__generated__/CodeFlow_MatchQuery.graphql';

interface Props {
  application: CodeFlow_application$key
}

const matchQuery = graphql`
  query CodeFlow_MatchQuery($applicationId: ID!, $secret: String!) {
    application(id: $applicationId) {
      ... on VerifyApplication {
        clientSecretMatches(secret: $secret)
      }
    }
  }
`;

export default function CodeFlow(props : Props) {
  const relayEnvironment = useRelayEnvironment();
  const application = useFragment(graphql`
    fragment CodeFlow_application on VerifyApplication {
      id
      name
      clientSecretEnabled
    }
  `, props.application);

  const wasEnabled = application.clientSecretEnabled;
  const [{value}, , {setValue}] = useField<boolean | null>('clientSecretEnabled');

  /* Client secret match logic, TODO: Consider moving to smaller, seperate component */
  const [matchValue, setMatchValue] = useState('');
  const [isMatch, matchState, triggerMatch] = usePromise(async () => {
    const value = await fetchQuery<CodeFlow_MatchQuery>(relayEnvironment, matchQuery, {
      applicationId: application.id,
      secret: matchValue,
    }).toPromise();
    return value?.application?.clientSecretMatches || false;
  }, false);

  /* Logic for showing secrets and regenerating */
  const [showClientSecret, setShowClientSecret] = useState<string | null>(null);
  const [generateClientSecretExecutor, generateClientSecretState] = useMutation<CodeFlowClientSecretMutation>(graphql`
    mutation CodeFlowClientSecretMutation($input: GenerateVerifyApplicationClientSecretInput!) {
      generateVerifyApplicationClientSecret(input: $input) {
        application {
          id
          ... on VerifyApplication {
            clientSecretEnabled
          }
        }
        clientSecret
      }
    }
  `);

  const handleGenerate = () => {
    generateClientSecretExecutor.executePromise({
      input: {
        applicationId: application.id
      }
    }).then(response => {
      setShowClientSecret(response.generateVerifyApplicationClientSecret!.clientSecret);
    })
  };

  return (
    <React.Fragment>
      {wasEnabled && value && (
        <React.Fragment>
          <div className="form-group" style={{marginBottom: "15px"}}>
            <label>{translate('CLIENT_SECRET')}</label>
            <div className="input-with-button">
              <input
                type="password"
                className={cx('form-control', {'has-success': isMatch === true, 'has-error': isMatch === false})}
                placeholder={translate('OAUTH2_MATCH_CLIENTSECRET')}
                value={matchValue}
                onChange={(event) => setMatchValue(event.target.value)}
              />

              <Button variant="default" className="button-icon" onClick={triggerMatch} working={matchState.pending}>
                {!matchState.pending && (
                  <i className={cx('fa', {'fa-search': isMatch !== true, 'fa-check': isMatch})} />
                )}
              </Button>
            </div>

            <Button variant="default" onClick={handleGenerate} working={generateClientSecretState.pending}>
              {translate('OAUTH2_RECYCLE_CLIENTSECRET')}
            </Button>
          </div>
        </React.Fragment>
      )}

      <CodeFlowToggle application={application} />

      <ClientSecretModal open={!!showClientSecret} onHide={() => setShowClientSecret(null)} application={application} clientSecret={showClientSecret!} />
    </React.Fragment>
  )
}

export function CodeFlowToggle(props: {application: CodeFlow_application$data}) {
  const {application} = props;
  const wasEnabled = application.clientSecretEnabled;
  const [{value}, , {setValue}] = useField<boolean | null>('clientSecretEnabled');

  const handleValue = (event : React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };

  return (
    <div className="form-group">
      <div className="flex flex-row items-center">
        <label className="switch mr-4">
          <input type="checkbox" checked={value ?? false} onChange={handleValue} /><i />
        </label>
        <label className="control-label">{translate('HINT_OAUTH2_AUTHZCODEFLOW_ENABLED')}</label>
      </div>
      <HelpText>{translate('INFO_OAUTH2_AUTHZCODEFLOW_ENABLED')}</HelpText>
      {!wasEnabled && value && (<HelpText>{translate('HINT_OAUTH2_AFTER_SAVE')}</HelpText>)}
    </div>
  );
}